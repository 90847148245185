import React from 'react';
import BigButton from '../components/bigButton';
import { clipboard, person, people, hammer, calculator, reader } from 'ionicons/icons';
//import {} from 'react-dom'

import '../css/hjem.css';
import Background from '../components/miniComponents/background';
import ButtonListButton from '../components/miniComponents/buttonListButton';

//icons
import { ReactComponent as ChatIcon } from '../assets/icons/material-symbols_chat.svg';
import { ReactComponent as RapportIcon } from '../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as Warning } from '../assets/icons/material-symbols_warning-rounded.svg';
import { ReactComponent as UtstyrIcon } from '../assets/icons/mdi_wrench.svg';
//import { ReactComponent as KalkulatorIcon } from '../assets/icons/mdi_calculator.svg';
//import { ReactComponent as NotaterIcon } from '../assets/icons/mdi_notebook.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/mdi_account-group.svg';
import { ReactComponent as RoleIcon } from '../assets/icons/eos-icons_role-binding.svg';
import { ReactComponent as WorkIcon } from '../assets/icons/material-symbols_work.svg';
import { ReactComponent as ProgressClockIcon } from '../assets/icons/mdi_progress-clock.svg';
import { ReactComponent as UserIcon } from '../assets/icons/material-symbols_person.svg';



import TilbakeKnapp from '../components/miniComponents/tilbakeKnapp';
import DefaultWrapper from '../components/defaultWrapper';
import { copyClientsToCompanies } from '../firebase';
import ButtonBigButton from '../components/miniComponents/buttonBigButton';
import { CalendarToday, PunchClockRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';



export default function AdministratorPage() {
    const { t } = useTranslation();
    return (
        <DefaultWrapper header={true}>
            <div className='content-ny'>
                <div className='row wrap '>
                    <div className='column noGap stretch-width'>
                        <div className='flexApart stretch-width row noGap first-and-last-no-padding '>
                            <ButtonBigButton title={t("Company Settings", "Firma Innstillinger")} permissions={"admin"} navigateTo={"admin/instillinger"} icon={ChatIcon} />
                            <ButtonBigButton title={t("Employees", "Ansatte")} permissions={["invitereBrukere", "fjerneBruklere", "redigereBrukere"]} navigateTo={"./brukere"} icon={GroupIcon} />
                            <ButtonBigButton title={t("Roles and Rights", "Roller og Rettigheter")} permissions={"redigereRoller"} navigateTo={"/roller"} icon={RoleIcon} />
                        </div>
                        <div className='long-line'></div>
                    </div>

                    {//<ButtonListButton title={"Kunder"} navigateTo={"./clientList"} icon={people}/>
                    }
                    <div className='flexApart stretch-width column small-gap'>
                        <ButtonListButton title={t("Customer Overview", "Kundeoversikt")} permissions={"kundeOversikt"} navigateTo={"/customerList"} icon={WorkIcon} />
                        {!Capacitor.isNativePlatform() &&
                            <ButtonListButton notOnPhone={true} title={t("Schedule", "Arbeidsplan")} permissions={"seAndreSineTimer"} navigateTo={"/arbeidsplan"} icon={CalendarToday} />
                        }
                        <ButtonListButton notOnPhone={true} title={t("Time Overview", "Timeoversikt")} permissions={"seAndreSineTimer"} navigateTo={"/timeAdmin"} icon={PunchClockRounded} />
                        <ButtonListButton title={t("Equipment", "Utstyr")} permissions={["seUtstyr", "lageUtstyr", "redigereUtstyr", "sletteUtstyr"]} navigateTo={"/utstyrsliste"} icon={UtstyrIcon} />
                        <ButtonListButton title={t("Templates", "Maler")} permissions={["lageMaler", "redigereMaler", "sletteMaler"]} navigateTo={"/reportSettings"} icon={RapportIcon} />
                        {/*<ButtonListButton title={"Kort Skanner"} navigateTo={"/cardscan"} icon={WorkIcon} />*/}
                    </div>
                </div>
            </div>
        </DefaultWrapper >
    );
};

