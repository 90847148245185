import { useEffect, useState } from "react";
import CustomInput from "./customInput";





export default function FillOnEmptyInput(props) {
    const { placeholderValue, ...rest } = props;
    const { value, onChange } = props;

    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (!placeholderValue) return console.error("FillOnEmptyInput must have a placeholderValue prop");
        if (!onChange) return console.error("FillOnEmptyInput must have an onChange prop");
        //(props?.value === "" && !focused) ? props.placeholderValue : props.value
        console.log(value, placeholderValue, focused);
        if (props?.value === "" && !focused) {
            props?.onChange({target: { value: props?.placeholderValue }});
        }
        if (props?.value === props?.placeholderValue && focused) {
            props?.onChange({target: { value: "" }});
        }
    }, [props.value, focused]);

    return (
        <CustomInput
            {...rest}
            onFocus={() => {
                setFocused(old => true);
                if (props.onFocus) props.onFocus();
            }}
            onBlur={() => {
                setFocused(old => false);
                if (props.onBlur) props.onBlur();
            }}
        />
    )
}