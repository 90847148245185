import { IonPage } from "@ionic/react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import React, { useContext } from "react";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import DefaultWrapper from "../../../components/defaultWrapper";
import { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";

import { PDFstyles } from "./styles";
import { PDFPreview } from "../../../components/renderPDF";
import { UploadingContext } from "../../../contexts/loadingContext";
import { CompanyContext } from "../../../contexts/companyContext";
import { handleUploadPdf } from "../../../firebase";
import { uploadHMSReport } from "../../../database/hms";
import { useSelector } from "react-redux";


// Based on https://avseth.no/avviksskjema/
// https://prest.no/wp-content/uploads/sites/2/2020/05/Skjema-for-avviksregistrering.pdf


export default function Avvikpdf() {
    const history = useHistory();
    const data = history.location.state;
    const { companyDetails } = useSelector(state => state.company);
    const { startUploadBar, countUp, endUploadBar } = useContext(UploadingContext);

    const navigateToNewPage = (path, state) => {
        history.push(path, state);
    }

    const send = async () => {
        startUploadBar(3, "Genererer PDF");
        const instance = await pdf(<MyDocument data={data} />);

        const pdfBlob = await instance.toBlob();
        countUp("Laster opp PDF");

        const pdfUrl = await handleUploadPdf(pdfBlob, "HMS/Avvik", data.id);


        countUp("Sender Emailer");
        const newReport = {
            ...data,
            pdfUrl,
            company: companyDetails
        }

        console.log(newReport);
        await uploadHMSReport(newReport)
        endUploadBar();
        localStorage.removeItem("currentAvvik");
        navigateToNewPage("/HMS", { tab: "Avvik" });
    }

    return (
        <DefaultWrapper paddingBottom={true}>
            
                <button className="bottom-right-button" onClick={send}>Send</button>
                <PDFPreview document={<MyDocument data={data} />} />
            
        </DefaultWrapper>
    );
}


const MyDocument = ({ data, imageDimensions }) => {
    console.log(data);

    return (
        <Document>
            <Page
                //landscape={true}
                size="A4"
                //orientation="landscape"
                style={PDFstyles.page}>
                {/* Page number */}
                <Text
                    style={PDFstyles.pageNumber}
                    render={({ pageNumber, totalPages }) => (
                        `Dokument ID #${data.id} ${pageNumber} / ${totalPages}`
                    )}
                    fixed
                />
                <View style={[PDFstyles.row, PDFstyles.pageInfo, PDFstyles.centerRow]} fixed>
                    <Text> Laget med EffektivJobb</Text>
                    <Image src={Logo} style={PDFstyles.infoLogo} />
                </View>

                <View style={[PDFstyles.column, PDFstyles.gap]}>
                    <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h1]}>Avviksskjema</Text>

                    <View style={[PDFstyles.row, { height: 80, width: "100%" }]}>
                        <View style={[PDFstyles.column, { width: "100%" }]}>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Melderens Navn</Text>
                                <Text style={[PDFstyles.text]}>{data?.ansvarlig?.name}</Text>
                            </View>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Prosjekt</Text>
                                <View style={[PDFstyles.row, PDFstyles.gap]}>
                                    <Text style={[PDFstyles.text]}>{data?.prosjekt?.navn}</Text>
                                    <Text style={[PDFstyles.text]}>{data?.prosjekt?.nummer}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={[PDFstyles.column, { width: "100%" }]}>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Dato og klokkeslett</Text>
                                <Text style={[PDFstyles.text]}>{new Date(data?.dato).toLocaleDateString()} {new Date(data?.dato).toLocaleTimeString()}</Text>
                            </View>

                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Sted</Text>

                                <Text style={[PDFstyles.text]}>{data?.sted?.addresse || data?.sted?.navn}, {data?.sted?.by}</Text>
                            </View>
                        </View>
                        {data?.logo &&
                            <Image src={data?.logo} style={PDFstyles.logo} />
                        }
                    </View>

                    <View style={[PDFstyles.column, PDFstyles.gap]}>
                        <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize]}>
                            <Text style={[PDFstyles.label,]}>Avviket ble oppdaget</Text>
                            <Text style={[PDFstyles.text]}>{new Date(data?.oppdaget).toLocaleDateString()} {new Date(data?.oppdaget).toLocaleTimeString()}</Text>
                        </View>

                        <View style={[PDFstyles.row]}>

                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxSmall, PDFstyles.boxOverlapRight, PDFstyles.minWidthBox]}>
                                <Text style={[PDFstyles.label,]}>Avvikets begrunnelser:</Text>

                                {data?.begrunnelser?.map((begrunnelse, index) => {
                                    return (
                                        <Text key={index} style={[PDFstyles.text]}>{begrunnelse.name}</Text>
                                    );
                                })}
                            </View>

                            <View style={[PDFstyles.column, PDFstyles.stretchWidth]}>
                                <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxSmall, PDFstyles.stretchWidth]}>
                                    <Text style={[PDFstyles.label,]}>Beskrivelse av avviket</Text>
                                    <Text style={[PDFstyles.text, PDFstyles.wrapText]}>{data?.description}</Text>
                                </View>

                                <View break style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxSmall, PDFstyles.boxOverlapTop, PDFstyles.stretchWidth]}>
                                    <Text style={[PDFstyles.label,]}>Sansynelig årsak til avvik</Text>
                                    <Text style={[PDFstyles.text]}>{data?.årsak}</Text>
                                </View>
                            </View>
                        </View>

                        <View break style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxSmall]}>
                            <Text style={[PDFstyles.label,]}>Forebyggende tiltak</Text>
                            <Text style={[PDFstyles.text, PDFstyles.wrapText]}>{data?.tiltak}</Text>
                        </View>
                    </View>

                    <View style={[PDFstyles.column, PDFstyles.hardBorder]}>
                        <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize]}>
                            <Text style={[PDFstyles.label,]}>Ønskelig frist for å lukke avviket</Text>
                            <Text style={[PDFstyles.text]}>{data?.frist ? new Date(data?.frist).toLocaleDateString() : null} {data?.frist ? new Date(data?.frist).toLocaleTimeString() : null}</Text>
                        </View>

                        <View style={[PDFstyles.row]} break>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop, PDFstyles.boxOverlapRight]}>
                                <Text style={[PDFstyles.label,]}>Behandlers Navn:</Text>
                            </View>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop]}>
                                <Text style={[PDFstyles.label,]}>Dato:</Text>
                            </View>
                        </View>

                        <View style={[PDFstyles.column]}>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop]}>
                                <Text style={[PDFstyles.label,]}>Analyse av årsak:</Text>
                            </View>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop]}>

                            </View>
                        </View>

                        <View style={[PDFstyles.column]}>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop]}>
                                <Text style={[PDFstyles.label,]}>Tiltak:</Text>
                            </View>
                            <View style={[PDFstyles.column, PDFstyles.box, PDFstyles.minHeightBoxTextSize, PDFstyles.stretchWidth, PDFstyles.boxOverlapTop]}>

                            </View>
                        </View>
                    </View>
                </View>
            </Page>

        </Document>
    )
};