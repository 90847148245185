

/* Performance wise worse.
export const noUndefined = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}
*/

export const noUndefined = (obj) => Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
);

/*
const noUndefined = (obj) Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
);
*/