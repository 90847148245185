
import React, { useState, useEffect } from 'react';
import { IonAlert, IonContent, IonHeader, IonPage, IonSelect, IonSelectOption, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import DefaultWrapper from '../../../components/defaultWrapper';

import { GoogleMap } from '@capacitor/google-maps';
import { useRef } from 'react';
import { MyMap, SearchAddressesWithPopup } from '../../../components/miniComponents/searchAddresses';
import CircleIcon from '../../../components/miniComponents/circleIcon';
import ButtonListButton from '../../../components/miniComponents/buttonListButton';

import { ReactComponent as RapportIcon } from '../../../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/material-symbols_archive-rounded.svg';
import CustomInput from '../../../components/miniComponents/customInput';
import { Link, People, Person, Person2 } from '@mui/icons-material';
import CustomModal from '../../../components/miniComponents/CustomModal';
import { createId, getClients, updateProject } from '../../../firebase';
import debounce from 'lodash.debounce';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PeopleAndRolesSearchWithPopup from '../../../components/searchWithPopups/peopleAndRolesSearch';

export default function ProjektOverview(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [projekt, setProjekt] = useState({
        navn: '',
        addresse: '',
        postnummer: '',
        by: '',
        id: createId(),
        kunde: null,
        kontaktpersoner: [],
        klokkeStart: "00:00",
        klokkeSlutt: "00:00",
        firmaConnection: "",
        ...history?.location?.state?.projekt,
        access: {
            users: [],
            roles: []
        }
    })

    const [firmaConnection, setFirmaConnection] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [firmaConnectionModalOpen, setFirmaConnectionModalOpen] = useState(false);

    const [kontaktPersonAlert, setKontaktPersonAlert] = useState(false);
    const [alertData, setAlertData] = useState({ navn: '', epost: '' });
    const [alertKey, setAlertKey] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);

    const updateDatabase = async () => {
        console.log("Updating database")
        await updateProject(projekt)
    }

    const debouncedSetProjekt = debounce(updateDatabase, 5000);

    useEffect(() => {
        debouncedSetProjekt(projekt);
        // Cleanup function to cancel the debounce on unmount
        return () => debouncedSetProjekt.cancel();
    }, [projekt, debouncedSetProjekt]);


    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
        //history.replace(path, information)
    }

    useEffect(() => {
        console.log("IT CHANGED")
        console.log(history.location.state)
        if (history.location.state) {
            setProjekt(old => {
                return {
                    ...old,
                    ...history.location.state.projekt
                }
            })
        }
    }, [history.location.state]);

    const updateWithAlertData = (alertData) => {
        console.log(alertData)
        setProjekt((old) => {
            return {
                ...old,
                kontaktpersoner: [
                    ...old.kontaktpersoner,
                    {
                        navn: alertData.navn,
                        epost: alertData.epost,
                        id: createId()
                    }
                ]
            }
        })
    }

    useEffect(() => {
        if (customers.length <= 0) return;
        if (projekt?.firmaConnection) {
            console.log(customers)
            setFirmaConnection(customers.find((customer) => customer.id === projekt?.firmaConnection))
        }
    }, [projekt?.firmaConnection, customers, projekt])

    useEffect(() => {
        const onUpdate = async (querySnapshot) => {
            setCustomers(querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            }))
        }
        getClients(onUpdate)
    }, [])


    return (
        <DefaultWrapper lagring={true} onBack={
            async () => {
                await updateDatabase();
            }
        } /*extraButton={<button onClick={() => navigateToNewPage(`/prosjekt/edit`, history.location.state)}>Rediger</button>}*/>
            <div className='content-ny'>
                <div className='row-size-dependant stretch-width' >
                    <div className='stretch-width column'>
                    <CustomInput label={t("Project-number", "Prosjektnummer")} value={projekt?.nummer} onChange={(e) => setProjekt({ ...projekt, nummer: e.target.value })} />
                        <CustomInput label={t("Name", "Navn")} value={projekt?.navn} onChange={(e) => setProjekt({ ...projekt, navn: e.target.value })} />
                        <div>
                            {firmaConnection ? <button className='stretch-width flex-start row' onClick={(e) => {
                                console.log(firmaConnectionModalOpen)
                                setFirmaConnectionModalOpen(true)
                            }}>
                                <Avatar />
                                <h3>{firmaConnection.name || firmaConnection.navn}</h3>
                            </button>
                                :
                                <button className='stretch-width flex-start row' onClick={(e) => { setFirmaConnectionModalOpen(true) }}>
                                    <CircleIcon icon={Link} /> <h3>{t("No Customer Linked", "Ingen Kunde Linket")}</h3>
                                </button>
                            }
                        </div>

                        <SearchAddressesWithPopup label={t("Address", "Addresse")} value={projekt?.addresse} onChange={
                            (addresse) => {
                                console.log(addresse)
                                setProjekt((old) => {
                                    return {
                                        ...old,
                                        //navn: addresse?.navn || addresse?.addresse || null,
                                        addresse: addresse?.addresse || addresse?.navn || old?.addresse || null,
                                        postnummer: addresse?.postalCode || old?.postnummer || null,
                                        by: addresse?.city || old?.by || null,
                                        lat: addresse?.lat || old?.lat || null,
                                        lng: addresse?.lng || old?.lng || null
                                    }
                                })
                            }} noMap={true} />
                        <div className='row-size-dependant stretch-width' >
                            <CustomInput label={t("Postal-code", "Postnummer")} value={projekt?.postnummer} onChange={(e) => setProjekt({ ...projekt, postnummer: e.target.value })} />
                            <CustomInput label={t("City", "By")} value={projekt?.by} onChange={(e) => setProjekt({ ...projekt, by: e.target.value })} />
                        </div>

                            <hr/>

                        <div className='row-size-dependant stretch-width' >
                            <CustomInput noIcon={true} label={t("Start-date", "Start-dato")} value={projekt?.startDato} onChange={(e) => setProjekt({ ...projekt, startDato: e.target.value })} type='date' />
                            <CustomInput noIcon={true} label={t("End-date", "Slutt-dato")} value={projekt?.sluttDato} onChange={(e) => setProjekt({ ...projekt, sluttDato: e.target.value })} type='date' />
                        </div>
                        <div className='row-size-dependant stretch-width'>
                            <CustomInput label={t("Normal Clock Start", "Normal Klokke Start")} value={projekt?.klokkeStart} onChange={(e) => setProjekt({ ...projekt, klokkeStart: e.target.value })} type='time' />
                            <CustomInput label={t("Normal Clock End", "Normal Klokke Slutt")} value={projekt?.klokkeSlutt} onChange={(e) => setProjekt({ ...projekt, klokkeSlutt: e.target.value })} type='time' />
                        </div>
                    </div>




                    <div className='stretch-width column' >
                        <div className='column stretch-width' >
                            <h2>{t("Employees on project", "Ansatte på prosjekt")}</h2>
                            <PeopleAndRolesSearchWithPopup onChange={(access) => {
                                setProjekt(old => {
                                    return {
                                        ...old,
                                        access: {
                                            users: access.people.map(user => user.id),
                                            roles: access.roles.map(role => role.id)
                                        }
                                    }
                                })
                            }} addedPeople={projekt?.access.users} addedRoles={projekt?.access.roles} idOnly={false}


                            />
                        </div>
                        {projekt?.lng && projekt?.lat && <MapWithDisplay lat={projekt.lat} lng={projekt.lng} navn={projekt.navn} moreInfo={projekt.addresse} />}
                    </div>
                </div>
                <div className='button-list-container column button-list'>
                    <ButtonListButton icon={People} title={t("Contactperson", "Kontaktpersoner")} onPress={() => setModalOpen(true)} />
                    <ButtonListButton icon={ArchiveIcon} title={t("Sent-Reports", "Sendte-Rapporter")} onPress={() => navigateToNewPage('/reportArchive', { projekt: projekt })} />
                    <ButtonListButton icon={RapportIcon} title={t("Project Documents", "Prosjekt Dokumenter")} onPress={() => navigateToNewPage('/prosjekt/documents', { projekt: projekt })} />
                </div>
            </div>

            <CustomModal open={modalOpen} onClose={() => { setModalOpen(false) }} title={t("Contactperson", "Kontaktpersoner")} items={projekt?.kontaktpersoner} onNew={() => {
                setKontaktPersonAlert(true)
            }} doNotCloseOnNew={true} />
            <CustomModal open={firmaConnectionModalOpen} onClose={() => { setFirmaConnectionModalOpen(false) }} title={t("Customers", "Kunder")} items={customers} onChoise={(item) => {
                setProjekt((old) => {
                    return {
                        ...old,
                        firmaConnection: item.id
                    }
                })
            }} />


            <IonAlert
                key={alertKey}
                isOpen={kontaktPersonAlert}
                onDidDismiss={() => setKontaktPersonAlert(false)}
                header={t("Add new contactperson", "Legg til en ny kontaktperson")}
                inputs={[
                    {
                        name: 'navn',
                        type: 'text',
                        placeholder: 'Navn',
                    },
                    {
                        name: 'epost',
                        type: 'email',
                        placeholder: 'Epost',
                    },
                ]}
                buttons={[
                    {
                        text: 'Avbryt',
                        role: 'cancel',
                        handler: () => {
                            console.log('Confirm Cancel');
                        },
                    },
                    {
                        text: 'OK',
                        handler: (data) => {
                            updateWithAlertData(data);
                            setKontaktPersonAlert(false);
                            setAlertKey(alertKey + 1);
                        },
                    },
                ]}
            />
        </DefaultWrapper >
    )
}


export function MapWithDisplay(props) {
    const mapRef = useRef(null);
    const [mapInstance, setMapInstance] = useState(null);

    useEffect(() => {
        const initializeMapAndAddMarker = async () => {
            const mapOptions = {
                center: {
                    lat: props.lat,
                    lng: props.lng
                },
                zoom: 16,
                disableDefaultUI: true, // This disables the default UI controls
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true
            };
            const map = new window.google.maps.Map(document.getElementById('map-no-border'), mapOptions);

            console.log("Map should be ready!")

            // Add marker immediately after initializing the map
            if (props.lat !== 0 && props.lng !== 0) {
                // Create a marker and set its position and map
                new window.google.maps.Marker({
                    position: {
                        lat: props.lat,
                        lng: props.lng
                    },
                    map: map,
                    title: 'Selected Location'
                });
            }
        }

        if (props.lat !== 0 && props.lng !== 0) {
            initializeMapAndAddMarker();
        }
    }, [props.lat, props.lng]);

    return (
        <div className="component-wrapper button-background" style={{ overflow: "hidden", borderRadius: 20, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            <div id="map-no-border" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}>

            </div>
            {/*
                <div className='padding column small-gap'>
                {props?.icon && <CircleIcon icon={props.icon}></CircleIcon>}
                <div className='row flexApart'>
                    <h2 className='orange'>{props?.navn}</h2>
                    <h2>{props?.info}</h2>
                </div>
                <p>{props?.moreInfo}</p>
                {props?.children}
            </div>
            */}
        </div>
    );
}


function useDebouncedEffect(effect, delay, deps) {
    const callback = useRef();

    // Update the effect callback each render
    useEffect(() => {
        callback.current = effect;
    }, [effect]);

    // Set up the effect callback to only run if the `delay` has passed
    useEffect(() => {
        const handler = setTimeout(() => {
            callback.current();
        }, delay);

        // Clean up the timeout if the component unmounts or if the delay changes
        return () => {
            clearTimeout(handler);
        };
    }, [...deps, delay]); // Reset the timer if any dependency changes
}