import AuthObserver from "./authObserver";
import { CompanyObserver } from "./companyObserver";
import DeBounceObserver from "./deBounceObserver";
import SubscriptionObserver from "./subscriptionObserver";




export default function SetupStoreObservers(store) {






    return (
        <div>
            <AuthObserver />
            <CompanyObserver />
            <SubscriptionObserver />
            <DeBounceObserver />
        </div>
    )
}