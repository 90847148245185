import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import TimeChooser from "../../components/timetracking/timeChooser";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { createId, getProjects } from "../../firebase";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import CustomModal from "../../components/miniComponents/CustomModal";
import ButtonListButton from "../../components/miniComponents/buttonListButton";
import HMSArea, { HMSProject } from "./utils/area";
import { useHistory, useParams } from "react-router";
import { CompanyContext } from "../../contexts/companyContext";
import { deleteHMSReport, getHMSReport, setHMSReport } from "../../database/hms";
import { useSingleFetchEditable } from "../../components/utils/hooks/fetchSingleEditable";
import { useSingleFetchEditableRS } from "../../components/utils/hooks/RSFetchSingleEditable";
import { addHMSReport, removeHMSReport, updateHMSReport } from "../../stores/hmsSlice";
import { useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import DeleteButton from "../../components/miniComponents/deleteButton";

function formatDate(date) {
    // Helper function to format a Date object to "YYYY-MM-DDTHH:MM"

    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = date.getFullYear();

    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    return yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes;
}


export default function RUHSkjema() {
    const params = useParams();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const { companyDetails } = useSelector((state) => state.company);
    const {
        document,
        isLoading,
        setDocument,
        updateData,
        isChanged,
        save
    } = useSingleFetchEditableRS({
        selector: (state) => state.HMS.ruh.reports,
        path: 'HMS/ruh/reports',
        saveFunction: setHMSReport,
        params: {
            id: params?.id,
            type: "ruh",
        },
        autoUpdate: true,
        //setFunction: setHMSByType,
        updateFunction: updateHMSReport,
        addFunction: addHMSReport,
        removeFunction: removeHMSReport,
        key: "document",
        payload: { type: "ruh", id: params.id },
        id: params?.id,
        filters: [
            { field: "originalCreator.uid", operator: "==", value: user.user.uid },
            { field: "status", operator: "!=", value: "sent" },
        ]
    })

    useEffect(() => {
        if (!user) return;
        if (!document) return;
        if (document?.originalCreator) return;
        setDocument({ ...document, originalCreator: user.user });
    }, [user, document]);

    useEffect(() => {
        if (!document) return
        if (!document.companyDetails?.logo) return
        setDocument({ ...document, logo: companyDetails?.logo });
    }, [companyDetails?.logo, document]);

    const navigateToNewPage = (path, state) => {
        history.push(path, state);
    }

    if (!document) return null;
    console.log(document);
    return (
        <DefaultWrapper extraButton={
            <button onClick={async () => {
                deleteHMSReport({ id: document.id, type: "ruh" });
                navigateToNewPage("/HMS", { tab: "RUH" });
            }}>Slett RUH <Delete /></button>
        } lagring={true} onBack={save} >
            <div className="content-ny column">
                <HMSArea skjema={document} setSkjema={setDocument} />
                <HMSProject skjema={document} setSkjema={setDocument} />

                <div className="column small-gap">
                    <h2>Dato</h2>
                    <CustomInput noIcon={true} type="datetime-local" value={document.dato} onChange={(e) => setDocument({ ...document, dato: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Beskriv Hendelsen</h2>
                    <CustomTextareaInput value={document.hendelse} onChange={(e) => setDocument({ ...document, hendelse: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Hva er resultatet</h2>
                    <CustomTextareaInput value={document.resultat} onChange={(e) => setDocument({ ...document, resultat: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Hva er årsaken til hendelsen</h2>
                    <CustomTextareaInput value={document.årsak} onChange={(e) => setDocument({ ...document, årsak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Tiltak for å hindre slik uønsket hendelse igjen</h2>
                    <CustomTextareaInput value={document.tiltak} onChange={(e) => setDocument({ ...document, tiltak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <div className="row flexApart">
                        <h2>Send e-post til:</h2>
                        <button onClick={() => {
                            setDocument({ ...document, emails: [...document?.emails, { email: "", id: createId() }] });
                        }}>Legg til</button>
                    </div>
                    {document?.emails?.map((e, i) => {
                        return (
                            <div key={e.id} className="row center">
                                <CustomInput type="text" value={e.email} 
                                onChange={(e) => {
                                    let temp = [...document.emails];
                                    temp[i] = { ...temp[i], email: e.target.value };
                                    setDocument({ ...document, emails: temp });
                                }} />
                                <DeleteButton onClick={() => {
                                    let temp = [...document.emails];
                                    temp.splice(i, 1);
                                    setDocument({ ...document, emails: temp });
                                }}/>
                            </div>
                        )
                    })
                    }
                </div>

                <div className="row flexApart">
                    <div />
                    <button onClick={() => {
                        navigateToNewPage('/hms/ruh/pdf', document);
                    }}>Se over</button>
                </div>
            </div>
        </DefaultWrapper>
    )

}