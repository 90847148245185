import { doc, setDoc } from "firebase/firestore";
import { companyId, db } from "../firebase";









export async function setCustomer({data }) {
    // Should be moved to server.
    console.log("setting Customer");
    console.log(data);
    const docRef = doc(db, 'Kompanier', companyId(), 'klienter', data.id);
    await setDoc(docRef, data);
}

/*const clientCol = doc(db, 'Kompanier', companyId(), 'klienter', data.id || randomId());
await setDoc(clientCol, data);*/