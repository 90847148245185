import { IonContent, IonHeader, IonModal, IonPage, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
import DefaultWrapper from "../../components/defaultWrapper";
import { createId, createProject, getClientById, getDocumentsByNoProjectId, getProjects, getProjectsByCompanyId } from "../../firebase";
import { useEffect, useState } from "react";
import ButtonListButton from "../../components/miniComponents/buttonListButton";
import CustomInput from "../../components/miniComponents/customInput";

import { ReactComponent as WorkIcon } from '../../assets/icons/material-symbols_work.svg';
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import { useTranslation } from "react-i18next";
import { useSingleFetchEditableRS } from "../../components/utils/hooks/RSFetchSingleEditable";
import { addCustomer, removeCustomer, updateCustomer } from "../../stores/customerSlice";
import { setCustomer } from "../../database/customers";
import OrgNameInput from "../../components/Customers and Projects/orgNameInput";
import { noUndefined } from "../../components/utils/noUndefined";


export default function Client(props) {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory();
    const params = useParams();

    const {
        customer,
        isLoading,
        setData,
        updateData,
        isChanged,
        save
    } = useSingleFetchEditableRS({
        selector: (state) => state.customers.byId[params?.id],
        colSelector: (state) => state.customers.allIds,
        path: 'klienter',
        saveFunction: setCustomer,
        //setFunction: setHMSByType,
        updateFunction: updateCustomer,
        addFunction: addCustomer,
        removeFunction: removeCustomer,
        autoUpdate: true,
        returnItemsByIdSelector: (state) => state.customers.byId,

        key: "customer",
        id: params?.id,
        filters: [
        ]
    })

    console.log(customer)



    const [firma, setFirma] = useState({
        navn: "",
        addresse: "",
        postnummer: "",
        by: "",
        orgNummer: "",
        arbeidsplass: "",
        kontaktpersoner: [{ navn: "", email: "" }],
        id: createId(),
        ...history?.location?.state
    })


    const [projects, setProjects] = useState([])
    /*
    useEffect(() => {
        //listen to client updates in firebase
        //update client list
        const onUpdate = (querySnapshot) => {
            console.log(querySnapshot)
            setFirma(querySnapshot)
        }
        console.log(history.location.state)
        getClientById(history.location.state.id, onUpdate)
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        const onUpdate = (data) => {
            setProjects(data)
        }

        getProjectsByCompanyId(firma, onUpdate)
    }, [])
    */

    if (!customer) return <div>Loading...</div>
    return (
        <DefaultWrapper>
            <div className="content-ny column big-gap">
                <div className="row flexApart">
                    <h1>{customer.name}</h1>
                    <div className="row">
                        <h3>ID</h3>
                        <h3>{customer?.id}</h3>
                    </div>
                </div>

                <div className="row big-gap column-on-phone stretch-width">
                    <div className="column stretch-width">


                        <h3>{t("Name", "Navn")}</h3>
                        <OrgNameInput type="text" placeholder="Firma / Navn" value={customer.name}
                            onChange={(e) => { setData({ ...customer, name: e.target.value }); }}
                            onChoise={(suggestion) => {
                                setData(old => {return { ...old, ...noUndefined(suggestion) } })
                            }}
                        />
                        <h3>{t("Organization Number", "Organisasjonsnummer")}</h3>
                        <CustomInput type="text" placeholder="Organisasjonsnummer" value={customer.orgNumber}
                            onChange={(e) => { setData({ ...customer, orgNumber: e.target.value }); }}
                        />
                        <h3>{t("Address", "Adresse")}</h3>
                        <SearchAddressesWithPopup lng={customer?.lng} lat={customer?.lat} value={customer.address} onChange={
                            (address) => {
                                console.log(address)
                                setData((old) => {
                                    return { ...old, address: typeof address === "string" ? address : address?.address || "" }
                                })
                            }
                        } onChoise={(addresse) => {
                            console.log(addresse)
                            
                            setData((old) => {
                                console.log(old)
                                return {
                                    ...old,
                                    address: addresse.addresse,
                                    postalCode: addresse.postalCode,
                                    city: addresse.city,
                                    lat: addresse.lat,
                                    lng: addresse.lng
                                }
                            })
                        }} />
                    </div>

                    <div className='column stretch-width'>
                        <div className='flexApart'>
                            <h1>{t("Projects", "Prosjekter")}</h1>
                            <button className='ny' onClick={() => {
                                console.log(history.location.state)
                                navigateToNewPage('/prosjekt/edit', { firma: firma }) // Edit later
                            }}>{t("New", "Ny")} +</button>
                        </div>

                        <div className='downContainer column big-gap button-list'>
                            {projects?.map((project) => {
                                return <ButtonListButton title={project.navn} description={project.address + (project.postnummer ? ", " + project.postnummer : "")}
                                    icon={WorkIcon} key={project.id} onClick={
                                        () => navigateToNewPage(`/prosjekter/${project.id}`, null)
                                    } />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    );
}