import React, { useEffect, useState, Component } from 'react';
import Konva from 'konva';

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export async function CropImage(imageProps, setInfo) {
    return new Promise((resolve, reject) => {
        try {
            // Define the Konva stage
            let stage = new Konva.Stage({
                container: "hidden",
                width: imageProps.square.width,
                height: imageProps.square.height,
            });

            // Define the layer and add it to the stage
            let layer = new Konva.Layer();
            stage.add(layer);

            // Define the image and add it to the layer
            let image = new Konva.Image({
                x: 0,
                y: 0,
                image: imageProps.image,
                width: imageProps.square.width,
                height: imageProps.square.height,
            });
            layer.add(image);

            // Create the data URL
            var dataURL = stage.toDataURL({
                x: 0,
                y: 0,
                width: imageProps.square.width,
                height: imageProps.square.height
            });

            // Convert the data URL to a blob
            let blob = dataURItoBlob(dataURL);

            // Convert the blob to a file
            let file = new File([blob], "filename.png", { type: "image/png" });
            console.log(file);
            // Resolve the Promise with the file
            resolve(file);
        } catch (error) {
            // If anything goes wrong, reject the Promise with the error
            reject(error);
        }

    });
}


//const file = new File([blob], "myTest.png", { type: "image/png" });


// Create File with Image and File Name
// Return a file that I can upload with firebase: 
/* export async function handleUpload(file, folderName, fileName) {
const fileType = file.type.split('/')[0];
//if (file?.size > 5000000) return { type: "error", msg: "File Too Big" }//window.ui.setState({ otherUI: ["test"] })
if (fileType !== "image" && fileType !== "video") return { type: "error", msg: "File Type Not Supported" }
const storage = getStorage();
const id = folderName && fileName ? `${companyId() + "/" + folderName + "/"} ${fileName}` : `bart${Math.floor(Math.random() * 10000000000000)}`
const storageRef = ref(storage, id || "test.png");

// 'file' comes from the Blob or File API
const ImageUrl = await uploadBytes(storageRef, file).then(async (snapshot) => {
    console.log('Uploaded a blob or file!');
    const url = await getDownloadURL(storageRef)
    return url
})
return ImageUrl
} */








//setInfo(dataURL)
//setInfo(dataURL)
//return dataURL

//imageProps.canvas.width / 2 - imageProps.square.width / 2
// MAKE THIS HAPPEN AFTER IMAGE IS COMPLETE.
// DO ANOTHER STAGE FIRST TO GET IMAGE PLACEMENTS


/*
let url = new Promise((resolve, reject) => {
    var imageObj = new Image();
    imageObj.src = image //URL   --- Change this to the URL when in production
    imageObj.crossOrigin = "anonymous";
    imageObj.onload = function (e) {
        /*let calculation = calculateAspectRatioFit(imageObj.naturalWidth, imageObj.naturalHeight, 400, 2000)

        function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

            return { width: srcWidth * ratio, height: srcHeight * ratio };
        }*//*
var image = new Konva.Image({
x: -200,
y: 0,
image: imageObj,
width: 400,
height: 400
});
layer.add(image);



/*image.toDataURL({
callback: async (uri) => {
var blob = dataURItoBlob(uri);
const file = new File([blob], "myTest.png", { type: "image/png" });
const upload = handleUpload(file)
resolve(upload)
}
});
};
})*/





//return url




export async function createSmallImageFromInput(inputEvent) {
    const file = inputEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onload = (e) => {
            console.log(e.target.result)

            const loadImage = () => {
                const src = e.target.result;
                // save to "this" to remove "load" handler on unmount



                let image = new Image();

                const handleLoad = () => {
                    resolve (CropImage({
                        square: { width: 24, height: 24 },
                        image: image,
                    }))
                }

                image.addEventListener('load', handleLoad);
                image.src = src;
                //this.image.crossOrigin = 'Anonymous';
            }
            loadImage()
        }
    })
}

export async function createMediumImageFromInput(inputEvent) {
    const file = inputEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onload = (e) => {
            console.log(e.target.result)

            const loadImage = () => {
                const src = e.target.result;
                // save to "this" to remove "load" handler on unmount



                let image = new Image();

                const handleLoad = () => {
                    resolve (CropImage({
                        square: { width: 124, height: 124 },
                        image: image,
                    }))
                }

                image.addEventListener('load', handleLoad);
                image.src = src;
                //this.image.crossOrigin = 'Anonymous';
            }
            loadImage()
        }
    })
}