import styled from "@emotion/styled";


export const customSxStyles = {
    // Input text color
    '& .MuiAutocomplete-inputRoot': {
        color: "white",
        backgroundColor: "var(--input-background)"
    },
    // Focused input border color
    '& .MuiAutocomplete-inputFocused': {
        borderColor: "var(--contrastColor)",
    },
    // Normal input border color
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
    },
    // Hover input border color
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "var(--contrastColor)",
    },
    // Label color
    '& .MuiInputLabel-root': {
        color: "white",
    },
    // Focused label color
    '& .Mui-focused .MuiInputLabel-root': {
        color: 'var(--contrastColor) !important',
    },
    // Shrunk label color
    '& .MuiInputLabel-shrink': {
        color: 'var(--contrastColor) !important',
    },
    // Autocomplete paper styles
    '& .MuiAutocomplete-paper': {
        borderTop: '2px solid var(--contrastColor)',
        borderBottom: '2px solid var(--contrastColor)',
        backgroundColor: "red",
    },
    // No options background color
    "& .MuiAutocomplete-noOptions": {
        backgroundColor: "red",
    },

    "& .MuiAutocomplete-listbox": {
        backgroundColor: "var(--input-background)",
    },
};

export const CustomPaper = styled('div')({
    backgroundColor: "var(--input-background)",
    borderRadius: 5,
});