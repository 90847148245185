
import React, { useState, useEffect } from 'react';
import { setReportFirebase, getNotSentReports, getReportNumber, getSentReportsbyProject, getSentReports, setReportNumber } from '../../../firebase';
import RapportCard from '../../../components/rapport-card';
import { IonContent, IonHeader, IonPage, IonSelect, IonSelectOption, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import DefaultWrapper from '../../../components/defaultWrapper';

import { GoogleMap } from '@capacitor/google-maps';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function SenteRapporter(props) {
    const { t } = useTranslation();
    const [sent, setSent] = useState([]);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        getSentReportsFromFirebase();
    }, [location.state]);

    async function getSentReportsFromFirebase() {
        const onUpdate = (querySnapshot) => {
            console.log(querySnapshot)
            setSent(querySnapshot)
        };
        console.log(history.location.state)
        const projekt = history?.location?.state?.projekt
        console.log(history.location.state)


        const newNotSent = await getSentReportsbyProject(projekt, onUpdate)
        setSent(newNotSent)
    }

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    return (
        <DefaultWrapper>
            <div className='content-ny'>
                <div className='rapportContainer column'>
                    <div className='flexApart'>
                        <h1>{t("Sent", "Sendte")}</h1>
                    </div>
                    <div className='column button-list'>
                        {sent.map((report, index) => {
                            return <RapportCard key={report.id} report={report} onPress={(raport) => navigateToNewPage('pdfViewer', raport)} />
                        })}
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    )
}
