import { Delete } from "@mui/icons-material";
import CircleIcon from "./circleIcon";




export default function DeleteButton(nonFilteredProps) {
    const { onDelete, onClick, ...props } = nonFilteredProps;
    return (
        <button type='button' 
        {...props }
        onClick={() => {
            if (onDelete) onDelete();
            if (onClick) onClick();
        }}>
            <CircleIcon icon={Delete} className='red'></CircleIcon>
        </button>
    );
}
