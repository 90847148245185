import { createContext, useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import Timeføring from "./timeføring";
import TopBarChoosePage from "../../components/miniComponents/choosePageTopBar";
import TimeOverview from "./timeOverview";
import Timeliste from "../timeføring/timeliste";
import { CheckIfOnPC, TimeContextProvider } from "../../App";
import { useTranslation } from "react-i18next";




export default function VanligOversiktMenu() {
    const { t } = useTranslation()

    useEffect(() => {
        //getTimeTrackedForUser()
    }, [])

    return (
        <TimeContextProvider>
            <DefaultWrapper noBackButton={CheckIfOnPC()}>
                <div className="content-ny column">
                    <TopBarChoosePage options={[
                        { name: t("Work Schedule", "Arbeidsplan"), component: <Timeliste /> },
                        { name: t("Overview", "Oversikt"), component: <TimeOverview /> }
                    ]} />
                    {/*
                        <TopBarChoosePage options={[{ name: "Timeføring", component: <Timeføring /> }, { name: "Arbeidsplan", component: <Timeliste /> }, { name: "Oversikt", component: <TimeOverview /> }]} />
                    */}
                </div>
            </DefaultWrapper>
        </TimeContextProvider>
    )
}