import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { Person, SwapHorizRounded, SwitchAccessShortcut } from "@mui/icons-material";
import { useSelector } from "react-redux";









export default function UserOrAnonymous({ value, onChange }) {
    //const user = useSelector(state => state.user.user);

    const CU = useSelector(state => state.user.companyUser);
    const [user, setUser] = useState(value || {
        id: CU?.id, name: CU?.name || CU?.navn, email: CU?.email
    });
    useEffect(() => {
        onChange(user);
    }, [user])

    console.log(CU)

    return (
        <div className="column">
            <div className="row flexApart stretch-width">
                <h2> Bruker </h2>
                <button type="button" onClick={() => setUser(old => (user?.id || user?.uid) ?
                    { name: "Anynom", email: "Anynom" }
                    :
                    {
                        id: CU?.id || CU?.uid, name: CU?.name || CU?.navn, email: CU?.email
                    }
                )}>Bytt til {(user?.id || user?.uid) ? " Anonym" : " Meg"} <SwapHorizRounded /></button>
            </div>
            <ButtonListButton title={user?.name || user?.navn} description={user?.email} icon={Person} />
        </div>
    )
}