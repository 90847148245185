







export default function FullscreenModal({ title, open, setOpen, onClose, noSearch, children }) {
    return (
        <div className={"fullscreen-modal " + (open ? "open" : "")}>
            {children}
        </div>
    )
}