
import { useEffect, useState } from "react";
import { ClockIcon } from "@mui/x-date-pickers";
import { ArrowDropDown } from "@mui/icons-material";
import CustomCheckboxModal from "../customCheckboxModal";
import CircleIcon from "../../miniComponents/circleIcon";

const begrunnelser = [
    { name: "Opplysning- og informasjonsplikt", id: 1 },
    { name: "Utforming og innredning", id: 2 },
    { name: "Aktivitet og hvile", id: 3 },
    { name: "Måltid", id: 4 },
    { name: "Psykososiale forhold", id: 5 },
    { name: "Rengjøring og vedlikehold", id: 6 },
    { name: "Sikkerhet og helsemessig beredskap", id: 7 },
    { name: "Førstehjelp", id: 8 },
    { name: "Tilrettelegging av helseforhold", id: 9 },
    { name: "Smittevern", id: 10 },
    { name: "Inneklima", id: 11 },
    { name: "Belysning", id: 12 },
    { name: "Lydforhold", id: 13 },
    { name: "Drikkevann", id: 14 },
    { name: "Sanitære forhold", id: 15 },
    { name: "Avfallsbehandling", id: 16 },
    { name: "Brannsikkerhet", id: 17 },
    { name: "Fysiske belastninger", id: 18 },
    { name: "Kjemisk og biologisk helsefare", id: 19 },
    { name: "Stråling", id: 20 },
    { name: "Maskiner og utstyr", id: 21 },
    { name: "Elektrisk anlegg", id: 22 },
    { name: "Skade", id: 23 },
    { name: "Tilløp til skade", id: 24 },
    { name: "Annet avvik", id: 99 },
]

export default function AvvikOptionsModalButton({ values, label, icon, title, onChange }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [info, setInfo] = useState(values ? values : []);

    useEffect(() => {
        if (values) setInfo(old => values)
    }, [values])

    return (
        <div className="button-list stretch-width column" >
            <button type={"button"} className="button-list-button stretch-width" onClick={() => {
                setModalVisible(true)
            }}>
                <div className="row relative center-column stretch-width flexApart">
                    <label className={"button-list-button-label " + (info.length ? "" : "button-list-button-label-not-active " )}>{label}</label>
                    <div className="row center-column">
                        <CircleIcon icon={ClockIcon} />
                        <p>{
                            info.map((i, index) => {
                                return index === 0 ? i.name : ', ' + i.name
                            })
                        }</p>
                    </div>
                    <ArrowDropDown />
                </div>

            </button>
            <CustomCheckboxModal
                visible={modalVisible}
                values={info}
                items={begrunnelser}
                onChange={(e) => {
                    if (onChange) onChange(e);
                    setInfo(old => e)
                }} onClose={() => setModalVisible(false)} />
        </div>
    )
}