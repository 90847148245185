




export async function getCompanyFromBrregByOrgNr(orgnumber) {

    //Remove spaces from orgnr
    const orgnr = orgnumber?.replace(/\s/g, '');
    if (!orgnr) return;
    if (orgnr.length !== 9) return;

    // Querying the Enhetsregisteret API
    /*const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
    const response = await axios.get(apiUrl);*/
    // Use fetch instead
    const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
    const apiUrlRoller = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}/roller`;
    const brregData = await fetch(apiUrl).then(response => response.json());

    const temp = {
        name: brregData?.navn,
        address: brregData?.forretningsadresse?.adresse[0],
        postalCode: brregData?.forretningsadresse?.postnummer,
        city: brregData?.forretningsadresse?.poststed,
    }

    return temp;
}


export async function getCompaniesFromBrregByOrgName(name) {
    const baseUrl = "https://data.brreg.no/enhetsregisteret/api/enheter"; // base URL for the entity search
    const url = `${baseUrl}?navn=${encodeURIComponent(name)}`; // constructing the URL with query parameter for name
    const suggestions = await fetch(url)
    const response = await suggestions.json()
    const data = response?._embedded?.enheter

    return data;
}