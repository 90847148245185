import { db, app, getCompany, getCompanyId, companyId } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs, query, where, onSnapshot, doc, getDoc, updateDoc, setDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { listener, makeDocsIntoData } from './utils/easeHookReading';

//useCustomHook Function
export async function getHMSReports({ type }, onUpdate) {
    const q = query(collection(db, 'Kompanier', companyId(), 'HMS', type, 'reports'));
    const querySnapshot = await getDocs(q);
    console.log("querySnapshot", querySnapshot);
    const dataArray = makeDocsIntoData(querySnapshot);
    // Listen to changes in reports
    const unsubscribe = listener(q, onUpdate);
    return { value: dataArray, subscription: unsubscribe };
}

export async function uploadHMSReport(report) {
    const functions = getFunctions(app, 'europe-west2');
    const upload = httpsCallable(functions, 'uploadHMS');
    return upload({ report, companyId: getCompanyId() });
}

export async function uploadHMSReportToCheck({ type, report }) {
    const functions = getFunctions(app, 'europe-west2');
    const upload = httpsCallable(functions, 'uploadHMSReportToCheck');
    return upload({ type, report, companyId: getCompanyId() });
}

export async function getHMSReport({ type, id }) {
    const docRef = doc(db, 'Kompanier', companyId(), 'HMS', type, 'reports', id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

export async function setHMSReport({data, type }) {
    // Should be moved to server.
    console.log("setting report");
    console.log(data);
    const docRef = doc(db, 'Kompanier', companyId(), 'HMS', type, 'reports', data.id);
    await setDoc(docRef, data);
}

export async function deleteHMSReport({ type, id }) {
    const docRef = doc(db, 'Kompanier', companyId(), 'HMS', type, 'reports', id);
    const deletedDocRef = collection(db, 'Kompanier', companyId(), 'HMS', type, 'deleted');

    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    try {
        await addDoc(deletedDocRef, data);
        await deleteDoc(docRef);
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}