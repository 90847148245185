import DefaultWrapper from "../../components/defaultWrapper";
import ButtonListButton from "../../components/miniComponents/buttonListButton";

import { ReactComponent as ChatIcon } from '../../assets/icons/material-symbols_chat.svg';
import { ReactComponent as RapportIcon } from '../../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as AvvikIcon } from '../../assets/icons/hms/SJA.svg';
import { ReactComponent as SJAIcon } from '../../assets/icons/hms/AvvikIcon.svg';
import { useHistory } from "react-router";

export default function HMSMeny() {
    const history = useHistory();


    return (
        <DefaultWrapper >
            <div className="content-ny column">
                <h1>HMS</h1>
                <div className="row button-list">
                    <ButtonListButton
                        title="RUH Skjema"
                        description="Risiko og ulykkes hendelse"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/ruh")
                        }}
                    />
                    <ButtonListButton
                        title="Avvik"
                        description="Avviksrapport"
                        icon={AvvikIcon}
                        onClick={() => {
                            history.push("/hms/avvik")
                        }}
                    />
                    <ButtonListButton
                        title="SJA Skjema"
                        description="Sikker jobb analyse"
                        icon={SJAIcon}
                        onClick={() => {
                            history.push("/hms/sja")
                        }
                        }
                    />
                    <ButtonListButton
                        disabled={true}
                        title="Arkiv"
                        description="Arkiv av alle HMS rapporter"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/arkiv")
                        }
                        }
                    />
                    {/*<ButtonListButton
                        title="Vernerunde"
                        description="Vernerunde"
                        icon={RapportIcon}
                        onClick={() => {
                            history.push("/hms/vernerunde")
                        }}
                    />
                    <ButtonListButton
                        title="Stoffkartotek"
                        description="Stoffkartotek"
                        icon={RapportIcon}
                        onClick={() => {
                            //history.push("/hms/stoffkartotek")
                        }}
                    />
                    */}
                </div>
            </div>
        </DefaultWrapper>
    )
}