import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";
import CustomInput from "../../../components/miniComponents/customInput";
import { createId, deleteTimeListForUser, getClients, getProjects, getProjectsByCompanyId, setTimeListForUser, updateTimeListForUser } from "../../../firebase";
import { SearchWithPopup } from "../../../components/searchWithPopups/searchWithPopup";


//material-symbols_compare-arrows-rounded
import { ReactComponent as SwitchIcon } from '../../../assets/icons/material-symbols_compare-arrows-rounded.svg';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';
import CustomDropdown from "../../../components/miniComponents/customDropdown";
import { useHistory } from "react-router";
import { UserContext } from "../../../App";


import * as React from 'react';
import TextField from '@mui/material/TextField';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import UtstyrSearchWithPopup from "../../../components/searchWithPopups/utstyrSearch";
import { Autocomplete } from "@mui/material";
import { CustomPaper, customSxStyles } from "../timeføring";
import { Avatar } from "@mui/material";
import CustomModal from "../../../components/miniComponents/CustomModal";
import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { useTranslation } from "react-i18next";
import { getCompanyUsers } from "../../../database/users";
import { useSelector } from "react-redux";

const StyledTextField = styled(TextField)({
    // Add your custom styles here
    width: '100%',
    marginBottom: '20px',
    // Example: Change the text field border color
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'blue',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'purple',
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',  // This sets the theme mode to dark
    },
});




export default function EditTimeArbeidsplan() {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);
    const history = useHistory();

    const timesToAdd = history.location.state?.time ?
        {
            startDate: toTimeString(createDateOutOfFirebaseTimestamp(history.location.state?.time?.startDate)),
            endDate: toTimeString(createDateOutOfFirebaseTimestamp(history.location.state?.time?.endDate)),
            dato: createDateOutOfFirebaseTimestamp(history.location.state?.time?.dato),
        } : {}

    const [time, setTime] = useState({
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        place: {
            by: "By",
            addresse: "Adresse",
            postnummer: "Postnummer"
        },
        klient: null,
        multipleDays: false,
        dato: "",
        secondDate: "",
        notificationSent: false,
        prosjekt: null,
        bruker: companyUser,
        utstyr: [],
        ...history.location.state?.time,
        ...timesToAdd
    });
    console.log(time);
    console.log(history.location.state?.time);

    const [people, setPeople] = useState([]);
    const [klienter, setKlienter] = useState([]); // [ {id: 1, name: "Klient 1"}, {id: 2, name: "Klient 2"}
    const [projects, setProjects] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);


    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateChange = (date) => {
        const newDate = date.toDate()
        console.log(newDate);
        console.log(newDate.getTime());
        console.log(selectedDates);
        // If it isn't already in the array, add it
        if (!selectedDates.find((selectedDate) => selectedDate?.getTime() === newDate?.getTime())) {
            setSelectedDates([...selectedDates, newDate]);
        }
        // Otherwise, remove it
        else {
            setSelectedDates(selectedDates.filter((selectedDate) => selectedDate?.getTime() !== newDate?.getTime()));
        }
    };




    useEffect(() => {
        const onUpdate = (data) => {
            setPeople(data)
        }

        async function getCompanypeople(onUpdate) {
            await getCompanyUsers(null, onUpdate)
        }
        getCompanypeople(onUpdate)
    }, []);

    useEffect(() => {
        const onUpdate = (data) => {
            setKlienter(data)
        }

        async function getCompanyClients(onUpdate) {
            await getClients(onUpdate)
        }
        getCompanyClients(onUpdate)
    }, []);

    useEffect(() => {
        const onUpdate = (data) => {
            setProjects(data)
        }

        async function getCompanyProjects(onUpdate) {
            await getProjects(onUpdate)
        }
        getCompanyProjects(onUpdate)
    }, []);

    const flattenedOptions = projects.map((project) => {
        return {
            ...project,
            clientName: project.navn,
            clientId: project.id
        }
    })


    const onDone = async () => {
        console.log(time);
        await updateTimeListForUser(time, time.bruker, time.dato);

        history.goBack();
    }

    const extraButton = <button onClick={onDone}>Ferdig <Checkmark /></button>


    return (
        <DefaultWrapper extraButton={extraButton}>

            <div className="content-ny">
                <div className="column small-gap">
                    <h3>{t("employee", "Ansatt")}</h3>
                    <div className="row center-column flexApart">
                        <div className="row center-column">
                            <Avatar src={time.bruker?.profilePicture} />
                            <h2>{time.bruker?.navn}</h2>
                        </div>
                        <button onClick={() => {
                            setModalOpen(true)
                        }}>{t("Switch employee", "Bytt ansatt")}</button>
                    </div>
                    <CustomModal visible={modalOpen} onClose={() => setModalOpen(false)} title="Bytt Ansatt" items={people}
                        onChoise={(ansatt) => {
                            console.log(ansatt)
                            setTime({
                                ...time,
                                bruker: ansatt
                            })
                            setModalOpen(false)
                        }} />
                </div>

                <Autocomplete
                    id="Timeføring"
                    PaperComponent={CustomPaper}
                    value={time?.prosjekt || { navn: "" }}
                    options={flattenedOptions}
                    filterOptions={(options, { inputValue }) => {
                        try {
                            let results = [];
                            let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                            options.forEach(option => {
                                // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                if (option && option?.clientName && option?.clientName.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                                else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                                    results.push(option);
                                }
                            });

                            return results;
                        } catch (error) {
                            console.error("Error filtering options:", error);
                            return []; // Return an empty array in case of error
                        }
                    }}
                    groupBy={(option) => option?.clientName}
                    getOptionLabel={(option) => option?.nummer ?  option?.nummer + " - " + option.navn : option.navn}
                    style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                    sx={{ ...customSxStyles, minWidth: 200, backgroundColor: "var(--input-background)", backgroundClip: "var(--input-background)" }}
                    renderInput={(params) => <TextField {...params} label={t("Project", "Prosjekt")} />}
                    renderGroup={(params) => (
                        <div key={params.key} style={{ background: "var(--input-background)" }}>
                            <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                            <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                {params.children}
                            </p>
                        </div>
                    )}
                    fullWidth={true}
                    onChange={async (e, value) => {
                        console.log(value)
                        if (!value) return setTime((old) => { return { ...old, kunde: null, prosjekt: null } })
                        setTime((old) => { return { ...old, prosjekt: value } })
                    }}
                />
                <div className="column small-gap">
                    <div className="column small-gap">
                        <CustomInput label={t("Date","Dato")} type="date" value={toDateString(time.dato)}
                            noIcon={true} onChange={(e) => setTime({ ...time, dato: e.target.value })} />
                    </div>
                </div>

                <div className="row">
                    <div className="column small-gap">
                        <h3>{t("Start time", "Start tid")}</h3>
                        <CustomInput type="time" noIcon={true} value={time.startDate} onChange={(e) => setTime({ ...time, startDate: e.target.value })} />
                    </div>
                    <div className="column small-gap">
                        <h3>{t("End Time", "Ende tid")}</h3>
                        <CustomInput type="time" noIcon={true} value={time.endDate} onChange={(e) => setTime({ ...time, endDate: e.target.value })} />
                    </div>
                </div>

                <div className="column small-gap">
                    <h3>{t("Comment", "Kommentar")}</h3>
                    <CustomInput type="text" value={time.comment} onChange={(e) => setTime({ ...time, comment: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h3>{t("Equipment", "Utstyr")}</h3>
                    <UtstyrSearchWithPopup onChange={(e) => setTime({ ...time, utstyr: e })} />
                </div>

                <div className="column small-gap flexApart">
                    <div />
                    <button className="recording" onClick={async () => {
                        await deleteTimeListForUser(time)
                        history.goBack();
                    }}
                    >{t("Delete plan", "Slett plan")}</button>
                </div>

            </div>
        </DefaultWrapper>
    )
}

const getDaysBetweenDates = (startDate, endDate) => {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
}

export function toTimeString(dateToConvert) {
    const date = createDateOutOfFirebaseTimestamp(dateToConvert)
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function toDateString(dateToConvert) {
    console.log(dateToConvert);
    const date = createDateOutOfFirebaseTimestamp(dateToConvert)
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
    let day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}