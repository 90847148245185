import { useEffect, useState } from "react";
import { SearchWithPopup } from "./searchWithPopup";
import { useTranslation } from 'react-i18next';
import { getCompanyUsers } from "../../database/users";
import { getRoles } from "../../database/company";
import { useCustomFetch } from "../utils/hooks/fetchHook";




export default function PeopleAndRolesSearchWithPopup({ onChange, addedRoles = [], addedPeople = [], label, idOnly = true }) {
    const { t } = useTranslation();
    //const [peopleInCompany, setPeopleInCompany] = useState([]);
    //const [roles, setRoles] = useState([]);
    const [rolesAndPeople, setRolesAndPeople] = useState([]);

    const [chosenPeople, setChosenPeople] = useState([]);
    const { roles, rolesisLoading } = useCustomFetch(getRoles, {}, "roles");
    const { peopleInCompany, peopleInCompanyisLoading } = useCustomFetch(getCompanyUsers, {}, "peopleInCompany");
    const [chosenRoles, setChosenRoles] = useState([]);
    const [chosenRolesAndPeople, setChosenRolesAndPeople] = useState([]);

    const [initialRoles, setInitialRoles] = useState([...addedRoles]);
    const [initialPeople, setInitialPeople] = useState([...addedPeople]);
    console.log(initialRoles, initialPeople)

    useEffect(() => {
        // set initial if ID only.
        console.log(roles, peopleInCompany, initialRoles, initialPeople)
        console.log(initialRoles.length > 0, chosenRoles.length === 0, initialPeople.length > 0, chosenPeople.length === 0)
        if (initialRoles.length > 0 && chosenRoles.length === 0) {
            console.log("setting roles", roles.filter(role => initialRoles.includes(role.id)))
            setChosenRoles(old => roles.filter(role => initialRoles.includes(role.id)))
        }
        if (initialPeople.length > 0 && chosenPeople.length === 0) {
            console.log("setting people", peopleInCompany.filter(person => initialPeople.includes(person.id)))
            setChosenPeople(old => peopleInCompany.filter(person => initialPeople.includes(person.id)))
        }
    }, [roles, peopleInCompany])


    useEffect(() => {

        onChange({ people: idOnly ? chosenPeople.map(person => person.id) : chosenPeople, roles: idOnly ? chosenRoles.map(role => role.id) : chosenRoles})
    }, [chosenPeople, chosenRoles])

    useEffect(() => {
        // set roles and people, do not include chosen people and roles
        //const chosen = [...chosenRoles, ...chosenPeople];
        const typedRoles = roles.map(role => ({ ...role, type: "role" }))
        const typedPeople = peopleInCompany.map(person => ({
            ...person,
            type: "person",
            searchInfo: person?.roles?.some(role =>
                chosenRoles.some(chosenRole => chosenRole.id === role.id)
            )
                ? `Allerede lagt til mellom ${chosenRoles.find(role => role.id === person.roles?.[0]?.id)?.navn || "ukjent"}`
                : ""
        }));

        setRolesAndPeople([...typedRoles, ...typedPeople].filter(item => {
            if (item.type === "role") {
                return !chosenRoles.some(chosenRole => chosenRole.id === item.id)
            } else {
                return !chosenPeople.some(chosenPerson => chosenPerson.id === item.id)
            }
        }))
        //setRolesAndPeople(old => chosen)
    }, [roles, peopleInCompany, chosenPeople, chosenRoles])

    useEffect(() => {
        console.log(chosenRolesAndPeople)
        console.log([...chosenRoles, ...chosenPeople])
        setChosenRolesAndPeople([...chosenRoles, ...chosenPeople])
    }, [chosenRoles, chosenPeople])

    return (
        <SearchWithPopup selected={chosenRolesAndPeople} items={rolesAndPeople} label={label} onChoise={
            (item) => {
                console.log(item)
                if (item.type === "role") {
                    setChosenRoles([...chosenRoles, item])
                }
                if (item.type === "person") {
                    setChosenPeople([...chosenPeople, item])
                }
            }
        }
            onRemove={(item) => {
                if (item.type === "role") {
                    setChosenRoles(chosenRoles.filter(role => role.id !== item.id))
                }
                if (item.type === "person") {
                    setChosenPeople(chosenPeople.filter(person => person.id !== item.id))
                }
            }}
        />
    )
}