import { getClients, getCompany } from "../../firebase";
import CustomModal from "../miniComponents/CustomModal";
import { useContext, useEffect, useState } from "react";

import { getCompanyTimeSettings } from "../../database/timetracking";
import CustomCheckboxModal from "./customCheckboxModal";
import { useCustomFetch } from "../utils/hooks/fetchHook";






export default function TimetypeModal({ visible, show, onChange, includeSelf, label, adaptToChoise, title, onClose, values }) {
    const { data } = useCustomFetch(getCompanyTimeSettings);
    console.log(data);

    return (
        <CustomCheckboxModal items={data?.tillegg} visible={visible || show}
            onChange={(e) => {
                if (onChange) onChange(e);
            }}
            adaptToChoise={adaptToChoise}
            onClose={() => {
                if (onClose) onClose();
            }}
            title={title}
            label={label}
            values={values}
        />
    )
}