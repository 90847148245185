

import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase'; // Firebase config
import { useDispatch } from 'react-redux'; // Redux for handling state
import { loginSuccess, logout, logoutUser } from '../authSlice';

const AuthObserver = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { displayName, email, photoURL, uid } = user;
        dispatch(loginSuccess({ displayName, email, photoURL, uid }));
      } else {
        dispatch(logoutUser());
      }
    });

    // Clean up listener on unmount
    return () => unsubscribe();
  }, [dispatch]);

  return null; // This component just listens for auth changes
};

export default AuthObserver;
