import { useHistory } from "react-router";
import DefaultWrapper from "../../components/defaultWrapper";
import { useState } from "react";
import TopBarChoosePageGoogleVersion from "../../components/miniComponents/choosePageTopBarGoogleVersion";
import { useTranslation } from "react-i18next";
import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react";
import { FileCopyOutlined } from "@mui/icons-material";
import { add } from "ionicons/icons";
import AvvikOverview from "./overview/AvvikOverview";
import { useDispatch } from "react-redux";
import { createNewHMSReport } from "../../stores/hmsSlice";
import { createId } from "../../firebase";
import SjaOverview from "./overview/SjaOverview";
import RUHOverview from "./overview/RuhOverview";
import CreateNewHMSReportButton from "./utils/create-new-button";







export default function HMSOverview() {
    const { t } = useTranslation();
    const dispatch = useDispatch();




    const history = useHistory();

    function navigateToNewPage(path, information) {
        history.replace({
            pathname: path,
            state: information
        });
    }



    return (
        <DefaultWrapper noBackButton={true} header={true} noPaddingTop={true} noMenuButton={true}>

            <TopBarChoosePageGoogleVersion options={[
                {name: t("RUH", "RUH"), component: <RUHOverview/>},
                {name: t("Deviation", "Avvik"), component: <AvvikOverview/>},
                {name: t("SJA", "SJA"), component: <SjaOverview/>},
                {/*name: t("Risk Analysis", "Risikoanalyse"), component: <div></div>*/}
            ]} />


            <CreateNewHMSReportButton />
        </DefaultWrapper>
    )
}