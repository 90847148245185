import { PDFstyles } from "./styles"
import Logo from "../../../assets/logo.png";
import { Image, Text, View } from "@react-pdf/renderer";



export function P(props) {
    let { style } = props;
    style ??= [];

    return (<Text
        {...props}
        style={[
            PDFstyles.text,
            props.noWrap ? PDFstyles.wrapText : {},
            ...style
        ]}
    >{props.children}</Text>)
}

export function H1(props) {
    let { style } = props;
    style ??= [];

    return (<Text
        {...props}
        style={[
            PDFstyles.text,
            PDFstyles.h1,
            props.noWrap ? PDFstyles.wrapText : {},
            ...style
        ]}
    >{props.children}</Text>)
}

export function H2(props) {
    let { style } = props;
    style ??= [];

    return (<Text
        {...props}
        style={[
            PDFstyles.text,
            PDFstyles.h2,
            props.noWrap ? PDFstyles.wrapText : {},
            ...style
        ]}
    >{props.children}</Text>)
}

export function H3(props) {
    let { style } = props;
    style ??= [];

    return (<Text
        {...props}
        style={[
            PDFstyles.text,
            PDFstyles.h3,
            props.noWrap ? PDFstyles.wrapText : {},
            ...style
        ]}
    >{props.children}</Text>)
}

export function Row(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.row,
            props.gap ? PDFstyles.gap : {},
            ...style
        ]}
    >{props.children}</View>)
}

export function Column(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.column,
            props.gap ? PDFstyles.gap : {},
            ...style
        ]}
    >{props.children}</View>)
}

export function Box(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            ...style
        ]}
    >{props.children}</View>)
}

export function CombineBoxRight(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.boxOverlapRight,
            ...style
        ]}
    >{props.children}</View>)
}

export function CombineBoxTop(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.boxOverlapTop,
            ...style
        ]}
    >{props.children}</View>)
}

export function Field(props) {
    let { label, value, style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.minHeightBoxTextSize,
            ...style
        ]}
    >
        <Text style={[PDFstyles.label]}>{label}</Text>
        <Text style={[PDFstyles.text, PDFstyles.wrapText]}>{value}</Text>
    </View>)
}

export function LongField(props) {
    let { label, value, style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.minHeightBoxTextSize,
            PDFstyles.stretchWidth,
            ...style
        ]}
    >
        <Text style={[PDFstyles.label]}>{label}</Text>
        <Text style={[PDFstyles.text, PDFstyles.wrapText]}>{value}</Text>
    </View>)
}

export function CommentField(props) {
    let { label, value, style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.minHeightBox,
            ...style
        ]}
    >
        <Text style={[PDFstyles.label]}>{label}</Text>
        <Text style={[PDFstyles.text, PDFstyles.wrapText]}>{value}</Text>
    </View>)
}

export function CombineBoxBoth(props) {
    let { style } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.box,
            PDFstyles.boxOverlapTop,
            PDFstyles.boxOverlapRight,
            ...style
        ]}
    >{props.children}</View>)
}

export function FlexApart(props) {
    let { style, direction="row" } = props;
    style ??= [];

    return (<View
        {...props}
        style={[
            PDFstyles.flexApart,
            direction === "row" ? PDFstyles.row : PDFstyles.column,
            ...style
        ]}
    >{props.children}</View>)
}

export function DefaultPageInfo({ id }) {
    
    return (
        <Text
            style={PDFstyles.pageNumber}
            render={({ pageNumber, totalPages }) => (
                `Dokument ID #${id} ${pageNumber} / ${totalPages}`
            )}
            fixed
        />
    )
}

export function DefaultPageFooter() {
    return (
        <View style={[PDFstyles.row, PDFstyles.pageInfo, PDFstyles.centerRow]} fixed>
            <Text> Laget med EffektivJobb</Text>
            <Image src={Logo} style={PDFstyles.infoLogo} />
        </View>
    )
}