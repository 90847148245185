import React, { useState, useEffect } from 'react';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import DefaultWrapper from '../../components/defaultWrapper';

import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';


const PermissionHandler = () => {
    const [hasPermission, setHasPermission] = useState(false);
    const [rememberChoice, setRememberChoice] = useState(false);

    useEffect(() => {
        const storedPermission = localStorage.getItem('hasPermission');
        return 
        if (storedPermission) {
            setHasPermission(JSON.parse(storedPermission));
        }
    }, []);

    const checkPermission = async () => {
        try {
            const result = await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION);
            setHasPermission(result.hasPermission);
            if (rememberChoice) {
                localStorage.setItem('hasPermission', JSON.stringify(result.hasPermission));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const requestPermission = async () => {
        try {
            /*const result = await AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION);
            */
            NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App
            });
            /*
          const result = Geolocation.requestPermissions();
          
          console.log(result);
          setHasPermission(result.hasPermission);
          if (rememberChoice) {
              localStorage.setItem('hasPermission', JSON.stringify(result.hasPermission));
          }*/
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <DefaultWrapper>
            <div>
                <input
                    type="checkbox"
                    checked={rememberChoice}
                    onChange={(e) => setRememberChoice(e.target.checked)}
                />
                Remember my choice
                <button onClick={checkPermission}>Check Permission</button>
                <button onClick={requestPermission}>Request Permission</button>
            </div>
        </DefaultWrapper>
    );
};

export default PermissionHandler;
