// useAuth.js
import { useState, useEffect } from 'react';
import { checkIfAuthenticated } from './firebase';

export default function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkUser() {
      const authenticated = await checkIfAuthenticated();
      setIsAuthenticated(authenticated);
      setLoading(false);
    }

    checkUser();
  }, []);

  return { isAuthenticated, loading };
}