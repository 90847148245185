import CircleIcon from "../miniComponents/circleIcon";
import CustomerModal from "./CustomerModal";
import { useEffect, useState } from "react";


export default function CustomerModalButton({ label, adaptToChoise, icon, title, items, onChange }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [info, setInfo] = useState({
        title: title || label,
        description: "",
        icon: icon,
    });

    useEffect(() => {
        console.log(modalVisible);
    }, [modalVisible])

    console.log(items);

    return (
        <div className="button-list" >
            <button type={"button"} className="button-list-button" onClick={() => {
                console.log(info);
                setModalVisible(true)
            }}>
                <div className="row relative center-column stretch-width">
                    <label className="button-list-button-label">{label}</label>

                    <CircleIcon icon={info.icon} />
                    <h2>{info.title}</h2>
                </div>

            </button>
            <CustomerModal visible={modalVisible} onChange={(e) => {
                console.log(e);
                if (onChange) onChange(e);
                if (adaptToChoise) setInfo(old => {
                    return {
                        ...old,
                        title: e.title || e.navn || e.name,
                        description: e.description || e.adresse || e.by,
                        icon: e?.icon,
                    }
                });
                console.log({
                    ...info,
                    title: e.title || e.navn || e.name,
                    description: e.description || e.adresse || e.by,
                    icon: e?.icon,
                });
                setModalVisible(false);
            }} onClose={() => setModalVisible(false)} />
        </div>
    )
}