import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { searchObjects } from '../search';
import CustomInput from '../miniComponents/customInput';
import { useSpring, animated } from 'react-spring';

import { ReactComponent as PlusIcon } from '../../assets/icons/material-symbols_add.svg';
import CustomNumberInput from '../miniComponents/customNumberInput';
import { AddRounded, ArrowDownwardRounded, CloseRounded, Delete, Remove } from '@mui/icons-material';
import LoadingImage from '../miniComponents/loadingImage';
import { useTranslation } from 'react-i18next';

export function SearchWithPopup(props) {
    const { t } = useTranslation();
    const items = props.items;
    const [search, setSearch] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [types, setTypes] = useState([]);
    const inputRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false)
    const expand = useSpring({ height: (searchResults.length >= 1 && (search !== '' || showDropdown)) ? `${(searchResults.length * 70) + 40}px` : '0px', maxHeight: '20em' });
    const containerRef = useRef(null);

    useEffect(() => {
        const results = searchObjects(items, search)
        console.log(results)
        setSearchResults(results)
        setTypes(
            [...new Set(results.map(item => item.type))].map(type => {
                function filterTypeName(item) {
                    if (item?.type === 'role') {
                        return t("Roles", "Roller")
                    }
                    if (item?.type === 'person') {
                        return t("People", "Personer")
                    }
                    return item?.type
                }
                return {
                    name: filterTypeName({ type: type }),
                    type: type
                }
            })
        )
    }, [search, items]);

    const onChoise = (item) => {
        props.onChoise(item)
        setSearch('')

        if (inputRef.current) inputRef.current.focus()
    }

    const [selectedIndex, setSelectedIndex] = useState(-1); // New state to track selected index

    useEffect(() => {
        // Reset selected index when search results change
        setSelectedIndex(-1);
    }, [searchResults]);

    const handleKeyDown = (e) => {
        console.log(e.key)
        // if the target is inside the container, then we prevent the default action
        if (containerRef.current && containerRef.current.contains(e.target) && e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
        }

        if (e.key === 'ArrowDown') {
            setSelectedIndex(prev => (prev + 1) % searchResults.length);
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex(prev => (prev - 1 + searchResults.length) % searchResults.length);
        } else if (e.key === 'Enter') {
            console.log(selectedIndex)
            if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
                console.log(searchResults[selectedIndex])
                onChoise((old) => searchResults[selectedIndex]);
            }
        } else if (e.key === 'Escape') {
            setShowDropdown(false);
        }

    }

    const handleMouseDown = (e) => {
        //e.preventDefault();
        // check if clicked outside of element, if so and it isn't a hold in (scroll) then close dropdown
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    }

    useEffect(() => {
        //on down arrow, select next item
        //on up arrow, select previous item
        //on enter, select item
        //on escape, close dropdown


        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('mousedown', handleMouseDown)
        window.addEventListener('touchstart', handleMouseDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('mousedown', handleMouseDown)
            window.removeEventListener('touchstart', handleMouseDown)
        }
    }, [searchResults, search])



    return (
        <div ref={containerRef} className='search column padding stretch-width' style={{ background: 'var(--background-break)', borderRadius: 20, position: "relative" }}>

            {props?.label && <label className='searchWithPopup-label'>{props?.label}</label>}
            {
                props?.selected &&
                <div className='row small-gap wrap' >
                    {props?.selected?.map((item, index) => {
                        return (
                            <button key={item.id} className={'row small-gap' + (item.orangeButton ? " orangeButton" : "")}
                                onClick={() => props.onRemove(item)}>{item?.navn || item?.name || item?.title || 'Ingen navn gitt'} <CloseRounded style={{ width: 20, height: 20 }} />
                            </button>
                        )
                    })}
                </div>
            }
            <div className={'row stretch-width posRelative center' + (props.quantity ? " wrap-on-phone" : null)} style={{ position: 'relative' }}>
                <CustomInput
                    label={props?.label || t("Search", "Søk")}
                    //forwardedRef={inputRef}
                    ref={inputRef}
                    noIcon={true}
                    value={search}
                    type='text'
                    placeholder={t("Search", "Søk")}
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                    onFocus={() => setShowDropdown(true)}
                /*onBlur={(e) => {
                    if (e.relatedTarget === null || !e.currentTarget.contains(e.relatedTarget)) {
                        setShowDropdown(false)
                    }
                }}*/
                />
                {
                    search !== '' &&
                    <button onClick={() => setSearch('')} style={{ height: '3em', width: '3em', top: '50%', transform: 'translateY(-50%)', right: '120px', fontSize: '12pt', background: 'none', position: 'absolute', color: 'white', boxShadow: 'none' }}>X</button>
                }
                <div className='row'>
                    {props.quantity &&
                        <CustomNumberInput bigInput={true} value={quantity} type="number" placeholder="Antall" onChange={e => setQuantity(e.target.value)} />
                    }
                    <button className='orangeButton' onClick={() => onChoise({ navn: search, id: randomId(), quantity: quantity ? quantity : null })} style={{ height: '3em', width: '3em', fontSize: '16pt', width: 'fit-content', }}><AddRounded style={{ color: "red" }} /></button>
                </div>
            </div>

            <animated.div className='search-results stretch-width column small-gap' style={{ ...expand, borderRadius: 5, overflowY: 'overlay' }}>


                {types.length > 1 ? types.map((type) => {

                    return (
                        <DropdownForGroups key={type.type} items={searchResults.filter(item => item.type === type.type)} onChoise={onChoise} type={type.name} />
                    )

                }) : <div className='column stretch-width padding center small-gap'>
                    {
                        searchResults.map((item, index) => {
                            return (
                                ((search !== '' && item.score !== 0) || showDropdown) &&
                                <Searchlist key={item.id} item={item} onChoise={onChoise} itemSelected={index === selectedIndex} quantity={quantity} />
                            )
                        })
                    }
                </div>
                }


            </animated.div>
        </div>
    );
}

function randomId() {
    return Math.random().toString(36).substr(2, 9);
}


const Searchlist = ({ items: initialItems, onOrderChange, onClickItem, itemSelected, item, onChoise, quantity }) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (itemSelected) {
            // focus
            ref.current.focus();
        }
    }, [itemSelected]);

    if (item.preloadBilde) {
        console.log(item.preloadBilde)
    }

    return (
        <button ref={ref} key={item?.id} className='search-card row stretch-width flexApart center' onClick={() => onChoise({ ...item, quantity: quantity ? quantity : null  })} style={{ height: '70px', minHeight: '70px', maxHeight: '70px', padding: 0 }}>
            <div className='row'>
                {(item?.preloadBilde || item?.bilde || item?.img || item?.image) &&
                    <LoadingImage medium={true} className='search-bilde' item={item} alt='Bilde' />
                }
                <div className='column stretch-width padding center' style={{ position: "relative" }}>
                    {item?.type && <h3 className='orange' style={{ textTransform: 'capitalize', position: "absolute", left: 20, top: 2 }}>{t(item?.type, item?.type)}</h3>}
                    <h2 className='bold' style={
                        {
                            color: item?.textColor || item?.colour || item?.color || 'white',
                        }
                    }>{item?.navn || item?.name || item?.title || t("No name given", 'Ingen navn gitt')}</h2>
                </div>
            </div>

            <div className='row center'>
                {item?.quantity && <h3>{item?.quantity}</h3>}
                {item?.searchInfo && <h3>{item?.searchInfo}</h3>}
                <PlusIcon style={{ width: 32, height: 32, margin: 20 }} />
            </div>
        </button>
    )
}


function DropdownForGroups({ items, onChoise, type }) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const expand = useSpring({ height: open ? `${items.length * 80}px` : '0px', });



    return (
        <div className='search-results stretch-width column small-gap'>

            <button className='search-card row stretch-width flexApart center padding ' onClick={() => setOpen(!open)} style={{ height: '70px', minHeight: '70px', maxHeight: '70px', padding: 0 }}>
                <h2 className='bold' style={
                    {
                        paddingLeft: 20,
                        color: 'white',
                        textTransform: 'capitalize'
                    }
                }>{type}</h2>
                <ArrowDownwardRounded style={{
                    width: 32, height: 32, margin: 20,
                    transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                    transition: 'transform 0.3s ease-in-out'
                }} />
            </button>
            <animated.div className='stretch-width column small-gap' style={{ ...expand, borderRadius: 5, marginLeft: 10 }}>
                {open && items.map((item, index) => {
                    return (
                        <Searchlist key={item.id} item={item} onChoise={onChoise} />
                    )
                })}
            </animated.div>

        </div>
    )
}