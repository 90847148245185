import { IonPage } from "@ionic/react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import React, { useContext } from "react";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import DefaultWrapper from "../../../components/defaultWrapper";
import { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import { PDFstyles } from "./styles";
import { PDFPreview } from "../../../components/renderPDF";

import { handleUploadPdf } from "../../../firebase";
import { CompanyContext } from "../../../contexts/companyContext";
import { UploadingContext } from "../../../contexts/loadingContext";
import { uploadHMSReport } from "../../../database/hms";
import { useSelector } from "react-redux";





export default function SJAPDF() {
    const history = useHistory();
    const data = history.location.state;
    const [imageDimensions, setImageDimensions] = useState({});
    const { companyDetails } = useSelector(state => state.company);
    const { startUploadBar, countUp, endUploadBar } = useContext(UploadingContext);

    const navigateToNewPage = ( path, state ) => {
        history.push(path, state);
    }

    const send = async () => {
        startUploadBar(3, "Genererer PDF");
        const instance = await pdf(<MyDocument data={data} imageDimensions={imageDimensions} />);

        const pdfBlob = await instance.toBlob();
        countUp("Laster opp PDF");

        const pdfUrl = await handleUploadPdf(pdfBlob, "HMS/SJA", data.id);


        countUp("Sender Emailer");
        const newReport = {
            ...data,
            pdfUrl,
            company: companyDetails
        }

        console.log(newReport);
        await uploadHMSReport(newReport)
        endUploadBar();
        //Remove local storage
        localStorage.removeItem("currentSJA");
        navigateToNewPage("/HMS", { tab: "SJA" });
    }

    useEffect(() => {
        // Fetch image dimensions for each signature
        const loadImages = async () => {
            const dimensions = {};
            console.log(data.deltagere)
            for (const participant of data.deltagere) {
                const dim = await fetchImageDimensions(participant.signatur);
                dimensions[participant.name] = dim;
            }
            setImageDimensions(dimensions);
        };

        loadImages();
    }, [data]);

    return (
        <DefaultWrapper>
            
            <button className="bottom-right-button" onClick={send}>Send</button>
            {imageDimensions && Object.keys(imageDimensions).length === data.deltagere.length &&
                <PDFPreview document={<MyDocument data={data} imageDimensions={imageDimensions} />} />
            }
        </DefaultWrapper>
    );
}

const fetchImageDimensions = (src) => {
    console.log("fetching for")
    return new Promise((resolve) => {
        const img = new window.Image();
        img.onload = () => {
            console.log("loaded" + src)
            resolve({ width: img.width, height: img.height });
        };
        img.src = src;
    });
};


const MyDocument = ({ data, imageDimensions }) => {
    console.log(data);

    const årsaker = [...data.årsaker]
    if (årsaker.length < 6) {
        // Add empty årsaker to fill the page
        const empty = Array(6 - årsaker.length).fill({ title: '' });
        årsaker.push(...empty);
    }

    return (
        <Document>
            <Page
                //landscape={true}
                size="A4"
                orientation="landscape"
                style={PDFstyles.page}>
                {/* Page number */}
                <Text
                    style={PDFstyles.pageNumber}
                    render={({ pageNumber, totalPages }) => (
                        `Dokument ID #${data.id} ${pageNumber} / ${totalPages}`
                    )}
                    fixed
                />
                <View style={[PDFstyles.row, PDFstyles.pageInfo, PDFstyles.centerRow]} fixed>
                    <Text> Laget med EffektivJobb</Text>
                    <Image src={Logo} style={PDFstyles.infoLogo} />
                </View>

                <View style={[PDFstyles.column, PDFstyles.gap]}>
                    <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h1]}>Sikker Jobb Analyse</Text>

                    <View style={[PDFstyles.row, { height: 80, width: "100%" }]}>
                        <View style={[PDFstyles.column, { width: "100%" }]}>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Aktivitet</Text>
                                <Text style={[PDFstyles.text]}>{data?.aktivitet}</Text>
                            </View>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Sja Ansvarlig (Person)</Text>
                                <Text style={[PDFstyles.text]}>{data?.ansvarlig?.navn || data?.ansvarlig?.name}</Text>
                            </View>
                        </View>

                        <View style={[PDFstyles.column, { width: "100%" }]}>
                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Dato</Text>
                                <Text style={[PDFstyles.text]}>{new Date(data?.dato).toLocaleDateString()}</Text>
                            </View>

                            <View style={[PDFstyles.column, PDFstyles.stretch2, PDFstyles.setHeight, PDFstyles.centerHeight]}>
                                <Text style={[PDFstyles.label,]}>Ansvar (Firma)</Text>
                                <Text style={[PDFstyles.text]}>{data?.firmaAnsvarlig?.navn || data?.firmaAnsvarlig?.name}</Text>
                            </View>
                        </View>
                        {data?.logo &&
                            <Image src={data?.logo} style={PDFstyles.logo} />
                        }
                    </View>

                    <View style={[PDFstyles.column, PDFstyles.gap]}>
                        <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h2]}>Årsaker til Sikker Jobb analyse</Text>
                        <View style={[PDFstyles.row, { height: 80, width: "100%", gap: -1 }]}>
                            <View style={[PDFstyles.column, { height: 80, width: "100%" }]}>

                                {årsaker.map((e, i) => {
                                    if (i > 2) return
                                    return (
                                        <View style={[PDFstyles.blackBox, { height: 27, width: "100%" }]}>
                                            <Text key={i} style={[PDFstyles.text]}>{e.title}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={[PDFstyles.column, { height: 80, width: "100%" }]}>
                                {årsaker.map((e, i) => {
                                    if (i < 3) return
                                    return (
                                        <View style={[PDFstyles.blackBox, { height: 27, width: "100%" }]}>
                                            <Text key={i} style={[PDFstyles.text]}>{e?.title}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                        <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h2]}>Risikovurdering</Text>
                        <View style={[PDFstyles.column]}>
                            <View style={[PDFstyles.row]} fixed>
                                <View style={[PDFstyles.stretch2, PDFstyles.uthevetBakgrunn]}>
                                    <Text style={[PDFstyles.text, PDFstyles.wrapText, PDFstyles.bold, PDFstyles.bigText,]}>{"Ansvarlig"}</Text>
                                </View>
                                <View style={[PDFstyles.stretch2, PDFstyles.uthevetBakgrunn]}>
                                    <Text style={[PDFstyles.text, PDFstyles.wrapText, PDFstyles.bold, PDFstyles.bigText]}>{"Farer"}</Text>
                                </View>
                                <View style={[PDFstyles.stretch2, PDFstyles.uthevetBakgrunn]}>
                                    <Text style={[PDFstyles.text, PDFstyles.wrapText, PDFstyles.bold, PDFstyles.bigText]}>{"Tiltak"}</Text>
                                </View>
                                <View style={[PDFstyles.stretch2, PDFstyles.uthevetBakgrunn]}>
                                    <Text style={[PDFstyles.text, PDFstyles.wrapText, PDFstyles.bold, PDFstyles.bigText]}>{"Hvor god kontroll har vi"}</Text>
                                </View>
                            </View>



                            {data.lines.map((e, i) => {
                                return (
                                    <View style={[PDFstyles.row]} wrap={false}>
                                        <View style={[PDFstyles.stretch2]}>
                                            <Text key={i} style={[PDFstyles.text, PDFstyles.wrapText]}>{
                                                e.ansvarlige.map((a, i) => {
                                                    return { ...a, name: (a.name || a.navn) + (i === e.ansvarlige.length - 1 ? '' : ', ') }
                                                }).map((a, i) => {
                                                    return a.name
                                                })
                                            }</Text>
                                        </View>
                                        <View style={[PDFstyles.stretch2]}>
                                            <Text key={i} style={[PDFstyles.text, PDFstyles.wrapText]}>{e.farer}</Text>
                                        </View>
                                        <View style={[PDFstyles.stretch2]}>
                                            <Text key={i} style={[PDFstyles.text, PDFstyles.wrapText]}>{e.tiltak}</Text>
                                        </View>
                                        <View style={[PDFstyles.stretch2]}>
                                            <Text key={i} style={[PDFstyles.text, PDFstyles.wrapText]}>{e.kontroll}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>


                </View>
            </Page>
            <Page size="A4" orientation="landscape" style={PDFstyles.page}>
                <Text
                    style={PDFstyles.pageNumber}
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )}
                    fixed
                />
                <View style={[PDFstyles.row, PDFstyles.pageInfo, PDFstyles.centerRow]} fixed>
                    <Text> Laget med EffektivJobb</Text>
                    <Image src={Logo} style={PDFstyles.infoLogo} />
                </View>



                <View style={[PDFstyles.column, PDFstyles.gap]}>
                    <View style={[PDFstyles.column, PDFstyles.gap]}>
                        <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h2]}>Kommentar & Sikkerhetsutstyr</Text>
                        <View style={[PDFstyles.row, PDFstyles.wrap]}>
                            <View style={[PDFstyles.stretch2, PDFstyles.wrap]}>
                                <Text style={[PDFstyles.label]}>Kommentar</Text>
                                <Text style={[PDFstyles.text]}>{data?.kommentar}</Text>
                            </View>
                            <View style={[PDFstyles.stretch2, PDFstyles.minHeightBox, PDFstyles.wrap]}>
                                <Text style={[PDFstyles.label]}>Sikkerhetsutstyr</Text>
                                <View style={[PDFstyles.row, PDFstyles.wrap]}>
                                    {data?.utstyr.map((e, i) => {
                                        return (
                                            <Text key={i} style={[PDFstyles.text]}>{e?.navn || e?.name}{i !== data?.utstyr.length - 1 ? ', ' : ''} </Text>
                                        )
                                    })}
                                </View>
                            </View>
                        </View>
                    </View>



                    <View style={[PDFstyles.column, PDFstyles.gap]}>
                        <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h2]}>Signaturer</Text>
                        <View style={[PDFstyles.row, PDFstyles.wrap]}>
                            {data.deltagere.map((e, i) => {
                                console.log(imageDimensions);
                                const dimensions = imageDimensions[e.navn];
                                const aspectRatio = dimensions ? dimensions.width / dimensions.height : 1;
                                const height = 40;
                                const width = height * aspectRatio;

                                return (

                                    <View key={i} style={[PDFstyles.stretch2, PDFstyles.flexCenter]}>
                                        <Text style={[PDFstyles.label]}>{e?.navn || e?.name}</Text>
                                        <Image src={e.signatur} style={{ height, width }} />
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};