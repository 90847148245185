import { IonHeader, IonPage, IonToolbar, useIonToast } from "@ionic/react";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createId, handleUploadPdf, moveReportToNeedsReview } from "../firebase";
import { pdf } from "@react-pdf/renderer";
import { Timestamp } from "firebase/firestore";

import { Document as PdfDocument, Page as PdfPage } from 'react-pdf/dist/esm/entry.webpack';

import Logo from "../assets/logo.png";
import DefaultWrapper from "../components/defaultWrapper";
import { ReportContextCreation, UserContext } from "../App";
import { checkRolesForPermission } from "./utils";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";





export default function exportTimeToPdf({ people, fromTime, endTime }) {

    /*async function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }*/

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    async function blobToArrayBuffer(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsArrayBuffer(blob);
        });
    }

    function sanitizeFilename(filename) {
        const invalidChars = /[/:\\*?|<>"]/g;
        return filename.replace(invalidChars, '_');
    }

    function getUniqueFilename() {
        const timestamp = new Date().toISOString().replace(/:/g, '_'); // Replace colons, which are invalid in filenames
        return `${timestamp}`;
    }

    // ...

    const baseFilename = people.length > 1
        ? "gruppe Timeføring"
        : `${sanitizeFilename(people[0].navn)} Timeføring ${new Date(fromTime).toLocaleDateString()} - ${new Date(endTime).toLocaleDateString()}`;



    console.log(fromTime, endTime);

    async function createPDF() {
        console.log("Create report");
        const instance = pdf(<MyDocument people={people} fromTime={fromTime} endTime={endTime} />);
        // Download pdf
        let link = document.createElement('a');
        const pdfBlob = await instance.toBlob();

        link.href = URL.createObjectURL(pdfBlob);
        console.log(people[0].dager[0])
        const fileName = (people.length > 1 ? "gruppe" : people[0].navn) + ` Timeføring ${new Date(people[0].dager[0].dates[0].startDate.seconds * 1000).toLocaleDateString() + "-" + new Date(people[0].dager[0].dates[people[0].dager[0].dates.length - 1].endDate.seconds * 1000).toLocaleDateString()}.pdf`;
        link.download = (people.length > 1 ? "gruppe" : people[0].navn) + ` Timeføring ${new Date(fromTime).toLocaleDateString() + "-" + new Date(endTime).toLocaleDateString()}.pdf`;
        //instance.download();
        //Filesystem.downloadFile(pdfBlob, "timeføring.pdf");
        /*Filesystem.writeFile({
            path: "timeføring.pdf",
            data: pdfBlob,
            directory: Directory.Documents,
            encoding: Encoding.UTF8
        });*/
        const pdfArrayBuffer = await blobToArrayBuffer(pdfBlob);
        const pdfBase64 = arrayBufferToBase64(pdfArrayBuffer);
        console.log("pdfArrayBuffer:", pdfArrayBuffer);

        if (Capacitor.isNativePlatform()) {
            console.log(fileName);
            const savedFile = await Filesystem.writeFile({
                path: sanitizeFilename(getUniqueFilename() + fileName),
                data: pdfBase64,
                directory: Directory.Documents,
                //encoding: Encoding.UTF8,
                recursive: true
            });

            console.log('PDF saved at:', savedFile.uri);
            console.info("Lagret fil til " + savedFile.uri);

            /*const toast = await toastController.create({
                message: 'PDF saved at: ' + savedFile.uri,
                duration: 2000
            });
            toast.present();*/
        } else {
            link.click();
            console.info("Nedlasting Startet");
            /*const toast = await toastController.create({
                message: 'PDF saved at: ' + link.href,
                duration: 2000
            });
            toast.present();*/
        }


        //pdfBlob.download();
        //setPresetPdf(pdfBlob);
        //setBlob(await blobToBase64(pdfBlob))
        //const pdfUrl = await handleUploadPdf(pdfBlob, "timeExport", createId());
    }
    createPDF();

    /*useEffect(() => {
        async function createPDF() {
            console.log("Create report");
            const instance = pdf(<MyDocument people={people} />);
            // Download pdf
            instance.download();
            /*const pdfBlob = await instance.toBlob();
            setPresetPdf(pdfBlob);
            setBlob(await blobToBase64(pdfBlob))
            const pdfUrl = await handleUploadPdf(pdfBlob, "timeExport", createId());
            setPdfUrl(pdfUrl);
        }
        createPDF();
    }, [pdfUpdate]);*/
}

const styles = StyleSheet.create({
    document: {
        padding: 20,
        fontSize: 8,
    },
    page: {
        width: 'auto', // This will allow the page to take the width of its content
        overflowWrap: 'break-word',
        padding: 20,
    },
    column: {
        flexDirection: 'column',
        display: 'flex',
        margin: 0,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        margin: 0,

    },
    flexApart: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },

    // Text
    text: {
        color: 'black',
        width: '100%',
        height: '100%',
        fontSize: 8,
    },
    textCenter: {
        textAlign: 'center',
        //marginBottom: -5
    },
    wrapText: {
        wordWrap: 'break-word',
    },
    textBold: {
        fontWeight: 'bold',
    },
    bigText: {
        fontSize: 16,
    },

    // styling
    blackBox: {
        border: '1px solid black',
        padding: 5,
    },
    centerInBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    centerInBoxText: {
        textAlign: 'center',
    },

    stretch2: {
        //flex: 1,
        //borderRight: '1px solid black',
        border: '1px solid black',
        padding: 10,

        justifyContent: 'center',
        //height: '50px',
        marginRight: '-1px',
        marginTop: '-1px',
        minWidth: '200px',
    },
    textLeft: {
        textAlign: 'left',
        fontSize: 8,
    },
    stretchWidth: {
        width: '100%',
    },

    logo: {
        height: "100px",
        //width: "120px",
        marginRight: 20
    },
    bigGap: {
        marginTop: 20,
    },
    contentMargin: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    fitContent: {
        minWidth: 200,
    },

    bigBox: {
        width: '100%',
        height: 120,
        maxHeight: 120,
        margin: 0,
        border: '1px solid black',
        padding: 5,
    },
    signering: {
        //width: ,
        height: 120,
        maxWidth: 400,
        borderBottom: '1px solid black',
    },
    fit: {
        flex: 1,
        //fit width to content
        minWidth: 200,
        //border: '1px solid black',
        padding: 10,
        //height: '50px',
        marginRight: '-1px',
        marginTop: '-1px',
    },

    tableBig: {
        flex: 2,
    },
    tableBarMedium: {
        flex: 1,
    },
    tableBarSmall: {
        flex: 0.5,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 10,
        right: 10,
        textAlign: 'center',
        color: 'black',
    },
});


const MyDocument = ({ people, fromTime, endTime }) => {
    const { t } = useTranslation();
    console.log(people);

    return (
        <Document>
            {people.map((person, index) => {
                const groupedByTilleg = groupByTillegType(person.dager, t);
                return (
                    <Page size="A4" style={styles.page}>
                        {/* Page Number */ }
                        <Text
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )}
                            fixed
                        />
                        {/* Username */ }
                        <View style={[styles.row, styles.flexApart, { padding: 20 }]}>
                            <Text style={[styles.text, styles.textBold, styles.bigText]}>{`Timeføring for ${person.navn} ${new Date(fromTime).toLocaleDateString() + " - " + new Date(endTime).toLocaleDateString()}`}</Text>
                        </View>

                        <View key={index} style={{ padding: 20 }}>
                            {/* Top Row */ }
                            <View style={[styles.row, { backgroundColor: "#fbb104", padding: 10, borderRadius: 2 }]}>
                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Date", "Dato")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Project", "Prosjekt")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>Nr.</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Activity", "Aktivitet")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Time", "Klokkeslett")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>{t("Hours", "Timer")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>{t("Overtime", "Overtid")}</Text>
                                <Text style={[styles.text, styles.textLeft, styles.tableBarSmall]}>{t("Pause", "Pause")}</Text>
                            </View>
                            {/* Rows of times */ }
                            <View key={index} style={[styles.column,]}>
                                {person.dager.reverse().map((dag, index2) => {
                                    return (
                                        <View key={index2 + "person"} style={[styles.column, { backgroundColor: index2 % 2 == 0 ? "#fff" : "#F1F1F1", borderRadius: 2, marginBottom: 2, marginTop: 5, }]}>

                                            {dag.dates.sort((a, b) => a.startDate - b.startDate).map((tid, index3) => {
                                                console.log(tid);
                                                return (
                                                    <View key={index3 + "tid"} style={[styles.row, { padding: 5, }]}>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{index3 === 0 ? new Date(tid.startDate.seconds * 1000).toLocaleDateString() : null}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{(tid?.prosjekt?.name ? tid?.prosjekt?.name : t("No Project", "Ingen Prosjekt"))}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>{(tid?.prosjekt?.nummer ? tid?.prosjekt?.nummer : "")}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{(tid?.activity?.name ? tid?.activity?.name : t("No Activity", "Ingen Aktivitet"))}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{roundToNearestHalfHour(new Date(tid.startDate.seconds * 1000)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " - " + roundToNearestHalfHour(new Date(tid.endDate.seconds * 1000)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>{calculateTimer(tid.startDate, tid.endDate, tid?.pause)} {t("h", "t")}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBarMedium]}>{tid?.tilleg?.navn}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBarSmall]}>{tid?.pause ? "0,5 t" : null}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    )
                                })}
                                {
                                    // Calculate total time for each person
                                }
                                <View style={[styles.column, { height: 3 * 40 }]}>
                                    <View style={[styles.text, styles.textLeft, styles.tableBarMedium, styles.column, { borderBottom: "1px solid black", marginTop: 10 }]}>
                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Total", "Total")}</Text>
                                    </View>
                                    <View style={[styles.row, { height: 2 * 40, marginTop: 10 }, styles.flexApart]}>
                                        <View style={[styles.row, styles.tableBig]}>
                                            <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{person.dager.length + " " + t("days", "dager")}</Text>
                                        </View>
                                        <View style={[styles.text, styles.textLeft, styles.tableBig, styles.column]}>
                                            {groupedByTilleg.map((tilleg, index) => {
                                                return (
                                                    <View key={index} style={[styles.text, styles.textLeft, styles.tableBig, styles.row]}>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{tilleg?.name}</Text>
                                                        <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{tilleg?.time} {t("h", "t")}</Text>
                                                    </View>
                                                )
                                            })}


                                            <View style={[styles.text, styles.textLeft, styles.tableBig, styles.row]}>
                                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{t("Pause", "Pause")}</Text>
                                                <Text style={[styles.text, styles.textLeft, styles.tableBig]}>{calculatePause(person.dager)} {t("h", "t")}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>


                    </Page>
                )
            })}
        </Document>
    )
}

export function calculateTimer(startDate, endDate, pause, overtime) {

    // Roud to nearest half-hour - 15 minutes = 30 minutes, 14 minutes = 0 minutes, 45 minutes = 30 minutes, 46 minutes = 60 minutes
    const start = roundToNearestHalfHour(new Date(startDate.seconds * 1000))
    const end = roundToNearestHalfHour(new Date(endDate.seconds * 1000));
    const diff = (end.getTime() - start.getTime()) / 1000;
    let hours = Math.floor(diff / 3600);
    let minutes = Math.floor((diff - hours * 3600) / 60);


    if (pause) {
        minutes = ifUnder0make0(minutes - 30);
    }
    if (hours < 0) {
        return hours = 0;
    }

    // Convert to hours: 01:30 -> 1.5
    return (hours + minutes / 60).toFixed(1);
    //return hours.toString().padStart(2, '0') + ":" + roundedMinutes.toString().padStart(2, '0');
}

function ifUnder0make0(number) {
    return Math.max(number, 0);
}

function roundToNearest(number, multiple) {
    console.log(number, multiple);
    const half = multiple / 2;
    const remainder = number % multiple;
    if (remainder < half) {
        return number - remainder; // Round down
    } else if (remainder >= half) {
        const result = number + multiple - remainder; // Round up
        return result === 60 ? 0 : result;
    }
}

export function roundToNearestHalfHour(date) {

    let start = date
    const startMinutes = date.getMinutes();
    if (startMinutes < 15) {
        start = new Date(date.setMinutes(0));
    } else if (startMinutes < 45) {
        start = new Date(date.setMinutes(30));
    } else {
        start = new Date(date.setMinutes(0));
        start = new Date(date.setHours(date.getHours() + 1));
    }
    return start;
}




function calculateTotalTimeWorked(dager) {
    const totalSeconds = dager.reduce((acc, curr) => {
        // If there's overtime, just add that
        if (curr.overtid > 0) {
            return acc + curr.overtid * 3600; // Assuming overtid is in hours
        }

        let totalForTheDay = 0;
        curr.dates.forEach(date => {
            const start = roundToNearestHalfHour(new Date(date.startDate.seconds * 1000))
            const end = roundToNearestHalfHour(new Date(date.endDate.seconds * 1000));

            if (end < start) {
                console.error("End time is before start time!", curr, date);
                return;  // Skip this date period if there's an error
            }

            totalForTheDay += end.getTime() - start.getTime();

            // Subtract break time if pause exists for this date
            /*if (date.pause) {
                totalForTheDay -= 30 * 60 * 1000; // Subtracting 30 minutes in milliseconds
            }*/
        });

        return acc + totalForTheDay / 1000; // Return total time in seconds
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);

    // Rounding to the nearest half-hour
    minutes = Math.round(minutes / 30) * 30;

    //return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
    return (hours + minutes / 60).toFixed(1);
}



function calculateTotalOvertime50(dager) {
    const time = dager.map(dag => {
        if (dag.overtid === 50) {
            const start = new Date(dag.dates[0].startDate.seconds * 1000);
            const end = new Date(dag.dates[dag.dates.length - 1].endDate.seconds * 1000);
            const diff = (end.getTime() - start.getTime()) / 1000;
            const hours = Math.floor(diff / 3600);
            const minutes = Math.floor((diff % 3600) / 60);

            return hours + roundToNearest(minutes, 30) / 60;
        }

        return 0;
    }).reduce((acc, curr) => acc + curr, 0);

    const roundedMinutes = roundToNearest((time % 1) * 60, 30);
    return Math.floor(time).toString().padStart(2, '0') + ":" + roundedMinutes.toString().padStart(2, '0');
}

function calculateTotalOvertime100(dager) {
    const time = dager.map(dag => {
        if (dag.overtid === 100) {
            const start = new Date(dag.dates[0].startDate.seconds * 1000);
            const end = new Date(dag.dates[dag.dates.length - 1].endDate.seconds * 1000);
            const diff = (end.getTime() - start.getTime()) / 1000;
            const hours = Math.floor(diff / 3600);
            const minutes = Math.floor((diff % 3600) / 60);

            return hours + roundToNearest(minutes, 30) / 60;
        }

        return 0;
    }).reduce((acc, curr) => acc + curr, 0);

    const roundedMinutes = roundToNearest((time % 1) * 60, 30);
    return Math.floor(time).toString().padStart(2, '0') + ":" + roundedMinutes.toString().padStart(2, '0');
}

export function calculatePause(dager) {
    let pauseInMinutes = 0;
    dager.forEach(dag => {
        console.log(dag);
        dag.dates.forEach(date => {
            if (date?.pause) {
                console.log(date?.pause);
                return pauseInMinutes += Number(date?.pause?.minutes || 30)
            }
        });
    })

    const hours = Math.floor(pauseInMinutes / 60);
    const minutes = pauseInMinutes % 60;
    // return in hours: 01:30 -> 1.5
    return (hours + minutes / 60).toFixed(1);

}



export function groupByTillegType(dager, t) {
    // return an array of objects:
    // [{ name: "Overtid 50%", time: 10.5 }
    // { name: "Overtid 100%", time: 5.5 }]

    let grouped = []
    dager.forEach(dag => {
        dag.dates.forEach(date => {
            if (date?.tilleg?.navn) {
                const index = grouped.findIndex(group => group.name === date?.tilleg?.navn);
                if (index === -1) {
                    grouped.push({ name: date?.tilleg?.navn, time: Number(calculateTimer(date.startDate, date.endDate, date?.pause)) });
                } else {
                    grouped[index].time += Number(calculateTimer(date.startDate, date.endDate, date?.pause));
                }
            }
            if (!date?.tilleg?.navn) {
                const index = grouped.findIndex(group => group.name === t("Normal time", "Normaltid"));
                if (index === -1) {
                    grouped.push({ name: t("Normal Time", "Normaltid"), time: Number(calculateTimer(date.startDate, date.endDate, date?.pause)) });
                } else {
                    grouped[index].time += Number(calculateTimer(date.startDate, date.endDate, date?.pause));
                }
            }
        });
    });

    return grouped;
}