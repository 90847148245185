import { useHistory } from "react-router";
import DefaultWrapper from "../../../../components/defaultWrapper";
import CustomInput from "../../../../components/miniComponents/customInput";
import { Close, Delete } from "@mui/icons-material";
import { SaveFirmSettings, createId } from "../../../../firebase";
import { useState, useEffect } from "react";

import { getRoles } from "../../../../firebase";
import { SearchWithPopup } from "../../../../components/searchWithPopups/searchWithPopup";
import { useTranslation } from "react-i18next";
import { getCompanyUsers } from "../../../../database/users";
import PeopleAndRolesSearchWithPopup from "../../../../components/searchWithPopups/peopleAndRolesSearch";
import DeleteButton from "../../../../components/miniComponents/deleteButton";
import FillOnEmptyInput from "../../../../components/miniComponents/fillOnEmptyInput";


export default function TimeforingAktivitet() {
    const { t } = useTranslation();
    const history = useHistory();
    const [aktivitet, setAktivitet] = useState({
        navn: "",
        underAktiviteter: [],
        id: createId(),
        users: [],
        people: [],
        roles: [],
        number: 0,
        ...history.location.state?.aktivitet,
    });
    console.log(aktivitet);
    console.log(history.location.state);
    //const oldSettings = history.location.state?.settings;
    const oldSettings = history.location.state?.settings;

    const [combinedFiltered, setCombinedFiltered] = useState([]); // [user1, user2, user3

    const [activityAmount, setActivityAmount] = useState(0);

    useEffect(() => {
        let activityAmount = 0;
        oldSettings?.aktiviteter.forEach((activity) => {
            activityAmount += activity.underAktiviteter.length;
        });
        setActivityAmount(activityAmount);
    }, []);


    return (
        <DefaultWrapper lagring={true} onBack={() => {
            const newSettings = { ...oldSettings };

            let changedOld = false;
            console.log(newSettings?.aktiviteter)
            newSettings?.aktiviteter?.forEach((oldAktivitet, index) => {
                if (oldAktivitet.id === aktivitet.id) {
                    newSettings.aktiviteter[index] = aktivitet;
                    changedOld = true;
                }
            });

            if (!changedOld) newSettings.aktiviteter.push(aktivitet);

            SaveFirmSettings(newSettings, "timeforingsInstillinger");
        }} >
            <div className="content-ny">
                <CustomInput label={t("Name", "Navn")} value={aktivitet.navn} onChange={(e) => {
                    setAktivitet({ ...aktivitet, navn: e.target.value });
                }}></CustomInput>
                <div className="column flexApart stretch-width">
                    <PeopleAndRolesSearchWithPopup label={"Personer som ser denne aktiviteten"} items={combinedFiltered} onChange={
                        (usersAndRoles) => {
                            console.log(usersAndRoles);
                            setAktivitet(prevAktivitet => ({
                                ...prevAktivitet,
                                ...usersAndRoles
                            }));
                        }
                    } idOnly={false}
                        addedRoles={aktivitet?.roles}
                        addedPeople={aktivitet?.people}
                    />
                </div>

                <div className="row flexApart stretch-width">
                    <h1>{t("Activity", "Aktivitet")}</h1>
                    <button className="bottom-right-button" onClick={() => {

                        let newUnderAktivitet = {
                            navn: "",
                            timesats: 0,
                            id: createId(),
                            number: activityAmount + 1,
                            autoFillNr: activityAmount + 1
                        };
                        console.log(newUnderAktivitet);
                        let aktivitetCopy = { ...aktivitet };
                        aktivitetCopy.underAktiviteter.push(newUnderAktivitet);
                        setAktivitet(old => aktivitetCopy);
                        setActivityAmount(old => old + 1);
                        //console.log({ ...oldSettings, aktiviteter: [...oldSettings.aktiviteter, aktivitetCopy] });
                    }
                    }>{t("Add under-activity", "Legg til underaktivitet")}</button>
                </div>
                <div className="column flexApart stretch-width button-list">
                    {
                        aktivitet.underAktiviteter.map((underAktivitet, index) => {

                            return (
                                <div key={underAktivitet.id} className="row flexApart stretch-width">
                                    <FillOnEmptyInput label="Navn" value={underAktivitet.navn}
                                        placeholderValue={t("Activity", "Aktivitet") + " " + underAktivitet?.number}
                                        onChange={(e) => {
                                            let aktivitetCopy = { ...aktivitet };
                                            aktivitetCopy.underAktiviteter[index].navn = e.target.value;
                                            setAktivitet(old => aktivitetCopy);
                                        }}></FillOnEmptyInput>

                                    <FillOnEmptyInput type="number" label="Nummer" value={underAktivitet.number}
                                        placeholderValue={Number(underAktivitet?.autoFillNr)}
                                        onChange={(e) => {
                                            let aktivitetCopy = { ...aktivitet };
                                            aktivitetCopy.underAktiviteter[index].number = e.target.value;
                                            setAktivitet(old => aktivitetCopy);
                                        }}></FillOnEmptyInput>

                                    <CustomInput type="number" label="Timesats" value={underAktivitet.timesats} onChange={(e) => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter[index].timesats = e.target.value;
                                        setAktivitet(old => aktivitetCopy);
                                    }}></CustomInput>


                                    <DeleteButton onClick={() => {
                                        let aktivitetCopy = { ...aktivitet };
                                        aktivitetCopy.underAktiviteter.splice(index, 1);
                                        setAktivitet(old => aktivitetCopy);
                                        setActivityAmount(old => old - 1);
                                    }} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </DefaultWrapper>
    )
}