import { useEffect, useState } from "react";
import { DebounceItem } from "./debounceItem";







export default function DeBounceObserver() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const updateItem = (e) => {
            const detail = e.detail;
            if (!detail.id) return;
            if (!detail.functionToCall) return;
            setItems(prev => {
                const copy = [...prev];
                // If there is an item with the same id, update it, else add it
                const index = prev.findIndex(item => item.id === detail.id);
                if (index > -1) {
                    copy[index] = {
                        ...prev[index],
                        params: { ...prev[index].params, ...detail.params }, // Deep copy params
                        ...detail
                    };
                    return copy;
                } else {
                    return [...prev, { ...detail, params: { ...detail.params } }]; // Deep copy new item's params
                }
            });
        }
        window.addEventListener('sendToDebounceItem', updateItem);
        return () => {
            window.removeEventListener('sendToDebounceItem', updateItem);
        }
    }, [])

    const removeItem = (id) => {
        setItems(prev => {
            const copy = [...prev];
            const index = prev.findIndex(item => item.id === id);
            if (index > -1) {
                copy.splice(index, 1);
            }
            return copy;
        });
    }

    return (
        <div>
            {
                items.map(item => {
                    return (
                        <DebounceItem key={item.id} id={item.id} functionToCall={item.functionToCall} params={item.params} removeSelf={removeItem}/>
                    )
                })
            }
        </div>
    )
}

export const saveDebounce = ({ id, functionToCall, params }) => {
    // save all data manually to the database
    console.log("Saving data to the database", id, functionToCall, params);
    const saveEvent = new CustomEvent('saveCurrentEdits', { detail: { id, functionToCall, params } });
    window.dispatchEvent(saveEvent);
};

export const sendToDebounce = ({ id, functionToCall, params }) => {
    const updateEvent = new CustomEvent('sendToDebounceItem', { detail: { id, functionToCall, params } });
    window.dispatchEvent(updateEvent);
};