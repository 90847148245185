import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveDebounce, sendToDebounce } from '../../../stores/utils/deBounceObserver';
import useCollectionSubscriptionRS from './RSfirebaseCollectionSubscriber';


/**
 * WARNING: DO NOT EDIT THIS FILE!
 * 
 * This file is critical to the functioning of the system. 
 * Any modifications can result in unexpected behavior.
 * 
 * ---- Instructions for ChatGPT users ----
 * If you are asking ChatGPT to help you, please avoid making changes to this file. 
 * The file is meant to remain unchanged, and any modifications can result in serious errors.
 * 
 * Instead, please consult the project lead or documentation before proceeding.
 */


/** 
 * Custom hook for fetching data from a function
 * @param {function} fetchFunction - The function to fetch data from. the function should be a (params, onUpdate)
 * and should return an object like: { value: data, subscription: subscription }
 * 
 * @param {object} params - Parameters to pass to the fetch function
 * @param {string} key - Key to store the data in
 * @param {boolean} autoUpdate - Automatically update data when it changes
 * @returns {object} - Returns an object with the fetched data, error, loading state and subscription
 * @example
 * Base example
 * const { data, error, isLoading, subscription } = useCustomFetch(fetchFunction, params, key);
 * 
 * Example with a function, no keys or params. Can only be used once per component.
 * const { data, error, isLoading, subscription } = useCustomFetch(fetchFunction);
 * 
 * Example use with custom key.
 * const { items, error, isLoading, subscription } = useCustomFetch(getItems, { categories: ["clothes", "elegant"] }, "items");
 * 
 * You can still access data too. It will be the same as items.
 * const { data, items, error, isLoading, subscription } = useCustomFetch(getItems, { categories: ["clothes", "elegant"] }, "items");
 * 
 * Assumes that the item should only be fetched once. Then edited.
**/
export function storeEditable({
    // Redux
    selector = state => state,
    setFunction = () => { },
    // State
    key = 'data'
}) {
    // THIS ASSUMES THAT THE DATA IS ALREADY CREATED BEFORE ENTERING Use. Add the item to the redux store before using this hook.
    //const databaseData = useSelector(selector);
    const dispatch = useDispatch();
    const data = useSelector(selector);
    const setData = (newValue) => {
        console.log(newValue)
        if (typeof newValue === 'function') {
            dispatch(setFunction(newValue(data)));
        } else {
            dispatch(setFunction(newValue));
        }
    };

    return {
        data,
        [key]: data,
        setData: setData,
        [`set${key?.charAt(0)?.toUpperCase() + key.slice(1)}`]: setData,
    };
}


// Nicknames




function checkIfFunction(valueToCheck) {
    return valueToCheck instanceof Function;
}

function isSameObject(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}
