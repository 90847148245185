import { IonHeader, IonPage, IonSpinner, IonToolbar, useIonToast } from "@ionic/react";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getCompanyDetails, handleUploadPdf, moveReportToNeedsReview, sendReportAndEmail } from "../../../firebase";
import { pdf } from "@react-pdf/renderer";
import { Timestamp } from "firebase/firestore";

import { Document as PdfDocument, Page as PdfPage } from 'react-pdf/dist/esm/entry.webpack';

//import Logo from "../assets/logo.png";
import DefaultWrapper from "../../../components/defaultWrapper";
import { ReportContextCreation, UserContext } from "../../../App";
import { checkRolesForPermission } from "../../../components/utils";
import { createDateOutOfFirebaseTimestamp, createNorwegianDate } from "../../../pages/timeføring/timeføring";
import { useTranslation } from "react-i18next";
import debounce from 'lodash/debounce';


const TemplatePreview = React.memo(({ report }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true); // State to track the first load

    // Generate PDF and update URL
    const generatePDF = async (reportContext) => {
        if (!reportContext) return;

        const instance = await pdf(<MyDocument data={reportContext} />).toBlob();
        const url = URL.createObjectURL(instance);
        setPdfUrl(url);
        if (isFirstLoad) setIsFirstLoad(false); // Update first load state after successful generation
    };

    // Debounce the PDF generation function to limit its execution frequency
    const debouncedGeneratePDF = useRef(debounce(generatePDF, 3000)).current;

    useEffect(() => {
        // Call the debounced PDF generation function
        debouncedGeneratePDF(report);

        // Cleanup function to cancel the debounce on component unmount
        return () => debouncedGeneratePDF.cancel();
    }, [report, debouncedGeneratePDF]);

    // Conditional rendering based on the first load
    return (
            <object className="render-pdf" key={"pdfIframe"} data={pdfUrl} type="application/pdf" />
    );
});

export default TemplatePreview;

const styles = StyleSheet.create({
    document: {
        padding: 20,
        fontSize: 12,
    },
    page: {
        width: 'auto', // This will allow the page to take the width of its content
        overflowWrap: 'break-word',
    },
    column: {
        flexDirection: 'column',
        display: 'flex',
        margin: 0,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        margin: 0,

    },
    flexApart: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },

    // Text
    text: {
        flexWrap: 'wrap',
        color: 'black',
        fontSize: 12,
        wordWrap: 'break-word'
    },
    textCenter: {
        textAlign: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        //marginBottom: -5
    },
    wrapText: {
        wordWrap: 'break-word',
        flexWrap: 'wrap'
    },
    textBold: {
        fontWeight: 'bold',
    },
    bold: {
        fontWeight: 900,
    },
    bigText: {
        fontSize: 16,
    },

    // styling
    blackBox: {
        border: '1px solid black',
        padding: 5,
        minHeight: 30,
    },
    centerInBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
    },
    centerInBoxText: {
        textAlign: 'center',
        textAlignVertical: 'center',
    },

    stretch2: {
        //flex: 1,
        //borderRight: '1px solid black',
        border: '1px solid black',
        padding: 10,

        justifyContent: 'center',
        //height: '50px',
        marginRight: '-1px',
        marginTop: '-1px',
        minWidth: '200px',
    },
    textLeft: {
        textAlign: 'left',
        fontSize: 12,
    },
    stretchWidth: {
        width: '100%',
    },

    logo: {
        height: "100px",
        //width: "120px",
        marginRight: 20
    },
    bigGap: {
        marginTop: 20,
    },
    contentMargin: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    fitContent: {
        minWidth: 200,
    },

    bigBox: {
        width: '100%',
        height: 120,
        maxHeight: 120,
        margin: 0,
        border: '1px solid black',
        padding: 5,
    },
    signering: {
        //width: ,
        height: 120,
        maxWidth: 400,
        borderBottom: '1px solid black',
    },
    textPadding: {
        marginBottom: 5,
    },
    footer: {
        paddingTop: 20,
    },
    topLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    alignBottom: {
        display: 'flex',
        //justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },

});

const MyDocument = ({ data }) => {
    const { t } = useTranslation();
    const mal = data.mal
    // filter oppgaver per date
    const oppgaver = data.oppgaver
    const firma = data.firma
    const projekt = data.projekt
    const detaljer = data.sendingFirmDetails
    const Logo = data?.sendingFirmDetails?.logo || "https://firebasestorage.googleapis.com/v0/b/fir-gruppen-app.appspot.com/o/Våre%20filer%2Flogo.png?alt=media&token=caeec543-0712-4285-a4ab-aadb6449df75"
    //console.log(data)

    console.log(mal)
    console.log(oppgaver)
    console.log(firma)
    console.log(projekt)
    console.log(detaljer)

    if (!mal) return null

    const whatSizeOppgaveEmne = (oppgaveEmne, squareSize) => {
        let size = 0
        //console.log(oppgaveEmne)
        oppgaveEmne.underAlignments.forEach((underAlignment) => {
            if (underAlignment.type.value === "checkbox") {
                size += 1
            }
            if (underAlignment.type.value === "text") {
                size += 3
            }
            if (underAlignment.type.value === "number") {
                size += 1
            }
            if (underAlignment.type.value === "date") {
                size += 2
            }
            if (underAlignment.type.value === "textarea") {
                size += 5
            }
        })
        //console.log(styles.flexApart)
        //console.log("squareSize: " + squareSize)
        //console.log(size)
        return { width: size * squareSize, height: 30 }
    }

    const whatSizeUnderAlignment = (underAlignment, squareSize) => {
        //console.log(underAlignment)
        let size = 0
        if (underAlignment.type.value === "checkbox") {
            size += 1
        }
        if (underAlignment.type.value === "text") {
            size += 3
        }
        if (underAlignment.type.value === "number") {
            size += 1
        }
        if (underAlignment.type.value === "date") {
            size += 2
        }
        if (underAlignment.type.value === "textarea") {
            size += 5
        }
        //console.log(styles.flexApart)
        //console.log(size)
        //console.log("squareSize: " + squareSize)
        return { width: size * squareSize }
    }

    const howManySquaresNeeded = (underAlignment) => {
        let size = 0
        if (underAlignment.type.value === "checkbox") {
            size += 1
        }
        if (underAlignment.type.value === "text") {
            size += 3
        }
        if (underAlignment.type.value === "number") {
            size += 1
        }
        if (underAlignment.type.value === "date") {
            size += 2
        }
        if (underAlignment.type.value === "textarea") {
            size += 5
        }

        return size
    }

    /*useEffect(() => {
        mal.felles.forEach((oppgaveEmne) => {
            whatSizeTop(oppgaveEmne)
        })
    }, [])*/

    function combineArrays(arr1, arr2) {
        let arr2IndexById = {};

        // Creating a dictionary with id's as keys for easier look-up
        arr2.forEach(item => arr2IndexById[item.id] = item);

        // Now we map the first array, replacing any object with an id found in arr2IndexById
        const result = arr1.map(item => arr2IndexById[item.id] ? arr2IndexById[item.id] : item);

        // Check for any items in the second array not included in the first array
        arr2.forEach(item => {
            if (!result.some(obj => obj.id === item.id)) {
                result.push(item);
            }
        });

        return result;
    }

    const calculateWidth = () => {
        let combinedOppgave = []
        /*mal?.felles?.forEach((oppgaveEmne) => {
            oppgaveEmne.underAlignments.forEach((underAlignment) => {
                combinedOppgave.push(underAlignment)
            })
            //combinedOppgave.push(oppgaveEmne)
        })*/
        console.log(mal)
        mal?.oppgaver?.forEach((oppgaveEmne) => {
            console.log(oppgaveEmne)
            oppgaveEmne.underAlignments.forEach((underAlignment) => {
                combinedOppgave.push(underAlignment)
            })
            //combinedOppgave.push(oppgaveEmne)
        })

        const minWidth = 960

        let squaresNeeded = 0
        combinedOppgave.forEach((underAlignment) => {
            squaresNeeded += howManySquaresNeeded(underAlignment)
        })
        console.log(squaresNeeded)

        let squareSize = minWidth / squaresNeeded
        //console.log(squareSize)
        if (squareSize < 50) {
            squareSize = 50
        }

        /*console.log(squareSize)
 
        let widths = []
        combinedOppgave.forEach((underAlignment) => {
            widths.push(whatSizeUnderAlignment(underAlignment).width, squareSize)
        })
        console.log(combinedOppgave)*/

        /*let width = 100
        widths.forEach((widths) => {
            console.log(widths)
            width += widths
        })*/
        console.log(squaresNeeded, squareSize)
        let width = squaresNeeded * squareSize + 40

        console.log(width)

        if (width < minWidth) {
            width = minWidth
        }

        return { width, squareSize: squareSize }
    }



    //const height = 650 + (oppgaver.length * 40);

    const Content = () => {

        if (mal === undefined) return null
        let { width, squareSize } = calculateWidth()
        console.log(width)

        let fullOppgave = []
        oppgaver.map((oppgave, index) => {
            /*const malOppgaverToSplitAt = mal.oppgaver.find((malOppgave) => malOppgave.id === oppgave.type).underAlignments.length
 
            // For each underAlignment length, split the oppgaveEmne
            let oppgaveEmneArray = []
 
            const oppgaveSplit = oppgave.oppgaveEmner.map((oppgaveEmne, index) => {
                const oppgaveEmneSplit = oppgaveEmne.underAlignments.map((underAlignment, index) => {
                    if (index/malOppgaverToSplitAt === 0) {
                        oppgaveEmneArray.push([])
                    }
                })
            })
 
 
            let oppgaveEmner = oppgave.oppgaveEmner*/
            //console.log(oppgave)
            let underAlignmentArray = []
            let underAlignmentArrayIndex = 0

            oppgave?.oppgaveEmner.forEach((oppgaveEmne, index) => {
                //console.log(oppgaveEmne)
                if (underAlignmentArrayIndex < oppgaveEmne.underAlignments.length) {
                    for (let i = underAlignmentArrayIndex; i < oppgaveEmne.underAlignments.length; i++) {
                        underAlignmentArray.push([])
                    }
                    underAlignmentArrayIndex = oppgaveEmne.underAlignments.length
                }
            })


            oppgave?.oppgaveEmner.forEach((oppgaveEmne, index) => {
                oppgaveEmne.underAlignments.forEach((underAlignment, index) => {
                    underAlignmentArray[index].push(underAlignment)
                })
            })

            // [ [underAlignment1, underAlignment2], [underAlignment1, underAlignment2]


            const combineAllTasks = (underAlignmentArray) => {
                let combinedUnderAlignment = []
                underAlignmentArray.forEach((alignment) => {
                    //console.log(alignment)
                    //console.log(combinedUnderAlignment)
                    combinedUnderAlignment = combineArrays(combinedUnderAlignment, alignment.tasks)
                })
                return combinedUnderAlignment
            }

            const taskArrays = underAlignmentArray.map((underAlignment) => {
                return combineAllTasks(underAlignment)
            })

            //console.log(taskArrays)

            let fullMal = []
            /*mal.felles.forEach((oppgaveEmne) => {
                //fullMal.push(oppgaveEmne)
                oppgaveEmne.underAlignments.forEach((underAlignment) => {
                    fullMal.push(underAlignment)
                })
            })*/
            console.log(mal.oppgaver)
            mal.oppgaver.forEach((oppgaveEmne) => {
                oppgaveEmne.underAlignments.forEach((underAlignment) => {
                    fullMal.push(underAlignment)
                })
            })

            // Combine with missing tasks from fullMal

            taskArrays.forEach((taskArray, index) => {
                let oppgaveToPush = []
                //combine task array with fullMal
                // Removed OppgaveToPush =combineArrays(fullMal, taskArray) because of rendering issues
                oppgaveToPush = combineArrays(fullMal, taskArray)

                fullOppgave.push(oppgaveToPush)
            })
        })


        //const width =whatSizeUnderAlignment(combinedOppgave).width + 100 

        const height = 650 + (oppgaver.length * 40);
        console.log(detaljer)
        console.log(data)
        console.log(oppgaver)
        console.log(fullOppgave)

        return (
            <Page size={[height, width]} orientation="landscape" style={[styles.page, styles.stretchWidth]}>
                <View style={[styles.column, styles.contentMargin]}>
                    {/* Fill in with top header */}
                    <View style={[styles.row, styles.stretchWidth, styles.flexApart, { padding: "10px 0px" }]}>
                        <Text style={[styles.textLeft, styles.bold, styles.bigText]}># {data?.id}</Text>
                    </View>
                    <View style={[styles.row, styles.stretchWidth, styles.flexApart]}>
                        <View style={[styles.row]}>
                            <View style={styles.column}>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Name", "Navn")}: {firma?.navn}</Text>
                                </View>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Address", "Addresse")}: {firma?.addresse}</Text>
                                </View>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Contact Person", "Kontaktperson")}: {data?.kontaktperson?.navn}</Text>
                                </View>
                            </View>
                            <View style={styles.column}>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Place", "Sted")}: {projekt?.addresse}, {projekt?.postnummer}</Text>
                                </View>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Project", "Prosjekt")}: {projekt?.navn}</Text>
                                </View>
                                <View style={styles.stretch2}>
                                    <Text style={styles.textLeft}>{t("Our Representant", "Vår Representant")}:  {data?.bruker}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.row}>
                            {Logo && <Image src={Logo} style={[styles.logo, styles.column, { paddingRight: 10 }]} />}
                            <View style={[styles.column]}>
                                <Text style={[styles.textLeft, styles.bold]}>{detaljer?.name}</Text>
                                <Text style={styles.textLeft}>{detaljer?.address}</Text>
                                <Text style={styles.textLeft}>{detaljer?.info?.a}</Text>
                                <Text style={styles.textLeft}>{detaljer?.info?.b}</Text>
                                <Text style={styles.textLeft}>{detaljer?.info?.c}</Text>
                                <Text style={styles.textLeft}>{detaljer?.info?.d}</Text>
                            </View>
                        </View>
                    </View>

                    {/* Filling in the document */}
                    <View style={[styles.column, styles.bigGap]}>
                        <View style={[styles.row]}>
                            {mal.oppgaver.map((oppgave, index) => (
                                <View key={index}>
                                    <View key={index} style={[styles.row, whatSizeOppgaveEmne(oppgave, squareSize), styles.blackBox, styles.textCenter]}>
                                        {console.log(oppgave?.title)}
                                        <Text style={[styles.textBold, styles.bigText, styles.text]}>{oppgave.title}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                        <View style={[styles.row]}>
                            {mal.oppgaver.map((oppgave, index) => (
                                <View style={[styles.row]} key={index}>
                                    {oppgave.underAlignments.map((underAlignment, index) => (
                                        <View>
                                            <View key={index} style={[styles.row, whatSizeUnderAlignment(underAlignment, squareSize), styles.blackBox, styles.textCenter]}>
                                                {console.log(underAlignment.title)}
                                                <Text style={[styles.textBold, styles.bigText, styles.text]}>{underAlignment.name}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>

                        <View style={[styles.row]}>
                            <View style={[styles.column]} >{
                                fullOppgave.map((tasks, index) => {
                                    return (<View style={[styles.row]} key={index}>
                                        {tasks.map((task, index) => { // To edit Kommentar

                                            return (
                                                <View style={[styles.wrapText, styles.flexCenter, styles.row, styles.centerInBox, styles.blackBox, styles.textCenter, whatSizeUnderAlignment(task, squareSize)]} key={index}>
                                                    <Text style={[styles.wrapText, styles.flexCenter, styles.textBold, styles.text, styles.textCenter, styles.centerInBoxText]}>{checkIfValidValue(task?.value)}</Text>
                                                </View>
                                            )
                                        })}
                                    </View>
                                    )
                                })
                            }
                            </View>
                        </View>
                    </View>


                    {/* Other section */}
                    <View style={[styles.row, styles.stretchWidth, { height: 100, marginTop: 20 }]}>

                        <View style={[styles.column, styles.stretchWidth, { marginRight: 10 }]}>
                            <Text style={[styles.textLeft, styles.textPadding]}>{t("Miscellaneous", "Annet")} </Text>
                            <View style={[styles.bigBox, styles.wrap]}>
                                <Text style={[styles.textLeft]}>{data?.annet}</Text>
                            </View>
                        </View>

                        <View style={[styles.column, styles.stretchWidth]}>
                            <Text style={[styles.textLeft, styles.textPadding]}>{t("Equipment", "Utstyr")} </Text>
                            <View style={[styles.bigBox, styles.row, styles.wrap]}>
                                <Text style={[styles.textLeft]}>{createUtstyrText(data)}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <View style={styles.row}>
                            <Text style={styles.textLeft}>{t("Date", "Dato")}: </Text>
                            <Text style={[styles.textLeft, styles.textUnderline]}>{new Date().toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</Text>
                        </View>

                        <View style={[styles.row, styles.alignBottom]}>
                            <Text style={styles.textLeft}>{t("Signature", "Signatur")}:</Text>
                            {(data?.signatur || Logo) && <Image src={data?.signatur || Logo} style={styles.signering} />}
                        </View>
                    </View>
                </View>
            </Page>
        )
    }

    return (
        <Document style={styles.page}>
            <Content />
        </Document>
    );
}


function checkIfValidValue(value) {
    //console.log(value)
    //console.log(typeof value)
    if (value === undefined || value === null || value === "") {
        return value = " ";
    }
    if (typeof value === "object") {
        try {
            return createNorwegianDate(createDateOutOfFirebaseTimestamp(value));
        }
        catch (e) {
            return value = " ";
        }
    }
    if (typeof value === "string") {
        console.log(value)
        return value;
    }
    if (typeof value === "date") {
        return createNorwegianDate(createDateOutOfFirebaseTimestamp(value));
    }
    if (typeof value === "number") {
        return value.toString();
    }
    return value;
}

function createUtstyrText(data) {
    let utstyrText = "";
    data?.utstyr?.forEach((utstyr, index) => {
        if (index === data.utstyr.length - 1) {
            utstyrText += utstyr.navn + " x" + utstyr.quantity;
            return;
        }
        utstyrText += utstyr.navn + " x" + utstyr.quantity + ", ";
    });
    return utstyrText;
}

// OLD DOCUMENT WITHOUT AUTOMASATION
/* 
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    top: {
        flexDirection: 'row',
        padding: 10,
        //flex: 1
    },
    logo: {
        height: 120,
        marginRight: 20
    },
    section: {
        margin: 10,
        paddingTop: 50, // Add this line
        padding: 10,
        flexGrow: 1,
        gap: 10,
    },
    firmaInfo: {
        marginBottom: 20,
    },
    firmaTitle: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '8.33%',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        backgroundColor: '#f0f0f0',
        padding: 5,
    },
    tableCol: {
        border: '1px solid black',
    },
    tableCol2: {
        borderTop: '2px solid black',
        borderBottom: '2px solid black',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    tableCell: {
        fontSize: 12,
    },

    box1: {
        width: 40,
        minHeight: 40,
        borderLeft: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box2: {
        width: 80,
        minHeight: 40,
        borderLeft: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box3: {
        width: 120,
        minHeight: 40,
        borderLeft: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box4: {
        width: 160,
        minHeight: 40,
        borderLeft: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stretch: {
        flex: 1,
        borderLeft: '1px solid black',
        padding: 5,
        //alignItems: 'center',
        //justifyContent: 'center',
        //flexDirection: 'row',
        //flexWrap: 'wrap',
        //flex: 1
    },
    stretch2: {
        //flex: 1,
        //borderRight: '1px solid black',
        border: '1px solid black',
        padding: 5,

        justifyContent: 'center',
        height: '50px',
        marginRight: '-1px',
        marginTop: '-1px',
        minWidth: '200px',
    },
    stretch3: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    reportInfoRow: {
        flexDirection: 'row',
        marginTop: -1,
    },
    text: {
        fontSize: 12,
        textAlign: 'center',
    },
    textUnderline: {
        textDecorationLine: 'underline',
        borderBottom: '1px solid black',
        paddingRight: 10,
        paddingLeft: 10,
    },
    column: {
        flexDirection: 'column',
        //height: '100%',
    },
    textLeft: {
        fontSize: 12,
        textAlign: 'left',
    },
    bold: {
        fontWeight: "bold",
    },
    flexApart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row',
    },
    keepToBottom: {
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
    },
    footer: {
        //height: 100,
        flexDirection: 'row',
        justifyContent: 'space-between',
        //center content vertically
        alignItems: 'flex-end',
        padding: 20,
        paddingBottom: 30,
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    signering: {
        //width: ,
        height: 120,
        maxWidth: 400,
        borderBottom: '1px solid black',
    },
    alignBottom: {
        alignItems: "baseline"
    },
    padding: {
        padding: 20,
    },
    bigBox: {
        width: '100%',
        height: 120,
        border: '1px solid black',
        padding: 5,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    gap: {
        gap: 10,
    },
    otherSection: {
        position: 'absolute',
        bottom: 140,
        padding: 20,
        width: '100%',
    }
});


const MyDocument = (props) => {
    const { data } = props;
    const { firma, oppgaver } = data;
    console.log(data);

    const height = 650 + (oppgaver.length * 40);

    function parseAndCalculate(value) {
        if (value === undefined) {
            return 0;
        }

        if (typeof value === "string" && value.includes("x")) {
            const [num1, num2] = value.split("x").map(Number);
            return num1 * num2;
        }
        return Number(value);
    }

    function handleNaN(value) {
        return value === 0 ? "" : value;
    }

    const sum = {
        timer: 0,
        rigg: 0,
        omrigg: 0,
        lm: 0,
        tbs: 0,
        stk: 0,
        dim: 0,
        tb: 0,
        vanlig: 0,
        femti: 0,
        hundre: 0,
    };

    for (let i = 0; i < oppgaver.length; i++) {
        console.log(oppgaver[i]);
        sum.rigg += parseAndCalculate(oppgaver[i]?.rigg);
        sum.omrigg += parseAndCalculate(oppgaver[i]?.omrigg);
        sum.lm += parseAndCalculate(oppgaver[i]?.lm);
        sum.tbs += parseAndCalculate(oppgaver[i]?.tbs);
        sum.stk += parseAndCalculate(oppgaver[i]?.stk);
        sum.dim += parseAndCalculate(oppgaver[i]?.dim);
        sum.tb += parseAndCalculate(oppgaver[i]?.tb);
        sum.vanlig += parseAndCalculate(oppgaver[i]?.timer?.vanlig);
        sum.femti += parseAndCalculate(oppgaver[i]?.timer?.femti);
        sum.hundre += parseAndCalculate(oppgaver[i]?.timer?.hundre);
    }

    Object.keys(sum).forEach(key => {
        sum[key] = handleNaN(sum[key]);
    });

    console.log(sum);



    return (
        <Document>
            <Page size={[height, 960]} orientation="landscape" style={styles.page}>
                <View style={[styles.flexApart, styles.column]}>
                    <View style={styles.section}>
                        <View style={styles.flexApart}>
                            <Text>Arbeidsrapport</Text>
                            <View style={[styles.row, styles.keepToBottom]}>
                                <Text style={[styles.text, styles.keepToBottom]}>{"projekt: " + data?.projekt.nummer ? data?.projekt?.navn + " #" + data?.projekt?.nummer + "    " : data?.projekt?.navn + "    "}</Text>
                                <Text style={[styles.text, styles.keepToBottom]}>Nr:</Text>
                                <Text>{data.id}</Text>
                            </View>
                        </View>
                        <View style={styles.flexApart}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Navn: {firma.navn}</Text>
                                    </View>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Addresse: {firma.addresse}</Text>
                                    </View>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Kontaktperson: {data.kontaktperson.navn}</Text>
                                    </View>
                                </View>
                                <View style={styles.column}>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Sted: {firma.by}, {firma.postnummer}</Text>
                                    </View>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Arbeidsplass: {firma.arbeidsplass}</Text>
                                    </View>
                                    <View style={styles.stretch2}>
                                        <Text style={styles.textLeft}>Vår Representant:  {data.bruker}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <Image src={Logo} style={styles.logo} />
                                <View style={styles.column}>
                                    <Text style={[styles.textLeft, styles.bold]}>S Gruppen AS</Text>
                                    <Text style={styles.textLeft}>Hovenggata 15</Text>
                                    <Text style={styles.textLeft}>post@s-gruppen.no</Text>
                                    <Text style={styles.textLeft}>s-gruppen.no</Text>
                                    <Text style={styles.textLeft}> </Text>
                                    <Text style={styles.textLeft}>Stiann: 938 04 592</Text>
                                    <Text style={styles.textLeft}>Thim: 922 47 969</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.reportInfo}>
                            <View style={[styles.reportInfoRow, styles.tableCol]}>
                                <View style={styles.box2}>
                                    <Text style={styles.text}>Dato</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>Rigg</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>Omrigg</Text>
                                </View>
                                <View style={styles.box2}>
                                    <Text style={styles.text}>Saging</Text>
                                </View>
                                <View style={styles.box3}>
                                    <Text style={styles.text}>Boring</Text>
                                </View>
                                <View style={styles.box3}>
                                    <Text style={styles.text}>Timer</Text>
                                </View>

                                <View style={styles.stretch}>
                                    <Text style={styles.text}>Kommentar</Text>
                                </View>
                            </View>
                            <View style={[styles.reportInfoRow, styles.tableCol]}>
                                <View style={styles.box2}>
                                    <Text style={styles.text}></Text>
                                </View>

                                <View style={styles.box1}>
                                    <Text style={styles.text}></Text>
                                </View>

                                <View style={styles.box1}>
                                    <Text style={styles.text}></Text>
                                </View>

                                <View style={styles.box1}>
                                    <Text style={styles.text}>LM</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>T.B.</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>STK</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>DIM</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>TB</Text>
                                </View>

                                <View style={styles.box1}>
                                    <Text style={styles.text}>Dag</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>50%</Text>
                                </View>
                                <View style={styles.box1}>
                                    <Text style={styles.text}>100%</Text>
                                </View>

                                <View style={styles.stretch}>
                                    <Text style={styles.text}></Text>
                                </View>
                            </View>

                            {oppgaver.map((oppgave, index) => {
                                let jsDate;

                                console.log('oppgave.dato:', oppgave.dato);

                                if (oppgave.dato instanceof Timestamp) {
                                    console.log('Converting from Timestamp');
                                    jsDate = oppgave.dato.toDate();
                                } else if (typeof oppgave.dato === 'string') {
                                    console.log('Converting from string');
                                    jsDate = new Date(Date.parse(oppgave.dato));
                                } else if ('seconds' in oppgave.dato && 'nanoseconds' in oppgave.dato) {
                                    console.log('Converting from object with seconds and nanoseconds');
                                    const timestamp = new Timestamp(oppgave.dato.seconds, oppgave.dato.nanoseconds);
                                    jsDate = timestamp.toDate();
                                } else {
                                    console.log('Assuming it is a Date object');
                                    jsDate = new Date(oppgave.dato);
                                }

                                console.log('jsDate:', jsDate);

                                const formattedDato = jsDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
                                return (
                                    <View key={index} style={[styles.reportInfoRow, styles.tableCol]}>
                                        <View style={styles.box2}>
                                            <Text style={styles.text}>{formattedDato}</Text>
                                        </View>

                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.rigg}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.omrigg}</Text>
                                        </View>

                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.lm}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.tbs}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.stk}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.dim}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.tb}</Text>
                                        </View>

                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.timer?.vanlig}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.timer?.femti}</Text>
                                        </View>
                                        <View style={styles.box1}>
                                            <Text style={styles.text}>{oppgave?.timer?.hundre}</Text>
                                        </View>

                                        <View style={styles.stretch}>
                                            <Text style={styles.text}>{oppgave?.kommentar}</Text>
                                        </View>
                                    </View>
                                );
                            })}


                            {

                                <View style={[styles.reportInfoRow, styles.tableCol2]}>
                                    <View style={styles.box2}>
                                        <Text style={[styles.text, styles.bold]}>SUM:</Text>
                                    </View>

                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.rigg}</Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.omrigg}</Text>
                                    </View>

                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.lm}</Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}></Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.stk}</Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}></Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.tb}</Text>
                                    </View>

                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.vanlig}</Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.femti}</Text>
                                    </View>
                                    <View style={styles.box1}>
                                        <Text style={styles.text}>{sum?.hundre}</Text>
                                    </View>

                                    <View style={styles.stretch}>
                                        <Text style={styles.text}></Text>
                                    </View>
                                </View>
                            }


                        </View>
                    </View>
                </View>
                <View style={[styles.row, styles.padding, styles.gap, styles.otherSection]}>

                    <View style={[styles.column, styles.stretch3]}>
                        <Text style={styles.text}>Annet </Text>
                        <View style={[styles.bigBox, styles.wrap]}>
                            <Text style={styles.text}>{data?.annet}</Text>
                        </View>
                    </View>

                    <View style={[styles.column, styles.stretch3]}>
                        <Text style={styles.text}>Utstyr </Text>
                        <View style={[styles.bigBox, styles.row, styles.wrap]}>
                            <Text style={styles.text}>{createUtstyrText(data)}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footer}>
                    <View style={styles.row}>
                        <Text style={styles.text}>Dato: </Text>
                        <Text style={[styles.text, styles.textUnderline]}>{new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}</Text>
                    </View>

                    <View style={[styles.row, styles.alignBottom]}>
                        <Text style={styles.text}>Signatur:</Text>
                        <Image src={data?.signatur || Logo} style={styles.signering} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

function createUtstyrText(data) {
    let utstyrText = "";
    data?.utstyr?.forEach((utstyr, index) => {
        if (index === data.utstyr.length - 1) {
            utstyrText += utstyr.navn;
            return;
        }
        utstyrText += utstyr.navn + ", ";
    });
    return utstyrText;
}
*/
