
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Close, Search } from "@mui/icons-material";
import CircleIcon from "../miniComponents/circleIcon";
import { ReactComponent as CheckIcon } from '../../assets/icons/checkmark.svg';

export default function CustomCheckboxModal({ items, open, visible, Icon, iconKey, onClose, values, onChange, onNew, doNotCloseOnNew, children, noSearch }) {
    const { t } = useTranslation();
    const holder = useRef();
    const modal = useRef();
    const [searchString, setSearchString] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const [show, setShow] = useState(open || visible || false);
    const [originalValues, setOriginalValues] = useState(values);
    const [output, setOutput] = useState(values ? [...values] : []);

    useEffect(() => {
        setOutput(values);
    }, [values]);

    useEffect(() => {
        if (JSON.stringify(values) === JSON.stringify(output)) return console.warn('Values and output are the same');
        if (onChange) onChange(output);
    }, [output]);

    useEffect(() => {
        setShow(open || visible || false);
    }, [open, visible]);

    useEffect(() => {
        // Could use SearchObjects from search.jsx here, but this is faster
        if (searchString === '') return setFilteredItems(items);
        setFilteredItems(items.filter((item) => item?.navn?.toLowerCase().includes(searchString.toLowerCase()) || item?.name?.toLowerCase().includes(searchString.toLowerCase()) || item?.title?.toLowerCase().includes(searchString.toLowerCase()) || item?.tittel?.toLowerCase().includes(searchString.toLowerCase()) || item?.label?.toLowerCase().includes(searchString.toLowerCase())));
    }, [searchString, items]);

    const handleClose = () => {
        setSearchString('');
        if (onClose) onClose();
        setShow(false);
    }

    const handleClickOutside = (event) => {
        if (modal.current && !modal.current.contains(event.target) && event.target === holder.current) {
            console.log('click outside');
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [holder]);


    if (!show) return null;

    const sortedItems = filteredItems ? filteredItems.sort((a, b) => {
        const aValue = a?.navn || a?.name || a?.title || a?.tittel || a?.label || '';
        const bValue = b?.navn || b?.name || b?.title || b?.tittel || b?.label || '';
        return aValue.localeCompare(bValue);
    }) : [];

    let currentLetter = '';
    const itemsWithDividers = sortedItems.flatMap((item) => {
        const itemValue = item?.navn || item?.name || item?.title || item?.tittel || item?.label || '';
        const icon = Icon || (iconKey ? Object?.values(item)[0][iconKey] : false) || item?.icon || false
        let firstLetter = itemValue[0]?.toLowerCase()

        if (!firstLetter) firstLetter = 'Ingen Navn';

        if (firstLetter !== currentLetter) {
            currentLetter = firstLetter;
            return [<div key={item?.id || itemValue} className="orange">{firstLetter} <div className="division-line" /></div>, (
                <button type={"button"} className="modal-card row center-column flexApart stretch-width" onClick={() => {
                    setOutput(old => {
                        if (output.some((it) => it.id === item.id)) {
                            return old.filter(o => o !== item);
                        }
                        return [...old, item];
                    })
                }}>
                    <div className='row center-column'>
                        <CircleIcon icon={icon} />
                        <h2>{itemValue}</h2>
                    </div>
                    <div className={'checkbox ' + (output.some((it) => it.id === item.id) ? 'checked' : '')}>
                        <CheckIcon />
                    </div>
                </button>
            )];
        } else {
            return [
                <button type={"button"} key={item?.id || itemValue} className="modal-card row flexApart stretch-width" onClick={() => {
                    setOutput(old => {
                        if (output.some((it) => it.id === item.id)) {
                            return old.filter(o => o.id !== item.id);
                        }
                        return [...old, item];
                    })
                }}>
                    <div className='row center-column'>
                        <CircleIcon icon={icon} />
                        <h2>{itemValue}</h2>
                    </div>
                    <div className={'checkbox ' + (output.some((it) => it.id === item.id) ? 'checked' : '')}>
                        <CheckIcon />
                    </div>
                </button>
            ];
        }
    });

    return (
        <div className={"effektiv-jobb-modal-holder"} ref={holder}>
            <div className="effektiv-jobb-modal column noGap" ref={modal}>
                <div className="modal-header row flexApart">
                    <div />
                    <Close className="close-button" onClick={handleClose} />
                </div>

                <div className="modal-content column padding">
                    {!noSearch && <div className="row stretch-width">

                        <div className="column small-gap modal-search-container stretch-width">
                            <div className="modal-search row small-padding">
                                <input type="text" placeholder="Søk" onChange={(e) => setSearchString(e.target.value)} />
                                <Search />
                            </div>
                        </div>
                        {onNew && <button type={"button"} id="modal-ny" className="ny" onClick={() => {
                            if (onNew) onNew();
                            if (doNotCloseOnNew) return;
                            handleClose();
                        }}>{t("New", "Ny")} <Add /></button>}
                    </div>
                    }

                    <div className="column small-gap">
                        {itemsWithDividers}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}