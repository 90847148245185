import React, { useContext, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { useHistory } from 'react-router';
import { handleUpload } from '../../firebase';
import { IonPage } from '@ionic/react';
import { ReportContextCreation } from '../../App';
import DefaultWrapper from '../../components/defaultWrapper';

import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Capacitor } from '@capacitor/core';

export default function Canvas(props) {
    const [lines, setLines] = useState([]);
    const [strokeWidth, setStrokeWidth] = useState(10);
    const { reportContext, setReportContext } = useContext(ReportContextCreation);

    const history = useHistory();

    useEffect(() => {
        console.log(window.innerWidth, window.innerHeight)
        if (Capacitor.isNativePlatform()) ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
        return () => {
            if (Capacitor.isNativePlatform()) ScreenOrientation.unlock();
        }
    }, [])

    function navigateToNewPage(path, information) {
        console.log(information)
        history.push({
            pathname: path,
            state: information
        });
    }

    const handleMouseDown = (e) => {
        const stage = stageRef.current;
        const pos = stage.getPointerPosition();
        setLines([...lines, { points: [pos.x, pos.y], strokeWidth }]);
    };

    const handleMouseMove = (e) => {
        const isTouch = e.evt.type === 'touchmove';
        if ((!isTouch && (!e.evt.buttons)) || (isTouch && !e.evt.touches)) return;

        //const relativePos = stageRef.current.getPointerPosition();
        const stage = stageRef.current;
        const pos = /*isTouch ? {
            x: e.evt.touches[0].clientX -20,
            y: e.evt.touches[0].clientY + -20 + (window.innerHeight / 2) - (window.innerHeight / 0.20)
        } :*/ stage.getPointerPosition();

        const lastLine = lines[lines.length - 1];
        const newPoints = lastLine.points.concat([pos.x, pos.y]);
        const newLines = lines.slice(0, lines.length - 1);
        setLines([...newLines, { points: newPoints, strokeWidth }]);
    };


    const handleMouseUp = () => {
        if (lines.length === 0) return;
        const lastLine = lines[lines.length - 1];
        if (lastLine.points.length < 4) {
            const newLines = lines.slice(0, lines.length - 1);
            setLines(newLines);
        }
    };

    // send the image to firebase, then navigate to pdfPreview with the image and the report
    const onCapture = async () => {
        if (Capacitor.isNativePlatform()) ScreenOrientation.unlock();
        const dataUrl = stageRef.current.toDataURL({ pixelRatio: 2 });
        console.log(dataUrl);

        const blob = await fetch(dataUrl).then((r) => r.blob());
        const file = new File([blob], 'image.png', { type: 'image/png' });
        console.log(file);

        const imageLink = await handleUpload(file, "signaturer", randomId());
        console.log(imageLink);

        // navigate to pdfPreview with signature added to the state information
        //let report = history.location.state;

        // set context signatur to imageLink
        //report.signatur = imageLink;
        setReportContext({ ...reportContext, signatur: imageLink });


        navigateToNewPage('/signert', reportContext);
    };

    const stageRef = useRef();

    return (
        <DefaultWrapper extraButton={<button className="button" onClick={onCapture}>Ferdig</button>}>
            <div className="content-ny" style={{paddingTop:0}}>
                <div className="canvas-container">
                    <div className="canvas shadow column">
                        <Stage
                            ref={stageRef}
                            width={window.innerWidth}
                            height={window.innerHeight - 100}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}

                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}

                        //onPointerDown={handleMouseDown}
                        //onPointerMove={handleMouseMove}
                        //onPointerUp={handleMouseUp}
                        >
                            <Layer>
                                {lines.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={line.points}
                                        stroke="black"
                                        strokeWidth={Number(line.strokeWidth)}
                                        lineCap="round"
                                        lineJoin="round"
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    </div>
                    {/*
                    <div className="canvas-slider-container">
                        <input
                            className="slider"
                            type="range"
                            min={1}
                            max={20}
                            step={1}
                            value={strokeWidth}
                            onChange={(e) => setStrokeWidth(e.target.value)}
                        />
                    </div>
                            */}
                    <div className="bottomLine"></div>

                </div>
            </div>
        </DefaultWrapper>
    );
};


function randomId() {
    return Math.random().toString(36).substr(2, 9);
}