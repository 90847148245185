import { useEffect, useState } from "react";

import { SearchWithPopup } from "./searchWithPopup";
import { getCompanyUsers } from "../../database/users";





export default function PeopleSearchWithPopup({ onChange, label }) {
    const [peopleInCompany, setPeopleInCompany] = useState([]);
    const [filteredpPeopleInCompany, setFilteredpPeopleInCompany] = useState([]);
    const [chosenPeople, setChosenPeople] = useState([]);

    useEffect(() => {
        const onUpdate = (people) => {
            console.log(people)
            setPeopleInCompany(people);
        }

        getCompanyUsers(null, onUpdate);
    }, []);

    useEffect(() => {
        console.log(chosenPeople)
        const newChosen = chosenPeople.map(person => { return { 
            id: person.id, 
            name: person.name || person.navn,
            email: person.email || person.epost
        } })
        onChange(newChosen)
    }, [chosenPeople])

    useEffect(() => {
        setFilteredpPeopleInCompany(peopleInCompany.filter(person => !chosenPeople.some(chosenPerson => chosenPerson.id === person.id)))
    }, [chosenPeople, peopleInCompany])

    return (
        <SearchWithPopup items={filteredpPeopleInCompany}
            label={label}
            onChoise={
                (item) => {
                    console.log(item)
                    if (chosenPeople.filter(person => person.id === item.id).length > 0) {
                        const newPeople = [...chosenPeople];
                        return setChosenPeople(newPeople)
                    }
                    setChosenPeople([...chosenPeople, item])
                }
            }
            selected={chosenPeople}
            onRemove={(item) => {
                console.log(item)
                setChosenPeople(chosenPeople.filter(person => person.id !== item.id))
            }
            }
        />
    )
}