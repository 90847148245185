import { IonBadge, IonIcon, IonLabel } from "@ionic/react";
import { HeaderContext, UserContext } from "../App";
import { ellipse, square, triangle, home, calculator, person, chatboxEllipses, reader } from 'ionicons/icons';
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { checkIfAuthenticated, getCompanyId, getFirmSettings, getMessages, } from "../firebase";
import useAuth from "../useauth";

import { ReactComponent as AlarmIcon } from '../assets/icons/mdi_alarm.svg';
import { useKeyboard } from '@capacitor-community/keyboard-react';

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import { ReactComponent as AddIcon } from '../assets/icons/material-symbols_add.svg';
import { styled } from '@emotion/react';
import { IconButton, Menu, Tooltip } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Divider } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { Avatar } from "@mui/material";
import { PersonAdd, SwitchAccountRounded, Language } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
import { Logout } from "@mui/icons-material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from "react-i18next";
import { getI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout, logoutUser } from "../stores/authSlice";
import { Capacitor } from "@capacitor/core";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const paperProps = {
    elevation: 0,

    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

export default function Header() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentLng = getI18n().language;


    //is on Phone
    const { isOpen, keyboardHeight } = Capacitor.isNativePlatform() ? useKeyboard() : { isOpen: false, keyboardHeight: 0 };
    //const headerContext = useContext(HeaderContext);
    const { headerContext, setHeaderContext } = useContext(HeaderContext);
    const user = useSelector(state => state.user.user);
    const [moduleSettings, setModuleSettings] = useState({})
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const lngs = {
        en: { nativeName: 'English', icon: <span className='fi fi-gb'></span> },
        nb: { nativeName: 'Norsk', icon: <span className='fi fi-no'></span> },
        pl: { nativeName: 'Polski', icon: <span className='fi fi-pl'></span> },
    };
    const openLangMenu = Boolean(langAnchorEl);

    const history = useHistory();
    const params = useParams();

    const [tab, setTab] = useState("hjem");

    const location = useLocation();

    useEffect(() => {
        const onUpdate = (e) => {
            console.log("settings changed")
            setModuleSettings(e);
        }
        const getSettings = async () => {
            await getFirmSettings("moduleSettings", onUpdate);
        }
        getSettings();
    }, [user]);

    useEffect(() => {
        //console.log('isActive', isActive); // debug log
        let tab = ""

        if (location.pathname === "/hjem") tab = "hjem"
        if (location.pathname === "/kalkulator") tab = "kalkulator"
        if (location.pathname === "/chat") tab = "chat"
        if (location.pathname === "/tab3") tab = "tab3"
        if (location.pathname === "/profile") tab = "profile"
        if (location.pathname === "/timeføring") tab = "timeføring"



        return setTab(tab)
    }, [location.pathname]); // Added props?.navigateTo to dependency list

    function navigateToNewPage(path, information) {
        //console.log(information)
        setTab(path.replace("/", "") || "hjem")
        history.push({
            pathname: path,
            state: information
        });
    }



    const { loading } = useAuth();
    const [messages, setMessages] = useState([]);
    const [isInChatComponent, setIsInChatComponent] = useState(false);



    const calculateNewMessages = () => {
        if (isInChatComponent) {
            return 0;
        }
        return 0
        const lastSeenTimestamp = JSON.parse(localStorage.getItem("lastSeenTimestamp") || "0");
        const newMessages = messages.filter(message => message.timestamp.seconds > lastSeenTimestamp);

        return newMessages.length;
    };

    // Add this state to your component to store new messages count
    const [newMessagesCount, setNewMessagesCount] = useState(0);

    // Modify the useEffect hook to update the newMessagesCount state
    useEffect(() => {

        const unsubscribe = getMessages((fetchedMessages) => {

            setMessages(fetchedMessages);
            setNewMessagesCount(calculateNewMessages());
        });

        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, []);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOutEndFunction = () => {
        navigateToNewPage("/login");
    }
    //console.log("headerContext", headerContext)

    if (isOpen) return null;
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className="header-container">
                <div className="header">
                    <button tab="Hjem" href="/hjem" className={
                        tab === "hjem" ? "column noGap button-no-style orange" :
                            "column button-no-style noGap"
                    }
                        onClick={(e) => navigateToNewPage("/hjem")}
                    >
                        <IonIcon aria-hidden="true" icon={home} />
                        <p>{t("Home", "Hjem")}</p>
                    </button>
                    {/*
                <button tab="chat" href="/chatLobby" onClick={() => {
                    navigateToNewPage("/chatLobby");
                }}
                    className={tab === "chat" ? "column button-no-style orange" :
                        "column button-no-style"}
                >
                    <IonIcon aria-hidden="true" icon={chatboxEllipses} />
                    {newMessagesCount > 0 && (
                        <IonBadge color="danger" style={{ position: "absolute", top: "5px", right: "5px" }}>
                            {newMessagesCount}
                        </IonBadge>
                    )}
                    <IonLabel>{t("Chat", "Chat")}</IonLabel>
                </button>
                */}

                    <button tab="timeføring" href="/timeføring" className={
                        tab === "timeføring" ? "column button-no-style orange" :
                            "column button-no-style"}
                        onClick={(e) => navigateToNewPage("/timeføring")}
                    >
                        <AlarmIcon style={{ width: 32, height: 32 }} />
                        <IonLabel>{t("Time Management", "Timeføring")}</IonLabel>
                    </button>

                    <div style={{ position: "relative", width: 32 }}>
                        <IconButton
                            style={{ position: "absolute", boxShadow: "0px 0px 10px rgba(0,0,0,0.8)" }}
                            onClick={handleClick}
                            //size={ "medium"}
                            sx={{ width: 52, height: 52 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar src={user?.photoUrl} alt="profile picture" style={{
                                backgroundColor: "var(--contrastColor)",
                                width: 52,
                                height: 52,
                                objectFit: 'contain'
                            }} />
                        </IconButton>
                    </div>

                    <Menu anchorEl={langAnchorEl}
                        id="lang-menu"
                        open={openLangMenu}
                        onClose={() => setLangAnchorEl(null)}
                        onClick={() => setLangAnchorEl(null)}
                        PaperProps={paperProps}
                        style={
                            {
                                transform: "translateY(-3%) translateX(-2%)",
                            }
                        }
                        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    > {Object.keys(lngs).map((lng) => (
                        <MenuItem key={lng} onClick={() => {
                            setLangAnchorEl(null)
                            getI18n().changeLanguage(lng);
                            localStorage.setItem("i18nextLng", lng)
                        }}>
                            {lngs[lng].icon} {lngs[lng].nativeName}
                        </MenuItem>
                    ))}
                    </Menu>

                    <Menu anchorEl={anchorEl}

                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={paperProps}
                        style={
                            {
                                transform: "translateY(-3%) translateX(-2%)",
                            }
                        }
                        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose()
                            navigateToNewPage("/profile")
                        }}>
                            <Avatar /> {t("My profile", "Min bruker")}
                        </MenuItem>
                        <Divider />
                        <MenuItem className="row" onClick={(e) => {
                            handleClose()
                            navigateToNewPage("/switch-company")
                        }
                        }>
                            <ListItemIcon>
                                <SwitchAccountRounded fontSize="small" />
                            </ListItemIcon>
                            {t("Switch Company", "Bytt Firma")}
                        </MenuItem>
                        <MenuItem className="row" onClick={(e) => {
                            handleClose()
                            setLangAnchorEl(e.currentTarget)
                        }}>
                            <ListItemIcon>
                                <Language fontSize="small" />
                            </ListItemIcon>
                            {t("Language", "Språk")} <>
                                <div>{lngs?.[currentLng]?.icon  ? lngs?.[currentLng]?.icon : null}</div>
                                <h3 className='beta'>BETA</h3>
                            </>
                        </MenuItem>
                        <MenuItem className="row" onClick={async (e) => {
                            handleClose()
                            dispatch(logoutUser());
                        }}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {t("Log out", "Logg ut")}
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </ThemeProvider>

    )
}