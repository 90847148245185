import CircleIcon from "../miniComponents/circleIcon";
import CustomerModal from "./CustomerModal";
import { useEffect, useState } from "react";
import TimetypeModal from "./TimetypeModal";
import { ClockIcon } from "@mui/x-date-pickers";
import { ArrowDropDown } from "@mui/icons-material";


export default function TimetypeModalButton({ values, label, icon, title, items, onChange }) {
    const [modalVisible, setModalVisible] = useState(false);
    console.log(values);
    const [info, setInfo] = useState(typeof values === 'array' ? [...values] : []);

    useEffect(() => {
        if (typeof values !== 'array') return
        setInfo(values);
    }, [values])

    return (
        <div className="button-list stretch-width" >
            <button type={"button"} className="button-list-button stretch-width" onClick={() => {
                setModalVisible(true)
            }}>
                <div className="row relative center-column stretch-width flexApart">
                    <label className="button-list-button-label">{label}</label>
                    <div className="row center-column">
                        <CircleIcon icon={ClockIcon} />
                        <p>{
                            info.map((i, index) => {
                                return index === 0 ? i.navn : ', ' + i.navn
                            })
                        }</p>
                    </div>
                    <ArrowDropDown />


                </div>

            </button>
            <TimetypeModal
                visible={modalVisible}
                values={info}
                onChange={(e) => {
                    console.log(e);
                    if (onChange) onChange(e);
                    setInfo(e)
                }} onClose={() => setModalVisible(false)} />
        </div>
    )
}