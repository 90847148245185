import { useEffect, useState } from "react";
import CustomInput from "../miniComponents/customInput";
import { getCompaniesFromBrregByOrgName } from "./getCompanyFrombrreg";


export default function OrgNameInput({ onChoise, ...props }) {
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (props?.value?.length > 3) {
            getCompaniesFromBrregByOrgName(props?.value).then((data) => {
                setSuggestions(old => data);
            })
        } else {
            setSuggestions(old => []);
        }
    }, [props?.value])

    const convertSuggestionToCustomer = (suggestion) => {
        const postalAddressDetail = suggestion?.postAdresse
        onChoise({
            name: suggestion?.navn,
            orgNumber: suggestion?.organisasjonsnummer,
            address: suggestion?.forretningsadresse?.adresse[0],
            postalCode: suggestion?.forretningsadresse?.postnummer,
            city: suggestion.forretningsadresse?.poststed,
            postalAddress: {
                address: postalAddressDetail?.adresse[0] || "",
                postalCode: postalAddressDetail?.postnummer || "",
                city: postalAddressDetail?.poststed || "",
                country: postalAddressDetail?.land || "",
                countryCode: postalAddressDetail?.landkode || "",
            }
        })
    }

    return (
        <CustomInput
            {...props}
            suggestions={suggestions}
            onSuggestion={convertSuggestionToCustomer}
        />
    )
}