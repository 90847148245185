import { createDateOutOfFirebaseTimestamp } from "../timeføring";
import { DOUGHNUT_COLORS } from "./doughnut";
import { roundToNearestHalfHour } from "./utils";




const groupByDay = (times) => {
    const groupedByDay = times.reduce((acc, current) => {
        if (!current.endDate) return acc;
        if (current.startDate) {
            const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

            const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

            if (!acc.has(day)) {
                acc.set(day, []);
            }
            acc.get(day).push(current);
        }

        return acc;
    }, new Map());
    return groupedByDay;
};
/*
for (let [_, recordings] of groupedByDay) {
    recordings.sort((a, b) => {
        if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
            return b.endDate.toDate() - a.endDate.toDate();
        } else {
            return 0;
        }
    });
}*/
/*
const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse().map(([date, recordings]) => {
    const [year, month, day] = date.split('-');
    const title = `${day}. ${getMonth(parseInt(month) - 1, t).slice(0, 3)} ${year}`; // change the title to the desired format

    return {
        title,
        dates: recordings,
    };
});
*/
const splitIntoTilleggsType = (times) => {
    const result = {};


    times.forEach(time => {
        const tillegArray = Array.isArray(time?.tilleg) ? time?.tilleg : [time?.tilleg];

        tillegArray.forEach(tilleg => {
            const name = tilleg?.navn || tilleg?.name || "Normal-";
            if (!result[name]) {
                result[name] = [];
            }
            result[name].push(time);
        })
    })
    return result;
};

// Object.values(tilleggsType)
const tilleggTypesToHours = (array) => array.map(tilleg => {

    let totalSeconds = 0;
    tilleg.forEach(time => {
        //console.log(time.startDate, time.endDate)
        if (!time.endDate || !time.startDate) return;
        const startDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.startDate));
        const endDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.endDate));
        //console.log(startDate, endDate)
        totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
    });

    let totalHours = totalSeconds / 3600; // Convert total seconds to hours
    return Number(totalHours).toFixed(1); // Return total hours rounded to 2 decimals
});

export const getDoughnutData = (times) => {
    const tilleggsType = splitIntoTilleggsType(times);
    const data = tilleggTypesToHours(Object.values(tilleggsType));
    //labels are the keys of the tilleggsTypeArray
    let labels = Object.keys(tilleggsType).map((label, index) => `${String(data[index])?.replace('.', ',')} t - ${label}`)
    //let labels = tilleggsTypeArray.map((label, index) => `${String(data[index])?.replace('.', ',')} t - ${label}`)

    return {
        labels: labels,
        datasets: [
            {
                label: 'Time per Tilleg',
                data: data,
                backgroundColor: DOUGHNUT_COLORS,
                hoverBackgroundColor: ['#FFFFFF'],
                labelColor: '#FFFFFF',
                //borderColor: '#faebd700',
                borderWidth: 0, // Increase the borderWidth to create spacing
                color: '#FFFFFF',
                borderRadius: 0,
                //spacing: 2,

            }
        ]
    };
};


export const calculateHoursOfTimes = (times) => {
    let hours = 0;
    times.forEach(time => {
        if (time.endDate && time.startDate) {
            const startDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.startDate));
            const endDate = roundToNearestHalfHour(createDateOutOfFirebaseTimestamp(time.endDate));

            const timeDiff = endDate - startDate;
            const hoursDiff = timeDiff / 1000 / 60 / 60;
            hours += hoursDiff;
        }
    });

    return Number(hours).toFixed(1)
};