import { useEffect } from "react";
import TimetrackingDoughnut from "../../pages/timeføring/utils/doughnut";
import { useSelector } from "react-redux";
import { getMonthId, getWeekId } from "../../pages/timeføring/utils/utils";
import useCollectionSubscriptionRS from "../utils/hooks/RSfirebaseCollectionSubscriber";
import { useHistory } from "react-router";
import { getMonth } from "../../pages/timeføring/timeføring";
import { addTime, removeTime, setTimes, updateTime } from "../../stores/timeSlice";
import { useTranslation } from "react-i18next";
import { SimplifiedTimeCardWeek } from "./timeCards";
import store from "../../stores/mainStore";
import moment from "moment-timezone";
import { generateWeeksInRange, getNorwegianWeekNumber } from "../../pages/timeføring/førte-timer";




export default function TrackedTimeCard({ month }) {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const history = useHistory();
    const monthSel = useSelector(state => state.time.months?.byId?.[getMonthId(month.date)]);
    const monthsSel = useSelector(state => state.time.months);
    const weeks = useSelector(state => monthSel?.weeks.map(week => state.time?.weeks?.byId?.[week]));


    const inView = true

    const startOfMonth = new Date(month.date);
    startOfMonth.setDate(1);
    const endOfMonth = new Date(month.date);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);
    endOfMonth.setDate(0);

    const { previousRecordings, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.allTimes.allIds,
        path: `tid/${user.uid}/timer`,
        setFunction: setTimes,
        updateFunction: updateTime,
        addFunction: addTime,
        removeFunction: removeTime,
        key: "previousRecordings",
        payload: {},
        idOnly: true,
        returnItemsByIdSelector: (state) => state.time.allTimes.byId,
        enabled: inView,
        // Only the once created by the user
        filters: [
            { field: 'startDate', operator: '>', value: startOfMonth },
            { field: 'startDate', operator: '<', value: endOfMonth },
        ]
    });

    const navigateToNewPage = (path, information) => {
        history.push({
            pathname: path,
            state: information
        });
    }

    const createWeeksInMonth = (weeksToTransform) => {
        const weeks = generateWeeksInRange(moment(month.date).startOf('month').toDate(), moment(month.date).endOf('month').toDate());

        const weeksInMonth = weeks.map(week => {
            const date = moment(week.date).toDate();
            const startDate = moment(date).startOf('week').toDate();
            const endDate = moment(date).endOf('week').toDate();
            const weekNumber = getNorwegianWeekNumber(endDate);
            const weekNumberYear = moment(date).isoWeekYear();
            const id = getWeekId(date);
            const selector = (state) => state.time.weeks.byId?.[id];
            const state = selector(store.getState());

            const weekData = { ...week, weekNumber, times: state?.times || [], startDate, endDate, weekNumberYear };
            return weekData;
        })
        return weeksInMonth;
    }
    const transformedWeeks = createWeeksInMonth(weeks)
    if (!transformedWeeks) return null;
    return (
        <div className="column">
            <h2>{getMonth(month.date, t)}</h2>
            <TimetrackingDoughnut times={transformedWeeks.map(week => week.times).flat()} />

            {transformedWeeks.reverse().map((weekData, weekIndex) => {
                return (
                    <SimplifiedTimeCardWeek key={weekIndex} weekData={weekData} onClick={() => {
                        navigateToNewPage(`/uke/${weekData.weekNumber}`, { week: weekData });
                    }} />
                )
            })}

        </div>
    )
}