import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { addSubscription, removeSubscription } from "../subscriptionsSlice";
import { db } from '../../firebase';
import store from '../mainStore';




export default function SubscriptionObserver() {
    const [subscribers, setSubscribers] = useState([]);
    const dispatch = useDispatch();
    const subscriptions = useSelector((state) => state.subscriptions);
    const companyId = useSelector((state) => state.company.companyId);

    useEffect(() => {
        startRemovalInterval();

        window.addEventListener("addColSubscriber", subscriberCheck);

        return () => {
            window.removeEventListener("addColSubscriber", subscriberCheck);
        }
    }, []);

    const subscriberCheck = (event) => {
        const params = event.detail;

        if (subscriptions.allIds.includes(params.path)) {
            return console.warn("Subscription already exists");
        }
        addSubscriberOb(params);
    }

    const addSubscriberOb = ({
        // Redux
        selector,
        payload = {},
        addFunction = null,
        setFunction = null,
        updateFunction = null,
        removeFunction = null,
        idOnly = false,

        // Firestore
        path = '',
        filters = [],
        timesTried = 0,
    }) => {
        console.log(companyId)
        if (!companyId) return console.error('No companyId found');
        if (timesTried > 5) return console.error('Could not fetch data after 5 tries');
        //if (filters.length > 0 && filters.some(filter => !filter.value)) return console.error('Filter value missing');
        let q = query(collection(db, path));
        filters.forEach(filter => {
            const { field, operator, value } = filter;
            q = query(q, where(field, operator, value));
        });

        // ISSUE : If the initial fetch is slower than the real-time listener, this will override. Think about starting the listener once fetched.
        const fetchInitialData = async () => {
            try {
                const snapshot = await getDocs(q);
                //const state = store.getState()[selector];
                const state = selector(store.getState());
                if (addFunction) {
                    //console.log("STATE: ", state)
                    const newDocs = snapshot.docs.map(doc => doc.data()).filter(doc => state.some(item => (idOnly ? item : item.id) === doc.id));
                    newDocs.forEach(doc => dispatch(addFunction({ document: doc, ...payload })));
                    return
                }
                if (updateFunction) {
                    const newDocs = snapshot.docs.map(doc => doc.data()).filter(doc => state.some(item => (idOnly ? item : item.id) === doc.id));
                    newDocs.forEach(doc => {
                        dispatch(updateFunction({ document: doc, ...payload }))
                    });
                    return
                }
                dispatch(setFunction({ documents: snapshot.docs.map(doc => doc.data()), ...payload }));
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData(); // Fetch data once before setting up real-time listener

        // Set up the onSnapshot listener (if not already active)

        const sub = onSnapshot(q, (snapshot) => {
            if (setFunction && !updateFunction) return dispatch(setFunction({ documents: snapshot.docs.map(doc => doc.data()), ...payload }));

            snapshot.docChanges().forEach((change) => {
                //console.log("NEW CHANGE", change, change.type === 'removed', removeFunction)
                const data = change.doc.data();
                const newPayload = { document: data, ...payload, id: data.id };
                if (change.type === 'added' && addFunction) {
                    dispatch(addFunction(newPayload)); // Handle new documents
                }
                if (change.type === 'modified' && updateFunction) {
                    dispatch(updateFunction(newPayload)); // Update store with changes
                }
                if (change.type === 'removed' && removeFunction) {
                    dispatch(removeFunction(newPayload)); // Handle removals
                }
            });
        });
        // Add the subscription to the Redux store
        const filterString = filters.map(filter => `${filter.field}${filter.operator}${filter.value}`).join('');
        const fullPathAndFilter = `${path}${filterString}`;
        const subObj = { path: fullPathAndFilter, unsubscribe: sub };
        dispatch(addSubscription(subObj));
        setSubscribers(old => {
            if (old.find(sub => sub.path === path) && old.find(sub => sub.path === path).unsubscribe) {
                old.find(sub => sub.path === path).unsubscribe();
            }
            return [
                ...old.filter(sub => sub.path !== path),
                subObj
            ]
        });
    }

    const startRemovalInterval = () => {
        // Start a timer that checks if the page is still open that requests the subscription.
        // If it is not, remove the subscription.

        setInterval(async () => {
            const subscriptions = store.getState().subscriptions;
            subscriptions.allIds.forEach(async (id) => {
                const isActive = await new Promise((resolve, reject) => {

                    const listenFunc = (Rid) => {
                        if (Rid === id) {
                            resolve(true);
                        }
                    };

                    window.dispatchEvent(new CustomEvent("checkForSubscribers", { detail: { id: id, functionToCall: listenFunc } }));

                    setTimeout(() => {
                        resolve(false);
                    }, 3000);
                });

                if (!isActive) {
                    // Remove the subscription
                    dispatch(removeSubscription(id));
                    const sub = subscribers.find(sub => sub.path === id);
                    if (sub && sub.unsubscribe) sub.unsubscribe();
                    setSubscribers(old => old.filter(sub => sub.path !== id));
                }

            });
        }, 30000);

    }

    /*const stopAllSubscriptions: (state) => {
        state.allIds.forEach(id => {
            if (state.byId[id].unsubscribe) {
                state.byId[id].unsubscribe();
            }
            delete state.byId[id];
        });
        state.allIds = [];
    }*/

    return (<></>)
}


export const addColSubscriber = (params) => {
    window.dispatchEvent(new CustomEvent("addColSubscriber", { detail: params }));
}

