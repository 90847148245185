import { collection } from "firebase/firestore";
import { db, getFirmSettings } from "../firebase";
import store from "../components/store";





export async function getCompanyTimeSettings() {
    const settings = await getFirmSettings("timeforingsInstillinger");
    console.log(settings)
    return { value: settings };
}

export function getTimeById(id) {

    const docRef = doc(db, 'Kompanier', companyId(), 'tid', store.getState().user.user.uid, 'timer', id);
    const fetchedDoc = getDoc(docRef);
    return fetchedDoc.data();
}