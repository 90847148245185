import { IonPage } from '@ionic/react';
import GoogleButton from 'react-google-button';
import { useHistory } from 'react-router';
import { checkIfAuthenticated, athentication, signInWithEmailAndPassword, signInWithEmailAndPasswordInFirebase, signUpWithEmailAndPassword, logout, auth, handleLogin, acceptInvitation, getAuthUserId, app } from '../../firebase';
import DefaultWrapper from '../../components/defaultWrapper';
import { createRef, useEffect, useState } from 'react';
import CustomInput from '../../components/miniComponents/customInput';
import logo from '../../assets/logo.png';
import ButtonListButton from '../../components/miniComponents/buttonListButton';
import { ReactComponent as addIcon } from '../../assets/icons/material-symbols_add.svg';
import { getAuth, signInWithCredential, signInWithPopup, GoogleAuthProvider, setUser, OAuthCredential, OAuthProvider } from 'firebase/auth';
import { AlertContext, UserContext } from '../../App';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSelector } from 'react-redux';


const functions = getFunctions(app, 'europe-west2');
const generateCustomToken = httpsCallable(functions, 'generateCustomToken');



export function LoginPage(props) {
    const { t } = useTranslation();
    const [email, setEmail] = useState(localStorage?.getItem("loginMail") || '');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [signUpMessage, setSignUpMessage] = useState('');
    const [alreadySignedUp, setAlreadySignedUp] = useState(false);
    const [signingIn, setSigningIn] = useState(false)
    const user = useSelector(state => state.user.user);
    const passwordRef = createRef();
    const history = useHistory();
    const appleSignInRef = createRef();
    const { alertContext, setAlertContext } = useContext(AlertContext);


    const authEndFunction = async () => {
        console.log("Auth end function called");
        const invId = history?.location?.state?.invId;
        if (invId) await acceptInvitation(user?.uid, { id: invId })
        //localStorage.setItem('userId', await getAuthUserId());
        console.log("GOING TO COMPANY")
        navigateToNewPage('/company');
    };

    const handleEmailSignIn = async (e) => {
        e.preventDefault()
        setSigningIn(old => true)
        try {
            console.log("Sign in")
            await signInWithEmailAndPasswordInFirebase(email, password).then(() => authEndFunction())
            /*
            if (signedIn) {
                console.log("AUTH END FUNCTION")
                authEndFunction();
            }*/
        } catch (error) {
            console.log("Error")
            setAlertContext({
                text: <div className="column">
                    <h3>{t("Could not log in. Are you sure you are putting in the right email and password?", "Kunne ikke logge in, er du sikker på at det er riktig email og passord?")}</h3>
                    <div className="row"> <p>{t("Error", "Feil")}: </p> <p>{error?.message || error}</p>
                    </div>
                </div>,
                onConfirmText: "Ok"
            })
            setMessage(error.message);
            console.error(error);
            setSigningIn(old => false)
        }
    };


    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const handleLogin = async () => {
        setSigningIn(true)
        console.log("Login")
        const signInWithGoogle = async () => {
            try {
                // 1. Create credentials on the native layer
                const result = await FirebaseAuthentication.signInWithGoogle();
                // 2. Sign in on the web layer using the id token
                console.log("Login done")
                const credential = GoogleAuthProvider.credential(result.credential?.idToken);
                const auth = getAuth();
                await signInWithCredential(auth, credential);
                //await FirebaseAuthentication.linkWithGoogle();
                console.log("Login done")

                navigateToNewPage('/company');
            } catch (error) {
                setSigningIn(false)
            }
        };
        signInWithGoogle();
    };

    const handleLoginApple = async (e) => {
        setSigningIn(true);

        try {
            // 1. Sign in with Apple and get the result

            const result = await FirebaseAuthentication.signInWithApple({
                skipNativeAuth: true,
            });


            if (result) {

                // 2. Sign in on the web layer using the id token and nonce
                const provider = new OAuthProvider('apple.com');
                const credential = provider.credential({
                    idToken: result.credential?.idToken,
                    rawNonce: result.credential?.nonce,
                });
                const auth = getAuth();
                await signInWithCredential(auth, credential);

                // 3. Get the ID token from the web layer
                const webIdToken = await auth.currentUser.getIdToken(true);

                // 4. Call the Firebase function to get a custom token
                const response = await generateCustomToken({ idToken: webIdToken });

                if (!response.data || !response.data.customToken) {
                    throw new Error('Failed to fetch custom token');
                }

                const customToken = response.data.customToken;

                // 5. Sign in on the native layer with the custom token
                await FirebaseAuthentication.signInWithCustomToken({ token: customToken });


                // 4. Navigate to the next page on successful login
                console.log("MOVING OVER TO COMPANY")
                navigateToNewPage('/company');
            } else {
                throw new Error('No result from Apple Sign-In');
            }
        } catch (error) {
            console.error('Login failed', error);
            // Handle the error (e.g., show an error message to the user)
        } finally {
            setSigningIn(false);
        }
    };

    const renderAppleSignInButton = () => {
        window.AppleID.auth.renderButton()

        // Add event listener to the Apple Sign-In button

        // clone the button to remove the event listener
        const appleSignInButton = document.getElementById('appleid-signin');
        if (appleSignInButton) {
            var old_element = appleSignInButton;
            var new_element = old_element.cloneNode(true);
            old_element.parentNode.replaceChild(new_element, old_element);
            appleSignInRef.current = new_element;

            new_element.addEventListener('click', handleLoginApple);

        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/no_NO/appleid.auth.js";
        script.async = true;
        script.onload = () => {
            renderAppleSignInButton();
        };
        document.body.appendChild(script);

        return () => {
            // Cleanup: Remove event listener and script
            const appleSignInButton = document.getElementById('appleid-signin');
            if (appleSignInButton) {
                appleSignInButton.removeEventListener('click', handleLoginApple);
            }
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (signingIn) return;
        if (!window.AppleID) return;
        renderAppleSignInButton();

    }, [signingIn])

    useEffect(() => {
        localStorage.setItem("loginMail", email);
    }, [email])


    return (
        <DefaultWrapper noBackButton={true} noHeader={true}>

            <div className='column padding stretch-height center-column content-ny' style={{ paddingTop: "10vh" }}>
                <div className='column center'>
                    <img src={logo} alt='' style={{ width: '60%', maxWidth: 400, maxHeight: 240 }}></img>
                    <h1 className='bold center'>EffektivJobb</h1>
                </div>

                {signingIn ?
                    <CircularProgress width={"80vw"} height={"80vw"} />
                    :
                    <div className='column'>
                        <div className='login-buttons column center'>
                            <GoogleButton onClick={handleLogin} label='Logg inn med google' style={{ borderRadius: 5 }} />

                            <div style={{
                                width: "240px",
                                height: "50px"
                            }} ref={appleSignInRef}
                                id="appleid-signin" className="signin-button" data-color="white" data-border="true"></div>
                        </div>

                        <div className='column stretch-width'>
                            <div className='long-line stretch-width'></div>
                        </div>

                        <form className='column' onSubmit={handleEmailSignIn}>

                            <div className='column'>
                                <h2>{t("Log in", "Logg inn")}</h2>
                            </div>
                            <CustomInput
                                id={"email"}
                                type="email"
                                placeholder={t("Email", "Epost")}
                                value={email}
                                required
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    // if enter or down arrow, go to next input
                                    if (e.keyCode === 13 || e.keyCode === 40) {
                                        return passwordRef.current.focus();
                                    }
                                }}
                            />
                            <CustomInput
                                ref={passwordRef}
                                id={"password"}
                                required
                                type="password"
                                placeholder={t("Password", "Passord")}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                //
                                onKeyDown={(e) => {
                                    // If enter, try to login
                                    if (e.keyCode === 13) {
                                        return handleEmailSignIn();
                                    }
                                }}
                            />
                            <div className='row flexApart'>
                                <div/>
                                <a onClick={() => navigateToNewPage('/glemt-passord', {email})}>{t("Forgot password?", "Glemt passord?")}</a>
                            </div>

                            <div className='button-list'>
                                <button className='button-list-button' type='submit'>{t("Log in", "Logg In")}</button>
                            </div>
                        </form>

                        <div className='column stretch-width'>
                            <div className='long-line stretch-width'></div>
                        </div>

                        <div className='button-list'>
                            <ButtonListButton icon={addIcon} title={t("Register a new user", "Registrer ny bruker")} onPress={() => navigateToNewPage('/registrer', {
                                invId: history?.location?.state?.invId
                            })}></ButtonListButton>
                        </div>
                    </div>
                }


            </div>
        </DefaultWrapper >
    );
}
