import { useHistory, useParams } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import TemplatePreview from "./malPreview";
import CreateTestMal from "./createTestMal";



export default function TemplatePreviewPage() {
    const history = useHistory();
    const params = useParams();

    return (
        <DefaultWrapper skipBack={true} onBack={() => {
            history.push("/reportSettings/" + params.id, {mal:history.location.state})
        }
        }>
            <CreateTestMal mal={history.location.state} />
        </DefaultWrapper>
    )
}