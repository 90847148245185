

export function oldgetNorwegianWeekNumber(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Make Sunday's day number 7
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first day of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 1);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the week number
    return weekNo;
}

export function getNorwegianWeekNumber(date) {
    const newDate = new Date(date);
    
    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Adjust for Sunday being day 0 in JavaScript, but day 7 in ISO
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first Thursday of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 4);
    const firstThursday = yearStart.getDate() - ((yearStart.getDay() + 6) % 7);
    yearStart.setDate(firstThursday);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the ISO week number
    return weekNo;
}


// Helper to convert Date or Timestamp to local time string
export const convertToLocalTimeString = (date) => {
    if (!date) return null;
    if (date instanceof Date || (typeof date.toDate === 'function')) {
        return new Date(date.toDate ? date.toDate() : date).toLocaleString();
    }
    if (typeof date === 'string') return new Date(date).toLocaleString();
    return null;
};

// Helper to calculate week and month IDs based on startDate
export const getWeekId = (dateToBe) => {
    const date = new Date(dateToBe);
    const year = date.getFullYear();
    const weekNumber = getNorwegianWeekNumber(date); // Assuming this function returns the correct ISO week number
    return `${year}-W${weekNumber}`;
}

export const getMonthId = (dateToBe) => {
    const date = new Date(dateToBe);
    return `${date.getFullYear()}-${date.getMonth() + 1}`;
}


export const getTimesBetweenDates = (state, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return state.allTimes.allIds
        .filter(time => {
            const timeStart = new Date(time.startDate);
            //const timeEnd = new Date(time.endDate);

            return timeStart >= start && timeStart <= end;
        });
}

export function roundToNearestHalfHour(date) {

    let start = date
    const startMinutes = date.getMinutes();
    if (startMinutes < 15) {
        start = new Date(date.setMinutes(0));
    } else if (startMinutes < 45) {
        start = new Date(date.setMinutes(30));
    } else {
        start = new Date(date.setMinutes(0));
        start = new Date(date.setHours(date.getHours() + 1));
    }
    return start;
}
