import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';

export default function CustomTextareaInput(props) {
    const inputRef = useRef();
    const [textAreaHeight, setTextAreaHeight] = useState(40); // Setting initial height to 40px 
    const [focused, setFocused] = useState(false);

    // React spring animation
    const textAreaSpringProps = useSpring({
        height: textAreaHeight,
        config: {
            tension: 300,
            friction: 26
        }
    });

    const adjustHeight = () => {
        if (inputRef.current) {
            // First, set the height to 'auto' to calculate the scrollHeight correctly
            inputRef.current.style.height = 'auto';
            const requiredHeight = inputRef.current.scrollHeight;
            setTextAreaHeight(requiredHeight);
            // After setting the height to scrollHeight, assign it to the textarea
            inputRef.current.style.height = `${requiredHeight}px`;
        }
    }

    useEffect(() => {
        adjustHeight();
    }, [props.value]);
    
    return (
        <div className={'customInput row ' + props.outerClassName}>
            {props?.label &&
                <label className={
                    (props.value || focused || props?.type === 'number' || props?.type === 'date' || props?.beforeInput)
                        ? 'customInput_label customInput_label_active'
                        : 'customInput_label'
                }>{props?.label}</label>
            }
            <animated.textarea
                style={{
                    width: '100%',
                    resize: 'none',
                    height: textAreaSpringProps.height.interpolate(height => `${height}px`),
                    overflow: 'hidden',
                    minHeight: '40px'
                }}
                onFocus={(e) => {
                    if (props?.onFocus) props?.onFocus(e);
                    setFocused(true);
                }}
                onBlur={(e) => {
                    setFocused(false);
                    if (props.onBlur) props?.onBlur(e);
                }}
                ref={inputRef}
                className={props.className}
                type={props?.type || "text"}
                onChange={(e) => {
                    props.onChange(e);
                    adjustHeight();
                }}
                value={props.value}
                placeholder={props?.placeholder}
            ></animated.textarea>
            {!props.noIcon && <EditIcon></EditIcon>}
        </div>
    );
}
