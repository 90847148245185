import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';

const lng = localStorage.getItem("i18nextLng") || 'nb'
i18next
    .use(initReactI18next)
    //.use(LanguageDetector)
    .use(Backend)
    .init({
        backend: {
            projectId: 'cc94c621-e210-465c-a5cf-d7cb4a435976',
            referenceLng: 'nb',
        },
        lng: lng,
        fallbackLng: 'nb',
        debug: true,
        saveMissing: true,
        saveMissingTo: 'all',
        interpolation: {
            escapeValue: false,
        },
    });


//localStorage.setItem("i18nextLng", lng)

