import CircleIcon from "./circleIcon";
import { ReactComponent as CheckIcon } from '../../assets/icons/checkmark.svg';
import { useState } from "react";



export default function CheckboxInput({ label, value, onChange, icon }) {
    const [selected, setSelected] = useState(value || false);


    return (
        <button className='customInput customCheckboxInput row flexApart small-gap center-column' onClick={(e) => {
            e.preventDefault();
            if (onChange) onChange(!value);
            setSelected(old => !old.selected);
        }}>
            <div className="row center-column">
                {<CircleIcon icon={icon} />}
                <h3 className="orange">{label}</h3>
            </div>
            <div className={'checkbox ' + (value ? 'checked' : '')}>
                <CheckIcon />
            </div>
        </button>
    )
}
