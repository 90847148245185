import React, { useEffect, useRef, forwardRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';

const CustomEditInput = forwardRef((props, ref) => {
    const internalRef = useRef();
    const [focused, setFocused] = useState(false);
    const [editing, setEditing] = useState(false);

    // Combine internal ref with external ref (if provided)
    const combinedRef = ref || internalRef;

    useEffect(() => {
        if (props.shouldFocus && combinedRef.current) {
            combinedRef.current.focus();
        }
    }, [props.value, props.shouldFocus, combinedRef]);

    return (
        <div className='row center small-gap' style={{ position: 'relative' }}
            onClick={() => {
                if (props.onClick) props.onClick();
                combinedRef.current.focus();
                setFocused(true);
            }}>
            {props?.label &&
                <label className={
                    (props.value || focused || props?.type === 'number' || props?.type === 'date' || props?.beforeInput)
                        ? 'customEditInput_label customEditInput_label_active'
                        : 'customEditInput_label'
                }>{props?.label}</label>
            }
            {props?.beforeInput && <div className='beforeInput'>{props?.beforeInput}</div>}
            <input
                {...props}
                onFocus={(e) => {
                    if (props?.onFocus) props?.onFocus(e);
                    setFocused(true);
                }}
                ref={combinedRef}
                className={props.className}
                type={props?.type || "text"}
                onChange={props.onChange}
                value={formatString(props?.value, props?.inputPattern)}
                placeholder={props?.label ? null : props?.placeholder}
                onClick={props?.onClick}
                onBlur={(e) => {
                    setFocused(false);
                    if (props.onBlur) props?.onBlur(e);
                    setEditing(false);
                }}
                
            />
            {!editing && !focused && <button type='button' onClick={
                () => {
                    setEditing(true)
                    setTimeout(() => {
                        combinedRef.current.focus();
                    }, 100);
                    setFocused(true);
                }}>
                <EditIcon
                    className='orange'
                />
            </button>
            }
            {props?.suggestions &&
                <div className="suggestions">
                    {props?.suggestions.map((suggestion, index) => {
                        return (
                            <div className='row suggestion' key={index} onClick={() => {
                                props?.onSuggestion(suggestion);
                            }}>
                                <h3>{suggestion?.navn || suggestion?.name}</h3>
                                <h3>{suggestion?.organisasjonsnummer || suggestion?.orgNumber}</h3>
                                <h3>{suggestion?.forretningsadresse?.adresse[0] || suggestion?.businessAddress}</h3>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
});

export default CustomEditInput;

function formatString(stringUntempered, pattern) {
    if (!stringUntempered) return '';
    if (!pattern) return stringUntempered;
    if (pattern.length === 0) return stringUntempered;

    // Define the pattern of spaces
    const stringMax = pattern.reduce((acc, curr) => acc + curr, 0);
    const stringTakenAwayExcess = stringUntempered.replace(/\s/g, '').substr(0, stringMax);
    const str = stringTakenAwayExcess;
    let formatted = '';
    let index = 0;

    for (let len of pattern) {
        // Slice the next part of the string and add a space
        formatted += str.substr(index, len) + ' ';
        index += len;
    }

    // Trim any excess space and return
    return formatted.trim();
}
