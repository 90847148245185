import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { createId, getProjects } from "../../../firebase";
import CustomInput from "../../../components/miniComponents/customInput";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { formatedDate } from "../../../components/timetracking/timelisteCard";
import CustomModal from "../../../components/miniComponents/CustomModal";
import { SwapHorizRounded, SwitchLeft } from "@mui/icons-material";


export default function HMSArea({ skjema, setSkjema, onChange }) {
    const [manualMode, setManualMode] = useState(skjema?.sted?.manual || false);
    const [manualAddress, setManualAddress] = useState(
        skjema?.sted?.id ? skjema?.sted :
            {
                id: createId(),
                addresse: "",
                navn: "",
                by: "",
                postnummer: "",
            });
    const [prosjekter, setProsjekter] = useState([])
    const [projectModal, setProjectModal] = useState(false);

    useEffect(() => {
        //getProjects
        const onUpdate = (data) => {
            console.log(data);
            setProsjekter(data);
        }
        getProjects(onUpdate);
    }, [])

    useEffect(() => {
        if (manualMode) {
            console.log("setting manual address")
            setSkjema({ ...skjema, sted: {...manualAddress, manual:manualMode} });
        }
    }, [manualAddress])


    return (
        <div className="column">
            <div className="row flexApart">
                <h2>Sted</h2>
                <button type="button" onClick={() => setManualMode(!manualMode)}>{manualMode ? "Velg fra prosjekter" : "Manuell inntasting"} <SwapHorizRounded /></button>
            </div>

            {manualMode ?
                (
                    <div className="column small-gap">
                        <CustomInput type="text" label={"Addresse"} value={manualAddress.addresse} onChange={(e) => setManualAddress({ ...manualAddress, addresse: e.target.value })} />
                        <div className="row">
                            <CustomInput type="text" label={"Postnummer"} value={manualAddress.postnummer} onChange={(e) => setManualAddress({ ...manualAddress, postnummer: e.target.value })} />
                            <CustomInput type="text" label={"By"} value={manualAddress.by} onChange={(e) => setManualAddress({ ...manualAddress, by: e.target.value })} />
                        </div>
                    </div>
                ) :
                (
                    <div className="button-list">
                        <ButtonListButton onClick={() => setProjectModal(true)} title={skjema.sted.navn || "Ingen prosjekt valgt"} description={skjema.sted.nummer} />
                    </div>
                )
            }


            <CustomModal visible={projectModal} items={prosjekter} onChoise={(e) => {
                setSkjema({ ...skjema, sted: e, prosjekt: e });
                setProjectModal(false);
            }} onClose={() => setProjectModal(false)} />
        </div>
    )
}


export function HMSProject({ skjema, setSkjema, onChange }) {
    const [manualMode, setManualMode] = useState(skjema?.prosjekt?.manual || false);
    const [manualProject, setManualProject] = useState(
        skjema?.prosjekt?.id ? skjema?.prosjekt :
            {
                id: createId(),
                nummer: "",
                navn: "",
            });
    const [prosjekter, setProsjekter] = useState([])
    const [projectModal, setProjectModal] = useState(false);

    useEffect(() => {
        //getProjects
        const onUpdate = (data) => {
            console.log(data);
            setProsjekter(data);
        }
        getProjects(onUpdate);
    }, [])

    useEffect(() => {
        if (manualMode) {
            setSkjema({ ...skjema, prosjekt: { ...manualProject, manual: true } });
        }
        if (onChange) {
            onChange(manualProject);
        }
    }, [manualProject])


    return (
        <div className="column">
            <div className="row flexApart center-column">
                <h2>Prosjekt</h2>
                <button type="button" onClick={
                    () => {
                        setManualMode(!manualMode)
                        setSkjema({ ...skjema, prosjekt: { ...skjema?.prosjekt, manual: !manualMode } });
                    }
                }>{manualMode ? "Velg fra prosjekter" : "Manuell inntasting"} <SwapHorizRounded /></button>
            </div>

            {manualMode ? (
                <div className="column small-gap">
                    <div className="row small-gap">
                        <CustomInput type="text" label={"Prosjektnavn"} value={manualProject.navn} onChange={(e) => setManualProject({ ...manualProject, navn: e.target.value })} />
                        <CustomInput type="text" label={"Prosjektnummer"} value={manualProject.nummer} onChange={(e) => setManualProject({ ...manualProject, nummer: e.target.value })} />
                    </div>
                </div>
            ) : (
                <div className="button-list">
                    <ButtonListButton onClick={() => setProjectModal(true)} title={skjema?.prosjekt?.navn || "Ingen prosjekt valgt"} description={skjema?.prosjekt?.nummer} />
                </div>
            )}


            <CustomModal visible={projectModal} items={prosjekter} onChoise={(e) => {
                if (onChange) onChange(e);
                if (setSkjema) setSkjema({ ...skjema, prosjekt: { ...e } });
                setProjectModal(false);
            }} onClose={() => setProjectModal(false)} />
        </div>
    )
}