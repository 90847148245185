import React, { PureComponent, useContext, useEffect, useRef, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts';
import { createDateOutOfFirebaseTimestamp, getMonth } from '../../pages/timeføring/timeføring';
import { ref } from 'firebase/storage';
import { TimeContextCreation } from '../../App';
import DateRangeSelector from '../dateSelector';

const calculateHours = (datesArray) => {
    let totalHours = 0;
    datesArray.forEach(date => {
        const start = createDateOutOfFirebaseTimestamp(date.startDate);
        const end = createDateOutOfFirebaseTimestamp(date.endDate);
        const hours = Math.ceil((end - start) / 1000 / 60 / 60);
        totalHours += hours;
    });

    return totalHours;
};

class CustomBar extends PureComponent {
    render() {
        const { fill, x, y, width, height } = this.props;

        // Set the maximum radius of the rounded corners
        const maxRadius = 10;

        // Make sure radius doesn't exceed half of the bar's height
        const radius = Math.min(height / 2, maxRadius);

        return (
            <path
                d={`M${x},${y + radius} 
              a ${radius} ${radius} 0 0 1 ${radius} -${radius} 
              h ${width - 2 * radius} 
              a ${radius} ${radius} 0 0 1 ${radius} ${radius} 
              v ${height - radius} 
              h ${-width} z`}
                style={{ fill }}
            />
        );
    }
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip center-width center-height column time-card">
                <div className="label column">
                    <p>{`Dag: ${label}`}</p>
                    <p>{`Timer: ${payload[0].value}`}</p>
                </div>
            </div>
        );
    }

    return null;
};

const BarChartExport = ({ arrayOfDays, startDate, endDate }) => {
    const [data, setData] = useState([]);
    const refTest = useRef(null);
    const { timeContext, setTimeContext } = useContext(TimeContextCreation);
    const filterId = `inset-shadow-${Math.floor(Math.random() * 1000)}`;

    useEffect(() => {
        const newData = arrayOfDays.map(day => {
            const hours = calculateHours(day.dates);
            return {
                day: day.title,
                Timer: hours
            };
        });

        setData([...newData].reverse());
    }, [arrayOfDays]);

    const [chartWidth, setChartWidth] = useState(0);


    useEffect(() => {
        setChartWidth(calculateWidth());
    }, [refTest.current]);

    const calculateWidth = () => {
        const minWidthOfBar = 20;

        // It can scroll, but if it is a small amount of bars, they should stretch to fill the width
        if (data.length * minWidthOfBar < refTest.current.clientWidth) {
            return refTest.current.clientWidth;
        }

        return data.length * minWidthOfBar;
    };

    useEffect(() => {
        setTimeContext({
            //Any Time it updates Dager, it will update the context
            ...timeContext,
            dager: arrayOfDays,
        })
    }, [arrayOfDays])

    console.log(startDate, endDate);

    return (
        <div ref={refTest} className="stretch-width time-card" style={{
            overflow: "scroll",
        }}>
            {/*<div className="row flexApart" style={{
                position: "sticky",
                left: 0,
            }}
            >
                <h2>{`${getMonth(startDate)}, ${startDate.getDate(startDate)}, ${startDate.getFullYear()}`}</h2>
                <h2>-</h2>
                <h2>{`${getMonth(endDate)}, ${endDate.getDate(endDate)}, ${endDate.getFullYear()}`}</h2>
        </div>*/}

            <BarChart
                width={chartWidth - 40}//refTest.current ? (refTest.current.clientWidth - 40) : 500}
                height={250}
                data={data}
                margin={{
                    top: 20, right: 0, left: 0, bottom: 50,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke="#999999ad" />
                <XAxis dataKey="day" stroke="#fff" angle={-75} textAnchor="end" tick={{ fontSize: 12 }} />


                <Tooltip content={<CustomTooltip />} />
                <Bar shape={<CustomBar />} dataKey="Timer" fill="#FBB106">
                    <LabelList dataKey="Timer" position="top" fill="#fff" />
                </Bar>
            </BarChart>

        </div>
    );
};

export default BarChartExport;
