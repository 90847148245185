import { useHistory } from "react-router";
import { Document as PdfDocument, Page as PdfPage } from 'react-pdf/dist/esm/entry.webpack';
import { IonPage } from "@ionic/react";
import React from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { useTranslation } from "react-i18next";


export default function PDFViewer() {
    const { t } = useTranslation();
    const history = useHistory();
    let report = history.location.state;
    const pdfUrl = report?.pdfURL || report?.filLink;
    console.log(report);
    console.log(history);

    const [numPages, setNumPages] = React.useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    if (!pdfUrl) {
        return (
            <DefaultWrapper>
                <div className="preview">
                    <p>No PDF to display</p>
                </div>
            </DefaultWrapper>
        );
    }

    return (
        <DefaultWrapper extraButton={(<a href={pdfUrl} download="Report.pdf" style={{ marginBottom: '20px', display: 'block' }}>
                    {t('Download PDF', 'Last ned PDF')}
                </a>)}>
            <div className="preview">
                {pdfUrl ? (
                    <PdfDocument
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        style={{ width: '100%', height: 'calc(100vh - 50px)', overflow: 'scroll' }}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <PdfPage key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </PdfDocument>
                ) : (
                    <p>Loading PDF...</p>
                )}
            </div>
        </DefaultWrapper>
    );
}