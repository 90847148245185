import { useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { useHistory, useParams } from "react-router";
import CustomInput from "../../components/miniComponents/customInput";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import _, { set } from 'lodash'


import { ReactComponent as TrashIcon } from '../../assets/icons/mdi_delete.svg';
import CircleIcon from "../../components/miniComponents/circleIcon";
import { createId, getCompany, getCompanyId, getRoles, getUser, kickUser, sendInvitation } from "../../firebase";
import { useTranslation } from "react-i18next";
import { IonAlert } from "@ionic/react";
import { PersonRemove } from "@mui/icons-material";
import { getCompanyUser, updateCompanyUser, updateCompanyUserRoles } from "../../database/users";

export default function BrukerInstillinger(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();

    const [user, setUser] = useState(
        {
            navn: "",
            epost: "",
            roles: [],
            orgRole: "",
        });

    const [prevUser, setPrevUser] = useState(user);

    const [deleteAlert, setDeleteAlert] = useState(false);


    /*const rolleOptions = [
        { value: 'admin', label: 'Admin', id: 1 },
        { value: 'utvikler', label: 'Utvikler', id: 2 },
        { value: 'bruker', label: 'Bruker', id: 3 },
    ];*/
    const [rolleOptions, setRolleOptions] = useState([]);

    useEffect(() => {
        //Get company roles
        const onUpdate = (roles) => {
            const data = roles

            console.log(data);
            setRolleOptions(data);
        }

        const getRolesFromDB = async () => {

            const roles = await getRoles(onUpdate)
            console.log(roles);
            if (user?.roles?.length == 0) setUser({
                ...user,
                roles: [roles[0]]
            });
        }
        getRolesFromDB();
    }, []);


    useEffect(() => {
        //Set User from history if it exists
        /*console.log(history.location.state?.bruker);
        if (history.location.state?.bruker) {
            //Combine the state.bruker with the user
            setUser(old => {
                return { ...user, ...history.location.state.bruker }
            });
        }*/

        //Get user from company, id is in params
        if (params.brukerId === "nyBruker") return;
        const onUpdate = (user) => {
            console.log(user);
            setUser(user);
        }

        const getUserFromCompany = async () => {
            console.log(params)
            const user = await getCompanyUser(params.brukerId, onUpdate);
            console.log(user);
            setUser(old => {
                return { ...old, ...user }
            });
        }
        getUserFromCompany();

    }, []);

    const NavnOnChange = (e) => {
        setUser({ ...user, navn: e.target.value });
    }

    const emailOnChange = (e) => {
        setUser({ ...user, epost: e.target.value });
    }

    const orgRoleOnChange = (e) => {
        setUser({ ...user, orgRole: e.target.value });
    }

    const onRoleChange = (value, key) => {
        //Changes the dropdowns selected role
        console.log(value);

        if (value === null || value === undefined) {
            return;
        }
        setUser((old) => {
            let newRoles = { ...old };  // create a copy
            newRoles.roles = [...newRoles.roles]; // copy the array
            newRoles.roles[key] = value;
            console.log(newRoles.roles);
            return newRoles;
        });
    }

    async function updateRolesIfChangesToRoles() {
        //Updates the roles if they have changed;
        console.log("updateRolesIfChangesToRoles");
        console.log(user.id, params.brukerId)
        if (params.brukerId === "nyBruker") return
        if (user.id === undefined) return
        if (!user.id) return
        console.log(user)
        await updateCompanyUserRoles(user);
    }

    useEffect(() => {
        console.log("user.roles", user.roles);
        updateRolesIfChangesToRoles();
    }, [user.roles]);

    return (
        <DefaultWrapper lagring={params.brukerId !== "nyBruker"} onBack={
            async () => {
                await updateCompanyUser(user);
            }
        } extraButton={
            <button className="button button-primary" onClick={() => {
                setDeleteAlert(true);
            }}>{t("Kick user", "Kast ut bruker")} <PersonRemove /></button>
        }>
            <IonAlert
                isOpen={deleteAlert}
                header={t("Kick user", "Kast ut bruker")}
                message={t("Are you sure you want to kick this user?", "Er du sikker på at du vil kaste ut denne brukeren?")}
                //trigger="present-alert"
                buttons={[
                    {
                        text: t("Cancel", "Avbryt"),
                        role: 'cancel',
                        handler: () => {
                            console.log('Alert canceled');
                            setDeleteAlert(false);
                        },
                    },
                    {
                        text: t("Ok", "OK"),
                        role: 'confirm',
                        handler: async () => {
                            await kickUser(user)
                            setDeleteAlert(false);
                            console.log('Alert confirmed');
                            history.goBack();
                        },
                    },
                ]}
                onDidPresent={() => console.log('did present')}
                onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
            ></IonAlert>

            <div className="content-ny button-list">
                <div className="button-list-button column top-left">
                    <h3 className="orange">{t("Name", "Navn")}</h3>
                    <CustomInput onChange={NavnOnChange} value={user.navn} placeholder={"Jon Arne"}></CustomInput>
                </div>

                <div className="button-list-button column top-left">
                    <h3 className="orange">{t("Email", "Epost")}</h3>
                    <CustomInput onChange={emailOnChange} value={user.epost} type={"email"} placeholder={"Jon@gmail.com"}></CustomInput>
                </div>

                <div className="button-list-button column top-left">
                    <h3 className="orange">{t("Organization rank", "Organisajons-stilling")}</h3>
                    <CustomInput onChange={orgRoleOnChange} value={user.orgRole} placeholder={"Utvikler"}></CustomInput>
                </div>

                <div className="button-list-button column top-left small-gap">
                    <div className="row flexApart stretch-width">
                        <h3 className="orange">{t("Roles", "Roller")}</h3>
                        <button className="button button-primary" onClick={() => setUser(() => {
                            return {
                                ...user, roles: [...user?.roles, { value: 'bruker', label: 'Bruker', id: 3 }]
                            }
                        })
                        }
                        >{t("Add", "Legg Til")}</button>
                    </div>
                    <div className="column small-gap stretch-width">
                        {user?.roles?.map((role, key) => {
                            console.log(role);
                            return (
                                <div className="row">
                                    <CustomDropdown options={rolleOptions} defaultValue={role} selected={role} onChange={(e) => onRoleChange(e, key)} key={key}></CustomDropdown>
                                    <button onClick={() => {
                                        console.log(key);
                                        setUser((old) => {
                                            const newRoles = [...old.roles];
                                            newRoles.splice(key, 1);
                                            return { ...old, roles: newRoles };
                                        });
                                    }}>
                                        <CircleIcon icon={TrashIcon}></CircleIcon>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {params?.brukerId === "nyBruker" &&
                    <div className="flexApart stretch-width">
                        <div></div>
                        <button className="button button-primary" onClick={async () => {
                            //Send invitation to user
                            const id = createId();
                            sendInvitation({
                                navn: user.navn,
                                epost: user.epost,
                                orgRole: user.orgRole,
                                roles: user.roles,
                                id: createId(),
                            },
                                await getCompany(getCompanyId())
                            );

                            history.push("/hjem");

                        }}>{t("Send Invitation", "Send Invitasjon")}</button>
                    </div>
                }


            </div>

        </DefaultWrapper>
    )
}