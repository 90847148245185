import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import TimeChooser from "../../components/timetracking/timeChooser";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { createId, getProjects } from "../../firebase";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import CustomModal from "../../components/miniComponents/CustomModal";
import ButtonListButton from "../../components/miniComponents/buttonListButton";
import PeopleAndRolesSearchWithPopup from "../../components/searchWithPopups/peopleAndRolesSearch";
import UtstyrSearchWithPopup from "../../components/searchWithPopups/utstyrSearch";
import SJAline from "./utils/SJA-line";
import PeopleSearchWithPopup from "../../components/searchWithPopups/peopleSearch";
import FullscreenModal from "../../components/fullscreenModal";
import Canvas from "../../components/canvas";
import HMSArea, { HMSProject } from "./utils/area";
import ButtonListCheckbox from "../../components/miniComponents/buttonListCheckbox";
import { Add, CheckRounded, Delete, DrawRounded } from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import ModalButton from "../../components/utils/ModalButton";
import CustomerModal from "../../components/modals/CustomerModal";
import CustomerModalButton from "../../components/modals/CustomerModalButton";
import { CompanyContext } from "../../contexts/companyContext";
import { useSingleFetchEditable } from "../../components/utils/hooks/fetchSingleEditable";
import { deleteHMSReport, getHMSReport, setHMSReport } from "../../database/hms";
import { useSingleFetchEditableRS } from "../../components/utils/hooks/RSFetchSingleEditable";
import { addHMSReport, removeHMSReport, updateHMSReport } from "../../stores/hmsSlice";
import { useSelector } from "react-redux";
import DeleteButton from "../../components/miniComponents/deleteButton";


export default function SJA() {
    const params = useParams();
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [signperson, setSignperson] = useState({});
    const user = useSelector((state) => state.user);

    const { companyDetails } = useSelector((state) => state.company);
    const {
        document,
        isLoading,
        setDocument,
        updateData,
        isChanged,
        save
    } = useSingleFetchEditableRS({
        selector: (state) => state.HMS.sja.reports,
        path: 'HMS/sja/reports',
        saveFunction: setHMSReport,
        params: {
            id: params?.id,
            type: "sja",
        },
        autoUpdate: true,
        //setFunction: setHMSByType,
        updateFunction: updateHMSReport,
        addFunction: addHMSReport,
        removeFunction: removeHMSReport,
        key: "document",
        payload: { type: "sja", id: params.id },
        id: params?.id,
        filters: [
            { field: "originalCreator.uid", operator: "==", value: user.user.uid },
            { field: "status", operator: "!=", value: "sent" },
        ]
    })

    useEffect(() => {
        if (!user) return;
        if (!document) return;
        if (document?.originalCreator) return;
        setDocument({ ...document, originalCreator: user.user });
    }, [user, document]);

    useEffect(() => {
        if (!document) return
        if (!document.companyDetails?.logo) return
        setDocument({ ...document, logo: companyDetails?.logo });
    }, [companyDetails?.logo, document]);

    const navigateToNewPage = (path, state) => {
        history.push(path, state);
    }

    if (!document) return null;
    return (
        <DefaultWrapper extraButton={
            <button onClick={async () => {
                deleteHMSReport({ id: document.id, type: "sja" });
                navigateToNewPage("/HMS", { tab: "SJA" });
            }}>Slett SJA <Delete /></button>
        } lagring={true} onBack={save} limitWidth={true}>
            <div className="content-ny column limit-width">
                <div className="column small-gap">
                    <h2>Aktivitet</h2>
                    <CustomInput type="text" label="Beskrivelse av aktiviteten" value={document.aktivitet} onChange={(e) => setDocument({ ...document, aktivitet: e.target.value })} />
                </div>
                <HMSArea skjema={document} setSkjema={setDocument} />
                <HMSProject skjema={document} setSkjema={setDocument} />

                <div className="column small-gap">
                    <h2>Dato</h2>
                    <CustomInput noIcon={true} type="date" value={
                        document.dato
                    } onChange={(e) => setDocument({ ...document, dato: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Deltagere</h2>
                    <PeopleSearchWithPopup label="Deltagere" onChange={(e) => setDocument({ ...document, deltagere: e })} />
                </div>

                <div className="column">
                    <h2>SJA gjennomføres fordi (flervalg)</h2>
                    <div className="column-on-phone row small-gap">
                        <div className="button-list">
                            <ButtonListCheckbox type="checkbox" title="Arbeidet medfører avvik fra beskrivelser i prosedyrer og planer" checked={
                                document?.årsaker?.some(årsak => årsak.id === "1")
                            } onClick={
                                () => {
                                    if (document?.årsaker?.some(årsak => årsak.id === "1")) {
                                        setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "1") })
                                    } else {
                                        setDocument({
                                            ...document, årsaker: [...document.årsaker, {
                                                title: "Arbeidet medfører avvik fra beskrivelser i prosedyrer og planer",
                                                id: "1"
                                            }]
                                        })
                                    }
                                }
                            } />

                            <ButtonListCheckbox type="checkbox" title="Aktiviteten er ny og ukjent"
                                checked={
                                    document?.årsaker?.some(årsak => årsak.id === "2")
                                } onClick={
                                    () => {
                                        if (document?.årsaker?.some(årsak => årsak.id === "2")) {
                                            setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "2") })
                                        } else {
                                            setDocument({
                                                ...document, årsaker: [...document.årsaker, {
                                                    title: "Aktiviteten er ny og ukjent",
                                                    id: "2"
                                                }]
                                            })
                                        }
                                    }
                                } />

                            <ButtonListCheckbox type="checkbox" title="Folk som ikke kjenner hverandre skal jobbe sammen"
                                checked={
                                    document?.årsaker?.some(årsak => årsak.id === "3")
                                } onClick={
                                    () => {
                                        if (document?.årsaker?.some(årsak => årsak.id === "3")) {
                                            setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "3") })
                                        } else {
                                            setDocument({
                                                ...document, årsaker: [...document.årsaker, {
                                                    title: "Folk som ikke kjenner hverandre skal jobbe sammen",
                                                    id: "3"
                                                }]
                                            })
                                        }
                                    }
                                } />

                        </div>
                        <div className="button-list">
                            <ButtonListCheckbox type="checkbox" title="Utstyr som arbeidstakerne ikke har erfaring med skal benyttes"
                                checked={
                                    document?.årsaker?.some(årsak => årsak.id === "4")
                                } onClick={
                                    () => {
                                        if (document?.årsaker?.some(årsak => årsak.id === "4")) {
                                            setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "4") })
                                        } else {
                                            setDocument({
                                                ...document, årsaker: [...document.årsaker, {
                                                    title: "Utstyr som arbeidstakerne ikke har erfaring med skal benyttes",
                                                    id: "4"
                                                }]
                                            })
                                        }
                                    }
                                } />

                            <ButtonListCheckbox type="checkbox" title="Forutsetninger er endret"
                                checked={
                                    document?.årsaker?.some(årsak => årsak.id === "5")
                                } onClick={
                                    () => {
                                        if (document?.årsaker?.some(årsak => årsak.id === "5")) {
                                            setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "5") })
                                        } else {
                                            setDocument({
                                                ...document, årsaker: [...document.årsaker, {
                                                    title: "Forutsetninger er endret",
                                                    id: "5"
                                                }]
                                            })
                                        }
                                    }
                                } />

                            <ButtonListCheckbox type="checkbox" title="Ulykker / Uønskede hendelser har skjedd tidligere ved tilsvarende aktiviteter"
                                checked={
                                    document?.årsaker?.some(årsak => årsak.id === "6")
                                } onClick={
                                    () => {
                                        if (document?.årsaker?.some(årsak => årsak.id === "6")) {
                                            setDocument({ ...document, årsaker: document?.årsaker?.filter(årsak => årsak.id !== "6") })
                                        } else {
                                            setDocument({
                                                ...document, årsaker: [...document.årsaker, {
                                                    title: "Ulykker / Uønskede hendelser har skjedd tidligere ved tilsvarende aktiviteter",
                                                    id: "6"
                                                }]
                                            })
                                        }
                                    }
                                } />

                        </div>
                    </div>
                </div>

                <div className="column">
                    <div className="row flexApart">
                        <h2>Oppgaver</h2>
                        <button onClick={() => {
                            setDocument(old => {
                                const newLines = [...old?.lines] || [];
                                newLines.push({ beskrivelse: "Ny Oppgave", farer: "", tiltak: "", kontroll: "", ansvarlige: [], id: createId() });
                                return { ...old, lines: newLines };
                            })
                        }}>Legg til oppgave</button>
                    </div>
                    <div className="column small-gap">
                        {document?.lines && document?.lines?.map((line) => {
                            return (<SJAline line={line} skjema={document} setSkjema={setDocument} />)
                        }
                        )}
                    </div>
                </div>

                <div className="column small-gap">
                    <h2>Sikkerhetsutstyr</h2>
                    <UtstyrSearchWithPopup onChange={(e) => setDocument({ ...document, utstyr: e })} />
                    {/*Velg fra Utstyr*/}
                </div>

                <div className="column small-gap">
                    <div className="row flexApart">
                        <h2>Send e-post til:</h2>
                        <div className="row">
                            <button onClick={() => {
                                setDocument({ ...document, emails: document.deltagere.map((deltager) => ({ email: deltager.email, id: createId() })) });
                            }}>Legg til Alle Deltagere <Add /></button>
                            <button onClick={() => {
                                setDocument({ ...document, emails: [...document.emails, { email: "", id: createId() }] });
                            }}>Legg til <Add /></button>
                        </div>
                    </div>
                    {document.emails.map((e, i) => {
                        return (
                            <div key={e.id} className="row center">
                                <CustomInput type="text" value={e.email} label="E-post"
                                    onChange={(e) => {
                                        let temp = [...document.emails];
                                        temp[i] = { ...temp[i], email: e.target.value };
                                        setDocument({ ...document, emails: temp });
                                    }} />
                                <DeleteButton onClick={() => {
                                    let temp = [...document.emails];
                                    temp.splice(i, 1);
                                    setDocument({ ...document, emails: temp });
                                }}></DeleteButton>
                            </div>
                        )
                    })
                    }
                </div>

                <div className="column">
                    <h2>Signaturer</h2>
                    {document?.deltagere && document?.deltagere?.map((person, i) => {
                        return (
                            <div className="column small-gap">
                                <div className="row small-gap flexApart center-column">
                                    <h3>{person.name}</h3>
                                    {person.signatur ? (
                                        <button className="good">Signert <CheckRounded /></button>) : (
                                        <button onClick={() => {
                                            setSignperson(person);
                                            setVisible(true);
                                        }}
                                        >Signer <DrawRounded /></button>
                                    )}

                                </div>
                                {i !== document.deltagere.length - 1 && <div className="divider" />}
                            </div>
                        )
                    })}
                    <div className="divider" />
                    <h2>Ansvarlige</h2>

                    <ModalButton label="SJA Ansvarlig" adaptToChoise={true} title="SJA Ansvarlig"
                        items={document?.deltagere} onChange={(e) => setDocument({ ...document, ansvarlig: e })} />

                    <CustomerModalButton label="Firma Ansvarlig" adaptToChoise={true} title="Firma Ansvarlig" onChange={(e) => setDocument({ ...document, firmaAnsvarlig: e })} />
                    <div className="divider" />
                    <div className="row flexApart">
                        <div />
                        {
                            document?.deltagere &&
                                document?.deltagere?.length > 0 &&
                                document?.deltagere?.every((person) => person.signatur) &&
                                document?.ansvarlig && document?.firmaAnsvarlig
                                ? (
                                    <button onClick={() => {
                                        const nyttSkjema = { ...document, signatur: "signert" }
                                        setDocument(nyttSkjema);
                                        navigateToNewPage("/hms/sja/pdf", nyttSkjema);
                                    }}>Se Over</button>
                                ) : null
                        }
                    </div>


                    <FullscreenModal open={visible} onClose={() => setVisible(false)}>
                        <Canvas onExport={(link) => {
                            console.log(link);
                            setDocument(old => {
                                const newDeltagere = old.deltagere.map((deltager) => {
                                    let personCopy = { ...deltager };
                                    if (deltager.id === signperson.id) {
                                        personCopy.signatur = link;
                                    }
                                    return personCopy;
                                })
                                return { ...old, deltagere: newDeltagere }
                            })
                            setVisible(false);
                        }} reset={signperson} />
                    </FullscreenModal>
                </div>
            </div>
        </DefaultWrapper>
    )
}