import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CircleIcon from './circleIcon';
import { checkRolesForPermission } from '../utils';
import { useDrag } from 'react-use-gesture';
import { Avatar } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';



export default function ButtonListButtonWDelete(props) {
    const history = useHistory();
    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);

    function navigateToNewPage(path, info) {
        history.push({
            pathname: path,
            state: info
        });
    }

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props?.permissions !== undefined && !checkRolesForPermission(companyUser?.permissions, props?.permissions)) return setShow(false);
        setShow(true);
    }, [companyUser?.permissions])

    const bind = useDrag(({ down, movement: [mx, my], first, last }) => {
        if (first) {
            // Drag started
            props.onDragStart?.(props.item, mx, my);
        }

        if (down) {
            // Dragging
            props.onDragMove?.(props.item, mx, my);
        }

        if (last) {
            // Drag ended
            props.onDragEnd?.(props.item, mx, my);
        }
    });

    if (!show || props?.moduleDisabled) return null;

    return (
        <div className='row'>
            <button type={"button"}
                onClick={(e) => {
                    e.preventDefault();
                    if (props?.disabled) return;
                    if (props?.onPress) props?.onPress(e);
                    if (props?.onClick) props?.onClick(e);
                    if (props?.navigateTo) navigateToNewPage(props.navigateTo, props?.navigateInfo);
                }}
                className={'button-list-button rounded' +
                    (props?.notOnPhone ? 'not-on-phone' : '') +
                    (props?.disabled ? 'disabled' : '') +
                    (props?.className ? props.className : '')
                }
            >
                {props?.avatar && <props.avatar />}
                {!props?.avatar && <CircleIcon icon={props.icon}></CircleIcon>}
                {props?.image && <img src={props.image} alt=''></img>}


                <div className={'column small-gap stretch-width ' + (props.centerText ? "center" : "")}>
                    {props?.title && <h2 className={'orange '}>{props.title}</h2>}
                    {props?.description && <p>{props.description}</p>}
                    {props?.children && props.children}
                </div>
                <div className={'column small-gap'}>
                    {props.draggable &&
                        <div ref={props.dragRef} className="drag-handle" style={{
                            cursor: 'grab',
                        }} {...bind()}> {/* or some icon to indicate draggability */}
                            ☰
                        </div>
                    }
                </div>
            </button>

            <button type='button' onClick={() => {
                    if (props?.onDelete) props?.onDelete();
                }
                }>
                    <CircleIcon icon={Delete} className='red'></CircleIcon>
                </button>
        </div>
    );
}
