import { useEffect, useRef, useState } from "react";
import { IonContent, IonPage, LocationHistory } from "@ionic/react";
import { useHistory } from "react-router";
import { setClientFirebase } from "../../firebase";
import CustomInput from "../../components/miniComponents/customInput";
import DefaultWrapper from "../../components/defaultWrapper";
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";

export default function CreateClient(props) {
    const { t } = useTranslation();
    const history = useHistory();
    console.log(history.location.state);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const [client, setClient] = useState(history?.location?.state || {
        navn: "",
        addresse: "",
        postnummer: "",
        by: "",
        orgNummer: "",
        arbeidsplass: "",
        kontaktpersoner: [{ navn: "", email: "" }],
        id: randomId(),
    });

    useEffect(() => {
        if (history.location.state) {
            setClient(history.location.state);
        }
    }, [history.location.state]);

    const addClient = async () => {
        console.log("Add client");

        //Check if each field is filled out, not an empty string
        //If not, show error message
        //If yes, create client

        /*for (let key in client) {
            if (client[key] === "") {
                console.warn("Error: " + key + " er tomt");
                return;
            }
        }*/
        console.log(client);
        await setClientFirebase(client);
        history.goBack({
            state: client
        });
    }

    useEffect(() => {
        const newClient = history?.location?.state || {
            navn: "",
            addresse: "",
            postnummer: "",
            by: "",
            orgNummer: "",
            arbeidsplass: "",
            kontaktpersoner: [{ navn: "", email: "" }],
            id: randomId(),
        };
        setClient(newClient);
    }, [history.location.state]);


    useEffect(() => {
        //Remove spaces from orgnr
        const orgnr = client?.orgNummer?.replace(/\s/g, '');
        if (!orgnr) return;
        if (orgnr.length !== 9) return;

        // Querying the Enhetsregisteret API
        /*const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
        const response = await axios.get(apiUrl);*/
        // Use fetch instead
        const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
        const apiUrlRoller = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}/roller`;


        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                // If the orgnr is valid, set the client's name to the name of the company
                // If the orgnr is invalid, set the client's name to an empty string
                // If the orgnr is empty, set the client's name to an empty string

                // If the orgnr is valid, set the client's address to the address of the company
                // If the orgnr is invalid, set the client's address to an empty string
                // If the orgnr is empty, set the client's address to an empty string

                // If the orgnr is valid, set the client's postal code to the postal code of the company
                // If the orgnr is invalid, set the client's postal code to an empty string
                // If the orgnr is empty, set the client's postal code to an empty string
                async function fetchPlaces(query) {
                    try {

                        const response = await fetch(`http://localhost:3001/fetchPlaces?input=${query}`);
                        const data = await response.json();
                        return data.candidates[0];  // get the first result
                    } catch (error) {
                        console.error('Error fetching from server:', error);
                        return null;
                    }

                }

                console.log(data)
                async function setData() {
                    let temp = { ...client, lat: null, lng: null };
                    console.log(client);
                    if (!data || data.status === 400 || data.status === 403) return;

                    if (client.navn === "") {
                        temp.navn = data?.navn;
                    }
                    if (client.addresse === "") {

                        temp.addresse = data?.forretningsadresse?.adresse[0]

                        async function fetchPlacesAsync() {
                            const place = await fetchPlaces(data?.forretningsadresse?.adresse[0]);
                            console.log(place);
                            return place;
                        }

                        const addressWithLangAndLat = await fetchPlacesAsync()

                        /*temp.lat = addressWithLangAndLat?.geometry.lat;
                        temp.lng = addressWithLangAndLat?.geometry.lng;*/
                        // add lat and lng to temp
                        console.log(addressWithLangAndLat);
                        temp.lat = addressWithLangAndLat?.geometry.location.lat || null;
                        temp.lng = addressWithLangAndLat?.geometry.location.lng || null;
                        console.log(temp);

                    }
                    if (client.postnummer === "") {
                        temp.postnummer = data?.forretningsadresse?.postnummer
                    }
                    if (client.by === "") {
                        temp.by = data?.forretningsadresse.poststed
                    }
                    console.log(temp);
                    console.log(client);
                    setClient(old => {
                        console.log({ ...old, ...temp });
                        return { ...old, ...temp }
                    }
                    );
                }
                setData();
            });




    }, [client.orgNummer]);

    return (
        <DefaultWrapper>
            <div className="content-ny">
                <div className="column big-gap">
                    <div className="column wrap">
                        <h1 className="white-text">{t("New Customer", "Ny kunde")}</h1>
                        <h3>{t("Name", "Navn")}</h3>
                        <CustomInput type="text" placeholder={t("Company", "Firma") + " / " + t("Name", "Navn")}
                         value={client.navn}
                            onChange={(e) => { setClient({ ...client, navn: e.target.value }); }}
                        />
                        <h3>{t("Organization number", "Organisasjonsnummer")}</h3>
                        <CustomInput type="text" placeholder={t("Organization number", "Organisasjonsnummer")} value={client.orgNummer}
                            onChange={(e) => { setClient({ ...client, orgNummer: e.target.value }); }}
                        />
                        <h3>{t("Address", "Adresse")}</h3>
                        <SearchAddressesWithPopup lng={client?.lng} lat={client?.lat} value={client.addresse} onChange={(addresse) => {
                            console.log(addresse)
                            console.log(client)
                            setClient((old) => {
                                console.log(old)
                                return { ...old, addresse: addresse.addresse, postnummer: addresse.postalCode, by: addresse.city, lat: addresse.lat || null, lng: addresse.lng || null }
                            })
                        }} />
                    </div>

                    


                    <div>
                        <button onClick={() => addClient()}>{t("Save", "Lagre")}</button>
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    )
}

function randomId() {
    return Math.random().toString(36).substring(2);
}
