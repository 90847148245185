import { CircleNotificationsRounded, Language, LanguageRounded, LocationOn } from "@mui/icons-material";
import DefaultWrapper from "../components/defaultWrapper";
import ButtonListButton from "../components/miniComponents/buttonListButton";
import { useTranslation } from "react-i18next";





export default function Instillinger() {
    const { t } = useTranslation();


    return (
        <DefaultWrapper>
            <div className="content-ny">
                <h1>{t("Settings", "Innstillinger")}</h1>
                <div className="button-list">
                    {/*<ButtonListButton title={t("Geolocation", "Geolokasjon")} description={t("Activate geolocation", "Aktiver geolokasjon")} icon={LocationOn} navigateTo="/instillinger/geolokasjon"></ButtonListButton>*/}
                    <ButtonListButton title={t("Notifications", "Varslinger")} description={t("Activate notifications", "Aktiver varslinger")} icon={CircleNotificationsRounded}></ButtonListButton>
                    <ButtonListButton title={t("Language", "Språk")} description={t("Choose language", "Velg språk")} icon={LanguageRounded}></ButtonListButton>
                    <ButtonListButton title={t("General", "Generelt")} description={t("Choose Theme", "Velg tema")} icon={null} navigateTo="/PermissionHandler"></ButtonListButton>
                </div>
            </div>
        </DefaultWrapper>
    );
}