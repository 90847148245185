import DefaultWrapper from "../../../components/defaultWrapper";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { SaveFirmSettings, getFirmSettings } from "../../../firebase";
import { useEffect } from "react";
import ButtonListCheckbox from "../../../components/miniComponents/buttonListCheckbox";
import { useTranslation } from "react-i18next";
import ButtonListButtonWDelete from "../../../components/miniComponents/buttonListButtonWDelete";





export default function TimeforingInstillinger() {
    const { t } = useTranslation();
    const history = useHistory();

    const [settings, setSettings] = useState({
        aktiviteter: [],
        komponenter: {
            utstyr: false,
        },
    });

    const getSettings = async () => {
        let lastData = null;
        const onUpdate = (data) => {
            console.log(data);
            setSettings({ ...settings, ...data })
        }

        const fetchedSettings = await getFirmSettings("timeforingsInstillinger", onUpdate);
        console.log(settings);
        if (settings) setSettings({ ...settings, ...fetchedSettings });
    }


    useEffect(() => {
        getSettings();
    }, [])

    function navigateToNewPage(path, info) {
        history.push({
            pathname: path,
            state: info
        });
    }

    return (
        <DefaultWrapper>
            <div className="content-ny">
                <div className="column flexApart stretch-width">
                    <h1>{t("Modules", "Moduler")}</h1>
                    <div className="button-list">
                        <ButtonListCheckbox title={t("Equipment", "Utstyr")} checked={settings?.komponenter?.utstyr} onCheckboxChange={(e) => {
                            let settingsCopy = { ...settings };
                            settingsCopy.komponenter.utstyr = e;
                            console.log(settingsCopy)
                            SaveFirmSettings(settingsCopy, "timeforingsInstillinger");
                        }} description={t("Let time management have equipment added", "La timeføringen ha utstyr lagdt til")}></ButtonListCheckbox>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="column">
                    <h1>{t("Payment types", "Tilleggstyper")}</h1>

                    <div className="button-list">
                        <ButtonListButton title={t("Payment type", "Tilleggstyper")} navigateTo="/admin/instillinger/timeforing/tillegg" navigateInfo={{ settings }}></ButtonListButton>
                    </div>

                </div>
                <div className="divider"></div>
                <div className="row flexApart stretch-width">
                    <h1>{t("Activity types", "Aktivitets Typer")}</h1>
                    <button onClick={() => {
                        navigateToNewPage("/admin/instillinger/timeforing/aktivitet/ny", { settings });
                    }}>{t("Add activity", "Legg til aktivitet")}</button>
                </div>
                <div className="button-list">
                    {settings.aktiviteter.map((aktivitet, index) => {
                        return (
                            <ButtonListButtonWDelete
                                key={aktivitet.id}
                                title={aktivitet?.navn}
                                description={aktivitet?.beskrivelse}
                                icon={null}
                                navigateTo={"/admin/instillinger/timeforing/aktivitet/" + aktivitet?.id}
                                navigateInfo={{ aktivitet, settings }}
                                onDelete={() => {
                                    let settingsCopy = { ...settings };
                                    settingsCopy.aktiviteter.splice(index, 1);
                                    SaveFirmSettings(settingsCopy, "timeforingsInstillinger");
                                }}
                            />
                        )
                    }
                    )}
                </div>
            </div>
        </DefaultWrapper>
    )
}