import UtstyrCard from "../components/utstyrCard"
import { IonButton, IonContent, IonDatetime, IonFab, IonFabButton, IonHeader, IonIcon, IonModal, IonPage, IonTextarea, IonToolbar, LocationHistory } from "@ionic/react"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { searchObjects } from "../components/search"
//import { getUtstyr } from "../firebase/utstyr"
import { add, send, trash } from "ionicons/icons"
import { getUtstyr, getAuthUser, getAuthUserImage, getAuthUserName, setUtstyr as setUtstyrFirebase, deleteUtstyr } from "../firebase"
import DefaultWrapper from "../components/defaultWrapper"
import { Timestamp } from "firebase/firestore"
import CustomInput from "../components/miniComponents/customInput"
import TopBarChoosePage from "../components/miniComponents/choosePageTopBar"
import ManageUtstyr from "./manageUtstyr"

import { ReactComponent as DeleteIcon } from '../assets/icons/mdi_delete.svg';
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useTranslation } from "react-i18next"
//import { getAuthUser, getAuthUserImage, getAuthUserName } from "../auth"

export default function UtstyrsDetaljer(props) {
    const { t } = useTranslation()
    const location = useLocation()
    const information = location.state
    const history = useHistory()
    const [utstyr, setUtstyr] = useState(history.location.state)



    const DeleteButton = () => {
        // Delete the utstyr from firebase
        // Navigate back to the previous page
        // The previous page should be the page where the utstyr was selected from
        return (
            <button onClick={() => {
                deleteUtstyr(utstyr.id)
                history.goBack()
            }}>{t("Delete", "Slett")} <DeleteIcon /></button>)
    }


    if (!utstyr) return <DefaultWrapper />

    const UtstyrsDealjerComponent = () => {
        const location = useLocation()
        const information = location.state
        const history = useHistory()
        const [utstyr, setUtstyr] = useState(history.location.state)


        const [showModal, setShowModal] = useState(false)

        const [kommentarfelt, setKommentarfelt] = useState('')

        const [userName, setUserName] = useState(null)

        async function getUserFromAuth() {
            const data = await getAuthUserName();
            console.log("Username:", data); // Add this line
            setUserName(data);
            return data;
        }

        function formatDate(date) {
            if (date instanceof Timestamp) {
                date = date.toDate();
            } else {
                date = new Date(date);
            }
            if (isNaN(date)) {
                console.log("Invalid date encountered:", date); // Add this line
                return "Invalid Date";
            }
            return date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }

        useEffect(() => {
            //return () => unsubscribe()
            //setUser(getAuthUser())
            getUserFromAuth()
        }, [])

        //const [filteredUtstyr, setFilteredUtstyr] = useState([])
        //const [search, setSearch] = useState('')
        useEffect(() => {
            // On Utstyr change, update the utstyr in firebase
            console.log(utstyr)
            if (!utstyr) return
            setUtstyrFirebase(utstyr)
        }, [utstyr])

        if (!utstyr) return <div />

        return (
            <div className='content-ny column'>
                <h1>Info</h1>
                <div className='row flexApart'>
                    <h1>{utstyr.navn}</h1>
                    <h3>#{utstyr.utstyrsNummer}</h3>
                </div>
                <div className='row'>
                    <h3>Kjøpt</h3>
                    <h3>{formatDate(utstyr.kjøpt)}</h3>
                </div>
                <div className='row flexApart align-bottom'>
                    <h1>Service</h1>
                    <button className="button-round" color={"secondary"} onClick={() => {
                        // Create a new Modal with a new service announcement, with a date selecter and a text field
                        // When the modal is closed, add the new service announcement to the list of service announcements
                        // The new service announcement should be added to the database as well
                        setShowModal(true)
                    }}>
                        <IonIcon className="add-icon" icon={add} size='small' ></IonIcon>
                    </button>
                </div>
                <div className='white-margin orangeButton' style={{ width: '100%', height: 1, }}></div>
                <div style={{ minHeight: 200 }} className='column'>
                    {utstyr?.service && utstyr.service.map((service, index) => {
                        console.log(service)
                        return (
                            <div className='row flexApart service-card' key={"service" + index}>
                                <h3>{service.beskrivelse}</h3>
                                <h3>{formatDate(service.dato)}</h3>
                            </div>
                        )
                    })
                    }
                </div>
                <div className='column'>
                    <h1>Kommentarer</h1>
                    <div className="row center-column">
                        <CustomInput placeholder="Skriv kommentar her" onChange={
                            (e) => {
                                console.log(e.target.value)
                                setKommentarfelt(e.target.value)
                            }
                        } value={kommentarfelt} />

                        <button className="round-button" onClick={async (e) => {
                            let kommentarer = utstyr?.kommentarer
                            const kommentarObjekt = {
                                kommentar: kommentarfelt,
                                id: randomId(),
                                dato: new Date(),
                                bruker: {
                                    navn: await getUserFromAuth(),
                                    bilde: await getAuthUserImage() || ""
                                }
                            }
                            setUtstyr({
                                ...utstyr,
                                kommentarer: kommentarer ? [...utstyr.kommentarer, kommentarObjekt] : [kommentarObjekt]
                            })
                            setKommentarfelt('')
                        }}
                        >
                            <IonIcon className="add-icon" icon={send} size='small' ></IonIcon>
                        </button>
                    </div>


                </div>
                <div className='column'>
                    {utstyr.kommentarer && utstyr.kommentarer.map((kommentar) => {
                        return (
                            <div className="row" key={kommentar.id}>
                                <div className="column service-card">
                                    <p>{kommentar.kommentar}</p>
                                    <div className='row flexApart'>
                                        <h3>{formatDate(kommentar.dato)}</h3>
                                        <div className='row'>
                                            <h3>{kommentar?.bruker?.navn}</h3>
                                            {kommentar?.bruker?.bilde && <img className="profile-image" src={kommentar?.bruker?.bilde} alt="profile" />}
                                        </div>
                                    </div>
                                </div>
                                {
                                    kommentar.bruker.navn === userName &&
                                    <button onClick={() => {
                                        let kommentarer = utstyr.kommentarer.filter((k) => k.id !== kommentar.id)
                                        setUtstyr({
                                            ...utstyr,
                                            kommentarer: kommentarer
                                        })
                                    }}>
                                        <IonIcon className="add-icon" icon={trash} size='small' ></IonIcon>
                                    </button>
                                }
                            </div>
                        )
                    })}
                </div>

                <ServiceModal visible={showModal} onClose={(newService) => {
                    if (newService) {
                        let newUtstyr
                        if (utstyr?.service) {
                            console.log(utstyr.service)
                            newUtstyr = {
                                ...utstyr,
                                service: [...utstyr.service, newService]
                            }
                        } else {
                            newUtstyr = {
                                ...utstyr,
                                service: [newService]
                            }
                        }
                        console.log(newUtstyr)
                        setUtstyr(newUtstyr)
                    }
                    setShowModal(false)
                }} />
            </div>
        )
    }

    return (
        <DefaultWrapper lagring={true} extraButton={<DeleteButton />}>
            <div className='content-ny'>
                <TopBarChoosePage options={[{ name: "Utstyr", component: <UtstyrsDealjerComponent /> }, { name: "Detaljer", component: <ManageUtstyr /> }]} />
            </div>
        </DefaultWrapper>
    );
}

function randomId() {
    return Math.random().toString(36).substr(2, 9);
}


// Create a new Modal with a new service announcement, with a date selecter and a text field
// When the modal is closed, add the new service announcement to the list of service announcements
// The new service announcement should be added to the database as well

const ServiceModal = (props) => {
    const [service, setService] = useState(
        {
            id: randomId(),
            dato: new Date(),
            beskrivelse: ''
        }
    )

    const onClose = (save) => {
        if (save) {
            const newService = service
            props.onClose(newService)
            setService({
                id: randomId(),
                dato: new Date(),
                beskrivelse: ''
            })
        } else {
            props.onClose(null)
            setService({
                id: randomId(),
                dato: new Date(),
                beskrivelse: ''
            })
        }
    }


    return (
        <IonModal isOpen={props.visible}>
            <IonHeader>
                <IonToolbar>
                    <IonButton onClick={() => onClose(false)}>Avbryt</IonButton>
                    <IonButton slot="end" onClick={() => onClose(true)}>Lagre</IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="column center padding stretch-height">
                    <h1>Dato</h1>
                    <IonDatetime
                        className="fit-content"
                        displayFormat="DD.MM.YYYY"
                        value={service.dato.toISOString()}
                        onIonChange={(e) => setService(s => ({ ...s, dato: new Date(e.detail.value) }))}
                    />

                    <h1>Beskrivelse</h1>
                    <CustomInput
                        className="input"
                        value={service.beskrivelse}
                        onChange={(e) => setService(s => ({ ...s, beskrivelse: e.target.value }))}
                    />
                </div>
            </IonContent>
        </IonModal>
    )
}
