import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createNewHMSReport } from "../../../stores/hmsSlice";
import { useHistory } from "react-router";
import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react";
import { Add, FileCopyOutlined } from "@mui/icons-material";
import { createId } from "../../../firebase";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { transform } from "lodash";




export default function CreateNewHMSReportButton() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ionFabOpen, setIonFabOpen] = useState(false);
    const history = useHistory();


    const navigateToNewPage = (path, state) => {
        history.push(path, state)
    }

    const createNew = (type) => {
        const id = createId();
        dispatch(createNewHMSReport({ type, id }))
        console.log("Creating new", type, id)
        navigateToNewPage(`hms/${type}/${id}`, { id })
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const newButton = useRef(null);

    return (
        <>
            <button ref={newButton} id="create-new-button" className="bottom-right-button" onClick={() => {
                setIonFabOpen(!ionFabOpen)
                setAnchorEl(document.getElementById("create-new-button"))
            }}>
                Nytt Skjema <Add />
            </button>

            {/*ionFabOpen ?
                <IonFabList side="top" className="custom-ionfablist">
                    
                </IonFabList>
    : null*/}

            <Menu anchorEl={anchorEl}

                id="New-HMS-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                style={{ transform: "translateY(-10px)"}}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <button className="menu-button" onClick={() => { createNew("ruh"); setIonFabOpen(false) }}>
                    <p className="fit-content">{t("New RUH", "Nytt RUH Skjema")}</p>
                    <FileCopyOutlined />
                </button>

                <button className="menu-button" onClick={() => { createNew("avvik"); setIonFabOpen(false) }}>
                    <p className="fit-content">{t("New deviation", "Nytt Avvik")}</p>
                    <FileCopyOutlined />
                </button>

                <button className="menu-button" onClick={() => { createNew("sja"); setIonFabOpen(false) }}>
                    <p className="fit-content">{t("New SJA", "Nytt SJA")}</p>
                    <FileCopyOutlined />
                </button>
            </Menu>
        </>
    )
}