
// Create the context
import React, { createContext, useContext, useEffect, useState } from "react";
import { getCompanyDetails, getFirmSettings, getThisCompany } from "../firebase";
import { getCompanyUser } from "../database/users";
import { UserContext } from "../App";
import { useSelector } from "react-redux";

// Create the context
export const CompanyContext = createContext();

// Create the provider component
export const CompanyProvider = ({ children }) => {
  const user = useSelector(state => state.user.user);
  const companyUser = useSelector(state => state.user.companyUser);
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [companyLoading, setCompanyLoading] = useState(true);
  const [moduleSettings, setModuleSettings] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    // Function to update companyId when localStorage changes
    const updateCompanyIdState = () => {
      setCompanyId(localStorage.getItem("companyId"));
    };

    // Listen for changes within the same tab using a custom event
    window.addEventListener("companyIdChange", updateCompanyIdState);

    // Listen for changes across different tabs/windows
    window.addEventListener("storage", (event) => {
      if (event.key === "companyId") {
        setCompanyId(event.newValue);
      }
    });

    // Initial state sync in case of changes
    updateCompanyIdState();

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("companyIdChange", updateCompanyIdState);
    };
  }, []);

  useEffect(() => {
    if (!companyId) return
    const onUpdate = (e) => {
      setCompany(e);
    }
    const fetchCompany = async () => {
      const company = await getThisCompany(onUpdate);
      setCompany(company);
      setCompanyLoading(false);
    };
    fetchCompany();
  }, [companyId]);

  useEffect(() => {
    if (!companyId) return console.log("No company id");
    const onUpdate = (e) => {
      setModuleSettings(e);
    }
    const getSettings = async () => {
      await getFirmSettings("moduleSettings", onUpdate);
    }
    getSettings();
  }, [companyId]);


  async function getCompany() {
    if (!companyId) return console.log("No company id");
    console.log("getting company details")
    const deails = await getCompanyDetails();
    setCompanyDetails(deails);
  }

  useEffect(() => {
    console.log("getting company details")
    console.log(companyId)
    getCompany();
  }, [companyId]);

  useEffect(() => {
    const getNewCUser = async () => {
      console.log("getting company user")
      if (!companyId) return console.log("No company id");
      const cUser = await getCompanyUser(userContext.user);
      setUserContext(old => {
        return { ...old, companyUser: cUser }
      });
    }
    getNewCUser();
  }, [companyId]);

  return (
    <CompanyContext.Provider value={{ companyId, companyLoading, moduleSettings, companyDetails }}>
      {children}
    </CompanyContext.Provider>
  );
};


export const setCompanyId = (newCompanyId) => {
  localStorage.setItem("companyId", newCompanyId);

  // Dispatch a custom event to notify any listeners
  const event = new CustomEvent("companyIdChange", { detail: newCompanyId });
  window.dispatchEvent(event);
};
