import BarChart from "../../components/miniComponents/barChart";

import { useContext, useEffect, useRef, useState } from "react";
import { TimeChoiseModal, createDateOutOfFirebaseTimestamp, getMonth } from "./timeføring";
import { createId, getAuthUser, getAuthUserName, getRecordedTimeFromDBByDate } from "../../firebase";
import CalendarChart from "../../components/miniComponents/calendarOverview";
import ExportTimeToPdf from "../../components/exportTimeToPdf";
import { ReactComponent as CalenderIcon } from '../../assets/icons/mdi_calendar.svg';

import exportTimeToPdf from "../../components/exportTimeToPdf";

import DateRangeSelector from "../../components/dateSelector";
import { TimeContextCreation } from "../../App";

import { Doughnut } from 'react-chartjs-2';

import { Chart, ArcElement, DoughnutController, Tooltip, Legend } from 'chart.js';
import { getAllHoursOfMonth } from "./førte-timer";
import { ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getCompanyUser } from "../../database/users";
import TimetrackingDoughnut from "./utils/doughnut";

// Register the components
Chart.register(ArcElement, DoughnutController, Tooltip, Legend);


const COLORS = [
    '#A155B9',
    '#1DDD8D',
    '#F765A3',
    '#16BFD6',
    '#165BAA',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];


export default function TimeOverview() {
    const { t } = useTranslation();
    const [previousRecordings, setPreviousRecordings] = useState([])
    const [arrayOfPreviousRecordings, setArrayOfPreviousRecordings] = useState([])
    const [timeChoiseModalInfo, setTimeChoiseModalInfo] = useState({
        open: false,
        startDate: localStorage.getItem("startDate") ? new Date(localStorage.getItem("startDate")) : new Date(),
        endDate: localStorage.getItem("endDate") ? new Date(localStorage.getItem("endDate")) : new Date(),
        title: "",
    })
    const { timeContext, setTimeContext } = useContext(TimeContextCreation);
    const pageContainer = useRef(null);

    useEffect(() => {
        console.log(timeChoiseModalInfo);
        if (timeChoiseModalInfo.startDate !== localStorage.getItem("startDate")) {
            localStorage.setItem("startDate", timeChoiseModalInfo.startDate)
        }
        if (timeChoiseModalInfo.endDate !== localStorage.getItem("endDate")) {
            localStorage.setItem("endDate", timeChoiseModalInfo.endDate)
        }


    }, [timeChoiseModalInfo])

    useEffect(() => {
        const onUpdate = (data) => {
            //console.log(data);  // Add this
            setPreviousRecordings(data)
        }

        getRecordedTimeFromDBByDate(onUpdate, timeChoiseModalInfo.startDate, timeChoiseModalInfo.endDate)
    }, [timeChoiseModalInfo])

    useEffect(() => {
        const groupedByDay = previousRecordings.reduce((acc, current) => {
            if (!current.endDate) return acc;
            if (current.startDate) {
                const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

                if (!isNaN(date)) {
                    const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                    if (!acc.has(day)) {
                        acc.set(day, []);
                    }

                    acc.get(day).push(current);
                }
            }

            return acc;
        }, new Map());

        for (let [_, recordings] of groupedByDay) {
            recordings.sort((a, b) => {
                if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
                    return b.endDate.toDate() - a.endDate.toDate();
                } else {
                    return 0;
                }
            });
        }

        const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse().map(([date, recordings]) => {
            const [year, month, day] = date.split('-');
            const title = `${day}. ${getMonth(parseInt(month) - 1, t).slice(0, 3)} ${year}`; // change the title to the desired format

            return {
                title,
                dates: recordings,
            };
        });

        setArrayOfPreviousRecordings(arrayOfPreviousRecordings);
    }, [previousRecordings]);

    const options = {
        layout: {
            padding: {
                right: 0, // Or whatever value needed to achieve the desired effect
            },
            autoPadding: false,
        },
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start',

                labels: {
                    align: 'start',
                    color: '#FFFFFF', // Set text color
                    font: {
                        size: 10, // Set font size
                        family: 'Arial', // Set font family
                    },
                    boxWidth: 20,

                }
            }

        },
        cutout: '70%',
        // Add responsive: true to make the chart adjust to its container
        responsive: true,
        maintainAspectRatio: false // Set to false to allow custom dimensions
    };

    const splitIntoTilleggsType = (times) => {
        const result = {};
        console.log(times);
        times.forEach(time => {
            if (!result[time?.tilleg?.navn || time?.tilleg?.name]) {
                result[time?.tilleg?.navn || time?.tilleg?.name] = [];
            }
            result[time?.tilleg?.navn || time?.tilleg?.name].push(time);
        });
        return result;
    };


    const getDoughnutData = (times) => {
        if (!times || times.length === 0) return {
            labels: [],
            datasets: [
                {
                    label: 'Time per Tilleg',
                    data: [],
                    backgroundColor: COLORS,
                    hoverBackgroundColor: ['#FFFFFF'],
                    labelColor: '#FFFFFF',
                    //borderColor: '#faebd700',
                    borderWidth: 0, // Increase the borderWidth to create spacing
                    color: '#FFFFFF',
                    borderRadius: 0,
                    //spacing: 2,

                }
            ]
        };
        const tilleggsType = splitIntoTilleggsType(times);
        let labels = Object.keys(tilleggsType);
        const data = Object.values(tilleggsType).map(tilleg => {
            let totalSeconds = 0;
            tilleg.forEach(time => {
                if (!time.endDate || !time.startDate) return;
                const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
                const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
                totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
            });

            let totalHours = totalSeconds / 3600; // Convert total seconds to hours

            // If total hours are 100 or more, round to nearest whole number
            // Otherwise, round to nearest quarter hour and replace dot with comma for decimals
            return totalHours >= 100 ? Math.round(totalHours) : (Math.floor(totalHours * 2) / 2).toString();
        });

        // Append hour count to each label

        
        labels = labels.map((label, index) => `${String(data[index])?.replace('.', ',')} t - ${label}`)
        


        console.log(labels);
        console.log(data)

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Time per Tilleg',
                    data: data,
                    backgroundColor: COLORS,
                    hoverBackgroundColor: ['#FFFFFF'],
                    labelColor: '#FFFFFF',
                    //borderColor: '#faebd700',
                    borderWidth: 0, // Increase the borderWidth to create spacing
                    color: '#FFFFFF',
                    borderRadius: 0,
                    //spacing: 2,

                }
            ]
        };
    };

    const [doughnutData, setDoughnutData] = useState(getDoughnutData(previousRecordings));

    useEffect(() => {
        setDoughnutData(getDoughnutData(previousRecordings));
        console.log(doughnutData);
    }, [previousRecordings]);



    return (
        <div className="column content-ny margin-bottom-content" ref={pageContainer}>
            <TimeChoiseModal t={t} open={timeChoiseModalInfo.open} start={timeChoiseModalInfo.startDate} end={timeChoiseModalInfo.endDate} title={timeChoiseModalInfo.title} onDone={
                (updatedStart, updatedEnd) => {
                    setTimeChoiseModalInfo({
                        ...timeChoiseModalInfo,
                        startDate: updatedStart,
                        endDate: updatedEnd,
                        open: false
                    })
                    /*if (updatedStart && updatedEnd) {
                        let timeCopy = { ...timeChoiseModalInfo }
                        console.log(timeCopy);
                        timeCopy.startDate = updatedStart
                        timeCopy.endDate = updatedEnd
                        uploadTimeToDb(timeCopy)
                    }*/
                }
            }
                onClose={() => setTimeChoiseModalInfo({ ...timeChoiseModalInfo, open: false })}
            />



            <div className="column small-gap">
                <div className="row small-gap flexApart">
                    <button style={{ padding: 10 }} className={"row " + (pageContainer.current?.clientWidth > 375 ? "small-gap" : "noGap")}
                        onClick={() => {
                            console.log(pageContainer.current?.clientWidth);
                            setTimeChoiseModalInfo({ ...timeChoiseModalInfo, open: true })
                        }}>

                        <p style={{ fontSize: 10 }}>{timeChoiseModalInfo.startDate.toLocaleDateString()}</p>
                        <ArrowRight width={10} height={10} />
                        <p style={{ fontSize: 10 }}>{timeChoiseModalInfo.endDate.toLocaleDateString()}</p>
                        <CalenderIcon width={10} height={10} />
                    </button>
                    <DateRangeSelector startDate={timeChoiseModalInfo.startDate} endDate={timeChoiseModalInfo.endDate}
                        onChange={({ startDate, endDate }) => {
                            setTimeChoiseModalInfo({
                                ...timeChoiseModalInfo,
                                startDate,
                                endDate,
                            })
                        }} />
                </div>
                <TimetrackingDoughnut times={previousRecordings} />
                
                <BarChart arrayOfDays={arrayOfPreviousRecordings} startDate={timeChoiseModalInfo.startDate} endDate={timeChoiseModalInfo.endDate} />

                {/*<CalendarChart
                    fromDate={new Date(2023, 7, 1)}
                    endDate={new Date(2023, 7, 7)}
                    dates={sampleData}
        />*/}
                <div className="row flexApart">
                    {//<div></div>
                    }
                    <button onClick={async () => {
                        const user = await getAuthUser();
                        const userName = await getCompanyUser(user)
                        exportTimeToPdf({ people: [{ navn: userName.navn, id: createId(), dager: timeContext.dager }], fromTime: timeChoiseModalInfo.startDate, endTime: timeChoiseModalInfo.endDate })
                    }
                    } >{t("Export To PDF", "Eksporter til PDF")}</button>
                </div>
            </div>

        </div >
    )
}

export const calculateHoursOfTimes = (times) => {
    let hours = 0;
    times.forEach(time => {
        if (time.endDate && time.startDate) {
            const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
            const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
            if (!isNaN(startDate) && !isNaN(endDate)) {
                const timeDiff = endDate - startDate;
                const hoursDiff = timeDiff / 1000 / 60 / 60;
                hours += hoursDiff;
            }
        }
    });
    console.log(hours);

    // If Over 100 hours, round to nearest whole number
    if (hours >= 100) {
        return Math.round(hours);
    }

    // If over 10 hours, round to nearest 0.25
    if (hours >= 10) {
        return Math.round(hours * 4) / 4;
    }

    return Math.round(hours * 10) / 10;
};