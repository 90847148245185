import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { acceptInvitation, getAuthUserId } from "../../firebase";
import { AlertContext, UserContext } from "../../App";
import { useTranslation } from "react-i18next";
import DefaultWrapper from "../../components/defaultWrapper";
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { IonSpinner } from "@ionic/react";
import { a } from "react-spring";
import { setCompanyId } from "../../contexts/companyContext";
import { useSelector } from "react-redux";




export default function Invitation(props) {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const [countDown, setCountDown] = useState(5);
    const [accepted, setAccepted] = useState(false);
    const history = useHistory();
    const { alertcontext, setAlertContext } = useContext(AlertContext);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        async function acceptSentInvitation() {
            try {
                const query = new URLSearchParams(window.location.search);
                const invitationId = query.get('invitationId');

                const test2 = window.location.href.split("invitationId=")[1];
                let invId = invitationId || test2;

                if (!user && invId) {
                    const userId = localStorage.getItem('userId');
                    console.log(userId)
                    if (!userId || userId === 'undefined') {
                        return navigateToNewPage("/login", { invId: invId });
                    }
                }

                if (!invId) return

                const accept = await acceptInvitation(user?.uid, { id: invId })

                if (accept?.data?.success) {
                    setCompanyId(accept.data.companyId)
                    
                    navigateToNewPage("/hjem");
                    setAccepted(true);
                }

            } catch (error) {
                console.error(error)
                setAlertContext({
                    text: <div className="column">
                        <h3>{t("Could not accept the invitation", "Kunne ikke akseptere invitasjonen")}</h3>
                        <div className="row"> <p>{t("Error", "Feil")}: </p> <p>{error?.message || error}</p>
                        </div>
                    </div>,
                    onConfirm: async () => {
                        history.push("/company")
                    },
                    onConfirmText: "Ok"
                })
            }
        }
        acceptSentInvitation()
    }, []);


    return (
        <DefaultWrapper noHeader={true} noBackButton={true}>
            <div className="content-ny column center">
                <h1>{t("Trying to accept the invitation", "Prøver å akseptere invitasjonen")}</h1>
                <IonSpinner style={{ width: 200, height: 200 }} name="crescent"></IonSpinner>
            </div>
        </DefaultWrapper>
    )
}