




// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    companyId: null,
    company: null,
    companyDetails: null,
    moduleSettings: null,
};



const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompanyDetails: (state, action) => {
            state.companyDetails = action.payload;
            localStorage.setItem('companyDetails', action.payload);
        },
        setCompany: (state, action) => {
            state.company = action.payload;
            localStorage.setItem('company', action.payload);
        },
        setModuleSettings: (state, action) => {
            state.moduleSettings = action.payload;
            localStorage.setItem('moduleSettings', action.payload);
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload;
            console.log(action.payload)
            localStorage.setItem('companyId', action.payload);
        },
        setCId: (state, action) => {
            state.companyId = action.payload;
            console.log(action.payload)
            localStorage.setItem('companyId', action.payload);
        },
        resetCompany: (state) => {
            state.company = null;
            state.companyDetails = null;
            state.moduleSettings = null;
            state.companyId = null
        },
    },
});

export const { setCompanyDetails, setCompany, setModuleSettings, setCompanyId, setCId, resetCompany } = companySlice.actions
export default companySlice.reducer;
