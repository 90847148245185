import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useCustomFetch } from "../../../components/utils/hooks/fetchHook";
import LoadingWhileEmpty from "../../../components/miniComponents/loadingWhileEmpty";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { useContext } from "react";
import { getHMSReports } from "../../../database/hms";
import useCollectionSubscription from "../../../components/utils/hooks/firebaseCollectionSubscriber";
import { addHMSReport, removeHMSReport, setHMSByType, updateHMSReport } from "../../../stores/hmsSlice";
import useCollectionSubscriptionRS from "../../../components/utils/hooks/RSfirebaseCollectionSubscriber";
import { createNorwegianDate } from "../../timeføring/timeføring";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../Klienter/clientList";
import { FileOpen } from "@mui/icons-material";
import { CollSub } from "../../../components/utils/hooks/sub/fetchCollection";





export default function RUHOverview() {
    const { t } = useTranslation();
    //const { avvik, isLoading } = useCustomFetch(getHMSReports, { type: "Avvik" }, "avvik");
    const user = useSelector((state) => state.user);
    console.log(user)
    const { ruh, isLoading } = CollSub({
        selector: (state) => state.HMS.ruh.reports,
        path: 'HMS/ruh/reports',
        setFunction: setHMSByType,
        updateFunction: updateHMSReport,
        addFunction: addHMSReport,
        removeFunction: removeHMSReport,
        key: "ruh",
        payload: { type: "ruh" },
        // Only the once created by the user
        filters: [
            { field: "originalCreator.uid", operator: "==", value: user.user.uid },
            { field: "status", operator: "!=", value: "sent" },
        ]
    });
    const history = useHistory();

    const navigateToNewPage = (path, information) => {
        history.push({
            pathname: path,
            state: information
        });
    }

    return (
        <div className="content-ny">
            <LoadingWhileEmpty loading={isLoading}>
                {ruh?.map((avvik) => {
                    return (
                        <ButtonListButton key={avvik.id} onClick={() => {
                            navigateToNewPage("/HMS/ruh/" + avvik.id, { avvik: avvik })
                        }}
                            icon={FileOpen}
                            avatar={avvik?.prosjekt ? () => {return <Avatar {...stringAvatar(avvik?.prosjekt?.navn || "")}/>} : null}
                        >
                            <div className="column">
                                <div className="row flexApart stretch-width">
                                    <h2 className="orange">{avvik?.prosjekt?.navn || "Ingen prosjekt valgt"}</h2>
                                    <h2>{createNorwegianDate(new Date(avvik.dato))}</h2>
                                </div>
                                <div className="row flexApart">
                                    <p>{avvik?.sted?.addresse}</p>

                                </div>
                            </div>
                        </ButtonListButton>
                    )
                })}
            </LoadingWhileEmpty>
        </div>
    )
}