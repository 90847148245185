
import React, { useState, useRef, useEffect, useContext } from 'react';
import { setReportFirebase, getNotSentReports, getReportNumber, getSentReports, setReportNumber, getMals, getReportsThatNeedsReview, getAuthUserId, getFirmSettings } from '../../firebase';
import RapportCard from '../../components/rapport-card';
import { IonContent, IonHeader, IonLabel, IonModal, IonPage, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { getAuthUser, getAuthUserName } from '../../firebase';
import { CheckIfOnPC, ReportContextCreation, UserContext } from '../../App';

import { searchObjects, sortObjects } from '../../components/search';
import DefaultWrapper, { Søkefelt } from '../../components/defaultWrapper';
import BigButton from '../../components/bigButton';
import ButtonListButton from '../../components/miniComponents/buttonListButton';
import { animated, useTrail } from '@react-spring/web';
import { checkRolesForPermission } from '../../components/utils';
import LoadingWhileEmpty from '../../components/miniComponents/loadingWhileEmpty';
import { createDateOutOfFirebaseTimestamp, getDayInMonth, getDayName, getMonth } from '../timeføring/timeføring';
import TopBarChoosePage from '../../components/miniComponents/choosePageTopBar';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/miniComponents/CustomModal';
import { getCompanyUsers } from '../../database/users';
import { Add } from '@mui/icons-material';
import { useFilter } from '../../components/utils/hooks/filterHook';
import CustomDropdown from '../../components/miniComponents/customDropdown';
import Dropdown from '../../components/modals/buttons/dropdownButton';
import { useSelector } from 'react-redux';



// Needs update: Probably put them into a topbar, make them either view Usendte or Må bekreftes.

export default function Rapporter(props) {
    const { t } = useTranslation();
    const [notSent, setNotSent] = useState([]);
    const [sent, setSent] = useState([]);
    const history = useHistory();
    const [gottenReports, setGottenReports] = useState(false)
    //const [filteredRaports, setFilteredRaports] = useState([]);
    //items, filter = {}, key
    const [strictValue, setStrictValue] = useState({ name: t("All", "Alle"), value: "*", id: "*" });
    const { filteredRaports, searchString, setSearchString } = useFilter(notSent, { strict: { key: "brukerId", value: strictValue.value } }, "filteredRaports");

    const [people, setPeople] = useState([]); // [user1, user2, user3
    const dropDownPeople = [
        ...people.map((person) => {
            return { name: person.navn, value: person.id, icon: person.bilde, id: person.id }
        }),
        { name: t("All", "Alle"), value: "*", icon: null, id: "*" }
    ]

    console.log(filteredRaports)
    //Firebase displayName
    const [filter, setFilter] = useState("");

    const [needsReviewReports, setNeedsReviewReports] = useState([]);
    const [filteredNeedsReviewReports, setFilteredNeedsReviewReports] = useState([]);

    const [maler, setMaler] = useState([]);
    const [malModalShow, setMalModalShow] = useState(false);

    const { reportContext, setReportContext } = useContext(ReportContextCreation);

    const [segment, setSegment] = useState("usendte");

    const user = useSelector(state => state.user.user);
    const companyUser = useSelector(state => state.user.companyUser);

    useEffect(() => {
        const onUpdate = (querySnapshot) => {
            /*const malList = [];
            querySnapshot.forEach((doc) => {
                const malData = doc.data();
                malList.push(malData);
            });*/
            setMaler(querySnapshot);
        };

        getMals(onUpdate);
    }, []);

    useEffect(() => {
        getCompanyUsers(null, (querySnapshot) => {
            setPeople(querySnapshot);
        });
    }, []);


    // Start of component render
    useEffect(() => {
        if (filter === "" && !checkRolesForPermission(
            companyUser?.permissions,
            ["redigereRapporter"]
        )) setFilter(companyUser?.navn);
    }, [filter]);

    useEffect(() => {
        getUnsentReports();
    }, []);

    useEffect(() => {
        const onUpdate = (querySnapshot) => {
            setNeedsReviewReports(querySnapshot);
        };
        if (!checkRolesForPermission(companyUser?.permissions, ["sendeRapporter"])) return;
        getReportsThatNeedsReview(onUpdate)
    }, []);





    async function createReportFirebase(mal) {
        console.log("Create report");
        //console.log(await getAuthUserName());
        const id = await getReportNumber()
        const settings = getFirmSettings("rapportInstillinger")

        console.log(id.number)
        console.log(mal)
        setReportContext({
            firma: {
                navn: "",
                arbeidsplass: "",
                by: "",
                postnummer: "",
                kontaktpersoner: [],
                kontaktperson: "",
                projekt: "",
                projekter: [],
            },
            id: id.number,
            oppgaver: [],
            annet: "",
            utstyr: [],
            mal: mal,
            bruker: await getAuthUserName(),
            brukerId: await getAuthUserId(),
            dato: new Date(),
            filer: [],
            lastUpdated: new Date(),
            status: settings?.defaultStatus || null,
        })

        //navigateToNewPage('rapport', raport)
        //await setReportFirebase(raport)
        await setReportNumber(id.number + 1)
        navigateToNewPage(`rapport/${id.number}`, { oppgaveMal: mal })
    }

    const GoToReport = (rapport) => {
        // check local storage if it is more recent than firebase
        // if it is, use that one
        const localReport = JSON.parse(localStorage.getItem('localRapporter'))?.find((localReport) => localReport.id === rapport.id)
        console.log(localReport)
        console.log(rapport)
        console.log(localReport?.lastUpdated > (rapport?.lastUpdated || null))
        //filter((localReport) => localReport.id === rapport.id)
        let report = localReport?.lastUpdated > (rapport?.lastUpdated || null) ? localReport : rapport
        setReportContext(report)
        navigateToNewPage(`rapport/${report.id}`, report)
    }

    async function getUnsentReports() {
        const onUpdate = (querySnapshot) => {
            const reportList = [];
            querySnapshot.forEach((doc) => {
                const reportData = doc.data();
                reportList.push(reportData);
            });
            setNotSent(reportList)
        };

        const newNotSent = await getNotSentReports(onUpdate)
        setGottenReports(true)
        setNotSent(newNotSent)
    }


    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    //const nyRapport = () => {
    const trail = useTrail(filteredRaports.length, {
        from: { opacity: 0, transform: 'translate3d(-40px,-10px,0px)' },
        to: { opacity: 1, transform: 'translate3d(0px,0px,0px)' },
    })

    return (
        <DefaultWrapper header={true} noBackButton={CheckIfOnPC()} updateSearchString={setSearchString} søkefelt={true}>
            <div className='content-ny'>
                <button className="bottom-right-header-button-with-header" onClick={() => setMalModalShow(true)}>{t("New Report", "Ny Rapport")} <Add /> </button>
                {window.innerWidth < 1200 ? (
                    <TopBarChoosePage options={[{
                        name: t("Reports", "Rapporter"), component: (
                            <div className='rapportContainer column'>
                                <div className='flexApart'>
                                    <h1></h1>
                                    <div className='row align-bottom'>
                                        <Dropdown label={t("Filter by", "Filtrer etter")} items={dropDownPeople} value={strictValue} onChange={(e) => setStrictValue(e)} />
                                    </div>
                                </div>
                                <div className='column button-list'>
                                    <LoadingWhileEmpty buttonList={true} gottenInformation={gottenReports}>
                                        {trail.map((props, index) => {
                                            const dato = createDateOutOfFirebaseTimestamp(filteredRaports[index]?.dato)
                                            return <animated.div key={index} style={props}>


                                                <ButtonListButton icon={filteredRaports[index].mal.icon} onPress={() => GoToReport(filteredRaports[index])}>
                                                    <div style={{ width: '100%' }} className='stretch-width flexApart row'>
                                                        <div style={{ width: '100%' }} className='stretch-width column'>
                                                            <h2 className='orange'>{filteredRaports[index]?.firma?.navn || filteredRaports[index].mal.title}</h2>
                                                            <h3>{getMonth(dato, t)} {getDayName(dato, t)} {getDayInMonth(dato, t)}</h3>
                                                        </div>
                                                        <div style={{ width: '100%' }} className='column right-align'>

                                                            <h2>{"#" + filteredRaports[index].id}</h2>
                                                            {filteredRaports[index]?.status &&
                                                                <div className='status' style={{
                                                                    background: filteredRaports[index]?.status?.farge || 'grey',
                                                                }}>
                                                                    {filteredRaports[index]?.status && <p>{filteredRaports[index]?.status.navn}</p>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </ButtonListButton>
                                            </animated.div>
                                        })}
                                    </LoadingWhileEmpty>
                                </div>
                            </div>)
                    }, checkRolesForPermission(companyUser?.permissions, ["sendeRapporter"]) && {
                        name: t("Needs to be accepted", "Må bekreftes"), component: (
                            <div className='column'>
                                <div className='flexApart'>
                                    <h1>{t("Needs to be accepted", "Må bekreftes")}</h1>
                                    <Søkefelt updateFilteredItems={setFilteredNeedsReviewReports} itemsToFilter={needsReviewReports} sortBy={"id"} />
                                </div>
                                <div className='column small-gap'>
                                    <LoadingWhileEmpty buttonList={true}>
                                        {filteredNeedsReviewReports.map((report, index) => {
                                            if (report.score > 0) return <RapportCard icon={report.mal.icon} key={report.id} report={report} onPress={(raport) => GoToReport(report)} />
                                        }
                                        )}
                                    </LoadingWhileEmpty>
                                </div>
                            </div>
                        )
                    }]} />)

                    : (
                        <div className='row stretch-width' style={{ marginRight: 100 }}>
                            <div className='rapportContainer column stretch-width input-background padding radius '>
                                <div className='flexApart center'>
                                    <h1>{t("Reports", "Rapporter")}</h1>
                                </div>
                                <div className='column button-list rapportContainer'>
                                    <LoadingWhileEmpty buttonList={true} gottenInformation={gottenReports}>
                                        {trail.map((props, index) => {
                                            const dato = createDateOutOfFirebaseTimestamp(filteredRaports[index]?.dato)
                                            return <animated.div key={index} style={props}>


                                                <ButtonListButton icon={filteredRaports[index].mal.icon} onPress={() => GoToReport(filteredRaports[index])}>
                                                    <div style={{ width: '100%' }} className='stretch-width flexApart row'>
                                                        <div style={{ width: '100%' }} className='stretch-width column'>
                                                            <h2 className='orange'>{filteredRaports[index]?.firma?.navn || filteredRaports[index].mal.title}</h2>
                                                            <h3>{getMonth(dato, t)} {getDayName(dato, t)} {getDayInMonth(dato, t)}</h3>
                                                        </div>
                                                        <div style={{ width: '100%' }} className='column right-align'>

                                                            <h2>{"#" + filteredRaports[index].id}</h2>
                                                            {filteredRaports[index]?.status &&
                                                                <div className='status' style={{
                                                                    background: filteredRaports[index]?.status?.farge || 'grey',
                                                                }}>
                                                                    {filteredRaports[index]?.status && <p>{filteredRaports[index]?.status.navn}</p>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </ButtonListButton>
                                            </animated.div>
                                        })}
                                    </LoadingWhileEmpty>
                                </div>
                            </div>

                            {checkRolesForPermission(companyUser?.permissions, ["sendeRapporter"]) &&
                                <div className='column stretch-width input-background padding radius rapportContainer'>
                                    <div className='flexApart'>
                                        <h1>{t("Needs to be confirmed", "Må bekreftes")}</h1>
                                        <Søkefelt updateFilteredItems={setFilteredNeedsReviewReports} itemsToFilter={needsReviewReports} sortBy={"id"} />
                                    </div>
                                    <div className='column small-gap'>
                                        <LoadingWhileEmpty buttonList={true}>
                                            {filteredNeedsReviewReports.map((report, index) => {
                                                if (report.score > 0) return <RapportCard icon={report.mal.icon} key={report.id} report={report} onPress={(raport) => GoToReport(report)} />
                                            }
                                            )}
                                        </LoadingWhileEmpty>
                                    </div>
                                </div>
                            }
                        </div>)

                }
            </div>
            <CustomModal visible={malModalShow} items={
                maler
            } onChoise={(mal) => {

                console.log(mal)
                setReportContext({
                    firma: {
                        navn: "",
                        arbeidsplass: "",
                        by: "",
                        postnummer: "",
                        kontaktpersoner: [],
                        kontaktperson: "",
                        projekt: "",
                        projekter: [],
                    },
                    id: "",
                    oppgaver: [],
                    annet: "",
                    utstyr: [],
                    mal: mal,
                })
                createReportFirebase(mal)
                setMalModalShow(false)
                //createReportFirebase(maler[e])

            }} onClose={() => setMalModalShow(false)} onNew={
                () => {
                    setMalModalShow(false)
                    navigateToNewPage('reportSettings/nyMal')
                }
            } onDidDismiss={() => setMalModalShow(false)} />
            {/*<IonModal isOpen={malModalShow} onDidDismiss={() => setMalModalShow(false)}>
                <div className='content-ny'>
                    {maler.map((mal, index) => {
                        console.log(mal)
                        return <ButtonListButton key={index} icon={mal.icon} title={mal.title} onPress={() => {
                            /*setReportContext({
                                firma: {
                                    navn: "",
                                    arbeidsplass: "",
                                    by: "",
                                    postnummer: "",
                                    kontaktpersoner: [],
                                    kontaktperson: "",
                                    projekt: "",
                                    projekter: [],
                                },
                                id: "",
                                oppgaver: [],
                                annet: "",
                                utstyr: [],
                                mal: mal,
                            })*/
                           /*
                            createReportFirebase(mal)
                            setMalModalShow(false)
                        }} />
                    })}
                    <div className='row flexApart'>
                        <div></div>
                        <button className='ny' onClick={() => {
                            setMalModalShow(false)
                            navigateToNewPage('/reportSettings/nyMal')
                        }}>{t("New template", "Ny mal")}</button>
                    </div>
                </div>
            </IonModal>
            */}


        </DefaultWrapper >
    )
}



