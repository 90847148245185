import { createRef, useEffect, useState } from "react"

//icons
import { ReactComponent as EditIcon } from '../assets/icons/material-symbols_edit.svg';
import { ReactComponent as AddIcon } from '../assets/icons/material-symbols_add.svg';
import { ReactComponent as MinusIcon } from '../assets/icons/ic_round-minus.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/mdi_delete.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/eos-icons_admin.svg';



import { useHistory, useParams } from "react-router";
import CustomInput from "../components/miniComponents/customInput";
import ButtonListCheckbox from "../components/miniComponents/buttonListCheckbox";
import DefaultWrapper from "../components/defaultWrapper";

import FontIconPicker from '@fonticonpicker/react-fonticonpicker';

import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

import * as iconDefs from '../components/iconDefs';
import { createId, setRole, updateAllUsersWithRole } from "../firebase";
import { useTranslation } from "react-i18next";


export default function RolleConfig() {
    const { t } = useTranslation()

    const DEFAULTROLLECONFIG = {
        navn: "",
        admin: false,
        icon: "fas fa-file-alt",
        id: createId(),
        permissions: [
            {
                tittel: t("Reports", "Rapporter"),
                regler: [
                    {
                        tittel: t("See Reports", "Se Rapporter"),
                        id: "seRapporter",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Send Reports", "Sende Rapporter"),
                        id: "sendeRapporter",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Create Reports", "Lage Rapporter"),
                        id: "lageRapporter",
                        icon: AddIcon
                    },
                    {
                        tittel: t("Delete Reports", "Slette Rapporter"),
                        id: "sletteRapporter",
                        icon: DeleteIcon
                    },
                    {
                        tittel: t("Edit Other Peoples Reports", "Redigere Andre Sine Rapporter"),
                        id: "redigereRapporter",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Create Templates", "Lage Maler"),
                        id: "lageMaler",
                        icon: AddIcon
                    },
                    {
                        tittel: t("Edit Templates", "Redigere Maler"),
                        id: "redigereMaler",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Delete Templates", "Slette Maler"),
                        id: "sletteMaler",
                        icon: DeleteIcon
                    },
                ]
            },
            {
                tittel: t("Employees", "Ansatte"),
                regler: [
                    {
                        tittel: t("Invite Employees", "Invitere Ansatte"),
                        id: "invitereBrukere",
                        allowed: false,
                        icon: AddIcon
                    },
                    {
                        tittel: t("Delete Employees", "Fjerne Ansatte"),
                        id: "fjerneBrukere",
                        allowed: false,
                        icon: DeleteIcon
                    },
                    {
                        tittel: t("Edit Employees", "Redigere Ansatte"),
                        id: "redigereBrukere",
                        allowed: false,
                        icon: EditIcon
                    },
                ]
            },
            /*{
                tittel: "kalkulatorer",
                regler: [
                    {
                        tittel: "Lage Kalkulatorer",
                        id: "lageKalkulatorer",
                        icon: AddIcon
                    },
                    {
                        tittel: "Redigere Kalkulatorer",
                        id: "redigereKalkulatorer",
                        icon: EditIcon
                    },
                    {
                        tittel: "Slette Kalkulatorer",
                        id: "sletteKalkulatorer",
                        icon: DeleteIcon
                    },
                ]
            },*/
            {
                tittel: t("Equipment", "Utstyr"),
                regler: [
                    {
                        tittel: t("See Equipment", "Se Utstyr"),
                        id: "seUtstyr",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Create Equipment", "Lage Utstyr"),
                        id: "lageUtstyr",
                        icon: AddIcon
                    },
                    {
                        tittel: t("Edit Equipment", "Redigere Utstyr"),
                        id: "redigereUtstyr",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Delete Equipment", "Slette Utstyr"),
                        id: "sletteUtstyr",
                        icon: DeleteIcon
                    },
                ]
            },
            {
                tittel: t("Time Management", "Timer"),
                regler: [
                    {
                        tittel: t("Change Dates", "Endre Datoer"),
                        id: "endreDatoer",
                        icon: EditIcon
                    },
                    {
                        tittel: t("See other peoples hours", "Se Andre Sine Timer"),
                        id: "seAndreSineTimer",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Edit other peoples Hours", "Redigere Andre Sine Timer"),
                        id: "redigereAndreSineTimer",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Delete other peoples hours", "Slette Andre Sine Timer"),
                        id: "sletteAndreSineTimer",
                        icon: DeleteIcon
                    },
                ]
            },
            {
                tittel: t("Roles", "Rolle"),
                regler: [
                    {
                        tittel: "Redigere Roller",
                        id: "redigereRoller",
                        icon: EditIcon
                    },
                ],
            },
            {
                tittel: t("Customer overview", "Kundeoversikt"),
                regler: [
                    {
                        tittel: t("Create Customer", "Lage kunder"),
                        id: "lageKunder",
                        icon: AddIcon
                    },
                    {
                        tittel: t("Customer overview", "Kundeoversikt"),
                        id: "kundeOversikt",
                        icon: EditIcon
                    },
                ],
            },
            {
                tittel: t("Projects", "Prosjekter"),
                regler: [
                    {
                        tittel: t("Create Projects", "Lage Prosjekter"),
                        id: "lageProsjekter",
                        icon: AddIcon
                    },
                    {
                        tittel: t("Edit Projects", "Redigere Prosjekter"),
                        id: "redigereProsjekter",
                        icon: EditIcon
                    },
                    {
                        tittel: t("Delete Projects", "Slette Prosjekter"),
                        id: "sletteProsjekter",
                        icon: DeleteIcon
                    },
                ]
            }
    
        ]
    }
    
    const DEFAULTPermissions = {
        "navn": "Ny Rolle",
        "icon": "fas fa-file-alt",
        "id": createId(),
        "admin": false,
    
        "seRapporter": true,
        "lageRapporter": true,
        "redigereRapporter": false,
        "sendeRapporter": false,
        "lageMaler": false,
        "redigereMaler": false,
        "sletteMaler": false,
    
        "invitereBrukere": false,
        "fjerneBrukere": false,
        "redigereBrukere": false,
    
        "lageKalkulatorer": false,
        "redigereKalkulatorer": false,
        "sletteKalkulatorer": false,
    
        "seUtstyr": true,
        "lageUtstyr": true,
        "redigereUtstyr": false,
        "sletteUtstyr": false,
    
        "endreDatoer": false,
        "seAndreSineTimer": false,
        "redigereAndreSineTimer": false,
        "sletteAndreSineTimer": false,
    
        "invitereBrukere": false,
        "fjerneBrukere": false,
        "redigereBrukere": false,
    
        "redigereRoller": false,
    
        "lageKunder": false,
        "kundeOversikt": false,
    
        "lageKalkulatorer": false,
        "redigereKalkulatorer": false,
        "sletteKalkulatorer": false,

        "lageProsjekter": false,
        "redigereProsjekter": false,
        "sletteProsjekter": false,
    }


    const history = useHistory()
    const params = useParams()
    const [rolle, setRolle] = useState(history.location.state?.rolle || DEFAULTPermissions)


    useEffect(() => {
        // Merge RolleConfig with history
        // This is if we ever edit roles, or add some.
        if (history.location.state?.rolle) {
            const merged = { ...DEFAULTPermissions, ...history.location.state?.rolle }
            setRolle(merged)
        }
    }, [window.location.pathname])

    // Icon picker
    const fontpickerRef = createRef();
    const icprops = {
        icons: iconDefs.fontAwesome,
        theme: 'bluegrey',
        renderUsing: 'class',
        value: rolle.icon,
        //onChange: (icon) => setMal({ ...mal, icon: icon }),
        isMulti: false,
        //renderUsing: "data-icomoon",
        closeOnSelect: true,
    };

    return (
        <DefaultWrapper onBack={() => {
            setRole(rolle)
            updateAllUsersWithRole(rolle)
        }} lagring={params.id !== "ny"}>
            <div className="content-ny column">
                <div className="row">
                    <div className="stretch-width column">
                        <h2>Rolle Navn</h2>
                        <CustomInput value={rolle.navn} onChange={(e) => setRolle({ ...rolle, navn: e.target.value })} />
                    </div>

                    <div className="column">
                        <h2>Icon</h2>
                        <div className="row">
                            <button className='icon-button'>
                                <FontIconPicker ref={fontpickerRef} {...icprops} onChange={(e) => setRolle({ ...rolle, icon: e })} value={rolle.icon} />
                            </button>
                        </div>
                    </div>
                </div>

                <ButtonListCheckbox icon={AdminIcon} title="Administrator" checked={rolle.admin} onCheckboxChange={(newCheckedStatus) => setRolle({ ...rolle, admin: newCheckedStatus })} />

                {DEFAULTROLLECONFIG.permissions.map((regel, i) => {
                    console.log(regel)
                    return (
                        <div className="column small-gap" key={i}>
                            <h2>{regel.tittel}</h2>
                            {regel.regler.map((subregel, j) => {
                                return (
                                    <div className="column" key={j}>
                                        <ButtonListCheckbox
                                            title={subregel.tittel}
                                            icon={subregel.icon}
                                            checked={rolle[subregel.id]}
                                            onCheckboxChange={(newCheckedStatus) => {
                                                setRolle({ ...rolle, [subregel.id]: newCheckedStatus })
                                            }}

                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}

                {params.id === "ny" ?
                    <div className="row flexApart">
                        <div />
                        <button className="button" onClick={() => {
                            setRole(rolle)

                            //go back
                            history.goBack()
                        }}>Lagre</button>
                    </div>
                    :
                    null
                }
            </div>
        </DefaultWrapper>
    )
}