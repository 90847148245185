

import { StyleSheet } from '@react-pdf/renderer';

export const PDFstyles = StyleSheet.create({
    document: {
        padding: 20,
        fontSize: 12,
    },
    page: {
        width: 'auto', // This will allow the page to take the width of its content
        overflowWrap: 'break-word',
        padding: 20,
        height: 'auto',
        paddingBottom: 40,
    },
    column: {
        flexDirection: 'column',
        display: 'flex',
    },
    gap: {
        gap: 10,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        margin: 0,
    },
    flexApart: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerHeight: {
        justifyContent: 'center',
    },
    centerRow: {
        alignItems: 'center',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        //alignItems: 'flex-end',
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    wrap: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    // Text
    text: {
        color: 'black',
        //width: '100%',
        //height: '100%',
        fontSize: 12,
        //border: '1px solid red',
    },
    textCenter: {
        textAlign: 'center',
        //marginBottom: -5
    },
    wrapText: {
        wordWrap: 'break-word',
    },
    textBold: {
        fontWeight: 800,
    },
    bold: {
        fontWeight: 800,
    },
    bigText: {
        fontSize: 14,
    },

    // styling
    blackBox: {
        border: '1px solid black',
        padding: 5,
        marginRight: '-1px',
        marginTop: '-1px',
    },
    centerInBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    centerInBoxText: {
        textAlign: 'center',
    },
    setHeight: {
        height: 60,
    },

    stretch2: {
        flex: 1,  // Allow the box to take up available space
        border: '1px solid black',
        padding: 10,
        marginRight: '-1px',
        marginTop: '-1px',
        minWidth: '200px',
        width: 'auto',
        flexShrink: 1,  // Allow the box to shrink based on content
        position: 'relative',
        height: 'auto',
    },
    textLeft: {
        textAlign: 'left',
        fontSize: 12,
    },
    stretchWidth: {
        width: '100%',
    },

    logo: {
        height: "100px",
        maxHeight: "100%",
        width: "160px",
        //width: "100%",
        marginRight: 20,
        marginLeft: 20,
        objectFit: "contain",
        display: 'flex',
    },
    bigGap: {
        marginTop: 20,
    },
    contentMargin: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20,
    },
    fitContent: {
        minWidth: 200,
    },

    bigBox: {
        width: '100%',
        margin: 0,
        border: '1px solid black',
        padding: 5,
        flexShrink: 1,  // Allow the box to shrink to content
    },
    box: {
        padding: 15,
        border: '1px solid black',
        position: 'relative',
        boxSizing: 'border-box',
        //flexDirection: 'column',
    },
    boxOverlapTop: {
        //marginRight: '-1px',
        marginTop: '-1px',
    },
    boxOverlapRight: {
        marginRight: '-1px',
        //marginTop: '-1px',
    },

    hardBorder: {
        border: '2px solid yellow',
        margin: "-2px",
    },
    minHeightBox: {
        minHeight: 100,
    },
    minHeightBoxSmall: {
        minHeight: "60px",
    },
    minHeightBoxTextSize: {
        minHeight: "20px",
    },

    minWidthBox: {
        minWidth: 200,
    },
    minWidthBoxSmall: {
        minWidth: 100,
    },
    
    signering: {
        //width: ,
        height: 40,
        width: "auto",
        //maxWidth: 400,
        //borderBottom: '1px solid black',
    },
    fit: {
        flex: 1,
        //fit width to content
        minWidth: 200,
        //border: '1px solid black',
        padding: 10,
        //height: '50px',
        marginRight: '-1px',
        marginTop: '-1px',
    },

    tableBig: {
        flex: 2,
    },
    tableBarMedium: {
        flex: 1,
    },
    tableBarSmall: {
        flex: 0.5,
    },
    pageNumber: {
        position: 'absolute', // Allows positioning relative to the page
        fontSize: 10, // Set font size
        bottom: 10, // 10px from the bottom, within the padding
        right: 10, // 10px from the right, within the padding
        textAlign: 'center', // Center align text (useful if there are multiple lines)
        color: 'black', // Text color
    },
    label: {
        fontWeight: 800,
        position: 'absolute',
        top: 3,
        left: 3,
        fontSize: 8,
    },

    h1: {
        fontSize: 24,
        fontWeight: 800,
    },
    h2: {
        fontSize: 16,
        fontWeight: 800,
    },
    uthevetBakgrunn: {
        backgroundColor: '#fab005',
    },
    pageInfo: {
        position: 'absolute', // Allows positioning relative to the page
        fontSize: 10, // Set font size
        bottom: 10, // 10px from the bottom, within the padding
        left: 10, // 10px from the right, within the padding
        textAlign: 'center', // Center align text (useful if there are multiple lines)
        color: 'black', // Text color
    },
    infoLogo: {
        height: 24,
        width: 24,
    },
    
});
