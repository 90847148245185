import { Capacitor } from "@capacitor/core";
import { ArrowLeftRounded, ArrowRightRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";




export default function TopBarChoosePageGoogleVersion(props) {
    const history = useHistory();
    const [state, setState] = useState(history.location.state?.tab || props.options[0]);
    const [topBarHolderScrollEnd, setTopBarHolderScrollEnd] = useState(false);
    const topBarHolder = React.createRef();
    

    useEffect(() => {
        // Find the option in the new props that matches the current state's name
        const matchingOption = props.options.find(option => option.name === state.name);

        // If a matching option is found, update the state with it
        if (matchingOption) {
            setState(matchingOption);
        }
    }, [props.options, state.name]);

    useEffect(() => {
        const tabState = history.location.state?.tab;
        if (!tabState) {
            return;
        }
        console.log(tabState);
        const matchingOption = props.options.find(option => option.name === tabState);
        if (matchingOption) {
            setState(matchingOption);
        }
    }, [history.location.state?.tab])


    const onClickScrollIntoView = (element) => {
        // Center element in the middle of the screen if plaussible
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    const onScroll = (e) => {
        // If top bar Holder isn't scrolled till the end, show arrow. Else hide it.
        if (e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth) {
            setTopBarHolderScrollEnd(false);
        }
        else {
            setTopBarHolderScrollEnd(true);
        }
    }

    

    return (
        <div className="column top-bar-holder-google-version-container">
            <div onScroll={onScroll} ref={topBarHolder} className="row top-bar-holder-google-version stretch-width" style={{
                // Check if on IOS
                paddingTop: Capacitor.getPlatform() === "ios" ? 40 : 20,
                position:"relative"
            }} >
                {props?.options?.map((option, index) => {
                    const buttonRef = React.createRef();
                    return (
                        <div key={index} className="column noGap center">
                            <button ref={buttonRef} className={"column small-gap top-bar-button-google-version " + (state.name === option.name ? "active" : "")} onClick={
                                () => {
                                    // Update the state with the new option
                                    history.push(history.location.pathname, { tab: option.name });
                                    setState(option)
                                    onClickScrollIntoView(buttonRef.current)
                                }
                            }>
                                {option?.icon && <option.icon className={state.name === option.name ? "orange" : ""} />}
                                {option.name}
                            </button>
                            <div className={"top-bar-button-google-version-underline " + (state.name === option.name ? "active" : "")}></div>
                        </div>
                    )
                })}

            </div>
            {!topBarHolderScrollEnd &&
                <div className="row center" style={{
                    position: "absolute",
                    top:60,
                    right: 0,
                }}>
                    <button className="button-no-style" onClick={() => topBarHolder.current.scrollBy({ left: 100, behavior: "smooth" })}>{<ArrowRightRounded />}</button>
                </div>
            }
            {state?.component && state.component}
        </div>
    )
}