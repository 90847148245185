import { db, app, getCompany, getCompanyId, companyId } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs, query, where, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { combineAllToCheckPermissions } from '../components/utils';

export async function getCompanyUsers(params, onUpdate) {

    const q = query(collection(db, 'Kompanier', companyId(), 'users'));
    const querySnapshot = await getDocs(q);
    const users = [];

    for (const doc of querySnapshot.docs) {
        const user = doc.data();
        users.push(user);
    }


    // Listen to changes in users
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const list = snapshot.docs.map((doc) => ({ ...doc.data() }));
        console.warn("Getting company users")
        onUpdate(list);
    }
    );

    return { value: users, subscription: unsubscribe };
}

export async function getCompanyUserRoles(companyUser, user) {
    // db, 'Kompanier', companyId(), 'invitation', companyUser.id
    const q = query(collection(db, 'Kompanier', companyId(), 'users'), where("id", "==", companyUser.id));
    //const docRef = doc(db, 'Kompanier', companyId(), 'invitations', companyUser.invId);
    const querySnapshot = await getDocs(q);
    const invitation = querySnapshot.docs[0].data();
    return invitation;
}


export async function updateCompanyUser(update) {
    console.log(update)

    const updateObj = Object.assign({}, update) // Delete Custom object/class
    await updateDoc(doc(db, 'Kompanier', companyId(), 'users', updateObj.id), updateObj);
}

export async function updateCompanyUserRoles(update) {
    const updateObj = {
        ...update,
        roles: update.roles,
        roleIds: update.roles.map(
            (role) => role.id
        ),
        permissions: combineAllToCheckPermissions(update.roles)
    }

    const functions = getFunctions(app, 'europe-west2');
    const updateCompanyUserFunction = httpsCallable(functions, 'updateCompanyUser');
    console.log(updateObj)
    const data = await updateCompanyUserFunction({
        company: companyId(),
        companyUserChanges: updateObj
    }
    );
    if (data?.error) {
        throw new Error(data.error);
    }
}


export async function getCompanyUser(userToGet) {
    //TODO - get the user by ID instead of epost
    try {
        const docRef = doc(db, 'Kompanier', companyId(), 'users', userToGet.uid || userToGet);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
        /*const q = query(collection(db, 'Kompanier', companyId(), 'users'), where("id", "==", userToGet.uid));
        const querySnapshot = await getDocs(q);

        console.log(querySnapshot.docs[0].data())
        if (!querySnapshot.empty) {
            const user = querySnapshot.docs[0].data();
            return user;
        } else {
            console.error('No document found');
            return null;
        }*/
    } catch (error) {
        //console.error('Error getting document:', error);
        return false;
    }
}