import { sendPasswordResetEmail } from "@firebase/auth";
import { auth } from "../../firebase";
import CustomInput from "../../components/miniComponents/customInput";
import ButtonOnlyHitOnce from "../../components/miniComponents/buttonOnlyHitOnce";
import DefaultWrapper from "../../components/defaultWrapper";
import { useContext, useState } from "react";
import { Send } from "@mui/icons-material";
import { useHistory } from "react-router";
import { AlertContext } from "../../App";
import { useTranslation } from "react-i18next";


const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log("Password reset email sent!");
    } catch (error) {
        console.error("Error sending password reset email: ", error);
    }
};

export default function ResetPassword() {
    const { t } = useTranslation();
    const history = useHistory();
    const { alertContext, setAlertContext } = useContext(AlertContext)
    const [email, setEmail] = useState(history.location.state?.email || "");
    const navigateToNewPage = (path, information) => {
        history.push({
            pathname: path,
            state: information
        });
    }

    const showAlert = () => setAlertContext({
        text: <div className="column">
            <h3>{t("Email sent. If it is a user they have received a reset email. Check Spam.",
                "Email sendt. Om det er en bruker så har de fått en reset mail. Sjekk Søppelpost.")}
            </h3>
        </div>,
        onConfirm: async () => {
            //history.push("/company")
        },
        onConfirmText: "Ok"
    })


    return (
        <DefaultWrapper>
            <div className="center column">
                <div className="column">
                    <h1>Reset Passord</h1>
                    <p>Skriv in din email for å sende en reset email.</p>
                    <CustomInput type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <ButtonOnlyHitOnce onClick={async () => {
                        await sendPasswordReset(email)
                        showAlert()
                        navigateToNewPage("/login")
                    }}> Send reset email <Send /> </ButtonOnlyHitOnce>
                </div>
            </div>
        </DefaultWrapper>
    )
}