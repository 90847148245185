import { useEffect, useState } from "react";
import DefaultWrapper from "../components/defaultWrapper";
import ButtonListButton from "../components/miniComponents/buttonListButton";
import { getRoles } from "../firebase";
import { useHistory } from "react-router";
import LoadingWhileEmpty from "../components/miniComponents/loadingWhileEmpty";
import { useTranslation } from "react-i18next";




export default function Roller() {
    const { t } = useTranslation();
    const history = useHistory();
    const [roller, setRoller] = useState([]);


    useEffect(() => {
        //firebase get roller
        const onUpdate = async (roller) => {
            console.log(roller);
            setRoller(roller);
        }
        getRoles(onUpdate)
    }, []);

    return (
        <DefaultWrapper>
            <div className="content-ny column">
                <h1>{t("Roles", "Roller")}</h1>
                <div className="button-list">
                    <LoadingWhileEmpty>
                        {roller.map((rolle) => {
                            return (
                                <ButtonListButton key={rolle.id} onClick={() => {
                                    if (rolle.id === "admin") return;
                                    history.push("/roller/" + rolle.id, { rolle: rolle })
                                }}
                                    title={rolle.navn}
                                    description={rolle.admin ? "Admin" : "Bruker"}
                                    icon={rolle?.icon}
                                />
                            )
                        })}
                    </LoadingWhileEmpty>
                </div>
                <button className="bottom-right-button" onClick={() => {
                    history.push("/roller/ny", { rolle: null })
                }}>{t("Add", "Legg til")} +</button>
            </div>
        </DefaultWrapper>
    )
}
