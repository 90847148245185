import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import SjaOverview from "./overview/SjaOverview";
import RUHOverview from "./overview/RuhOverview";
import AvvikOverview from "./overview/AvvikOverview";
import DefaultWrapper from "../../components/defaultWrapper";
import CreateNewHMSReportButton from "./utils/create-new-button";









export default function HMSViewType() {
    const { t } = useTranslation();
    const params = useParams();

    return (
        <DefaultWrapper>
            <HMSSwitcher type={params.type} />
            <CreateNewHMSReportButton />
        </DefaultWrapper>
    )
}

function HMSSwitcher({ type }) {
    switch (type) {
        case "avvik":
            return <AvvikOverview />
        case "ruh":
            return <RUHOverview />
        case "sja":
            return <SjaOverview />
        default:
            return <div>Unknown type</div>
    }
}
