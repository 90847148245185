import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { pdf } from '@react-pdf/renderer';
import { CircularProgress } from '@mui/material';



export const PDFPreview = React.memo(({ document }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true); // State to track the first load

    // Generate PDF and update URL
    const generatePDF = async () => {
        const instance = await pdf(document).toBlob();
        const url = URL.createObjectURL(instance);
        setPdfUrl(url);
        if (isFirstLoad) setIsFirstLoad(false); // Update first load state after successful generation
    };

    // Debounce the PDF generation function to limit its execution frequency
    const debouncedGeneratePDF = useRef(debounce(generatePDF, 3000)).current;

    useEffect(() => {
        // Call the debounced PDF generation function
        debouncedGeneratePDF();

        // Cleanup function to cancel the debounce on component unmount
        return () => debouncedGeneratePDF.cancel();
    }, [document, debouncedGeneratePDF]);

    // Conditional rendering based on the first load
    return (
        <div className='render-pdf-container'>
            {!pdfUrl && <CircularProgress className='pdf-loading' />}
            {pdfUrl && <object className='render-pdf' key={"pdfIframe"} data={pdfUrl} type="application/pdf" />}
        </div>
    );
});

export const generatePDFFromDocument = async (document) => {
    const instance = await pdf(document).toBlob();
    const url = URL.createObjectURL(instance);
    return url;
};