
import { IonButton, IonContent, IonDatetime, IonHeader, IonModal, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { createGesture } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from "react";
import TilbakeKnapp from "../components/miniComponents/tilbakeKnapp";

import { ReactComponent as SearchIcon } from '../assets/icons/material-symbols_search.svg';
import { searchObjects } from "./search";
import Background from "./miniComponents/background";
import { HeaderContext } from "../App";
import Header from "./header";
import { Capacitor } from "@capacitor/core";


export default function WrapperForTyping(props) {

    const history = useHistory();
    const pageElRef = useRef();

    return (
        <IonPage>
            <IonContent fullscreen ref={pageElRef} className={(props?.header ? "wrapperHeaderContent" : "") + " wrapper" }>
                <Background />
                <div className={"row flexApart padding wrapper wrapper-for-typing-top " + (Capacitor.getPlatform() === "ios" ? "padding ios-padding-top" : "padding")}>
                    {!props?.noBackButton && <TilbakeKnapp lagring={props?.lagring} onClick={props.onBack} skipNormalBehaviour={props.skipBack} />}
                    {props?.søkefelt &&
                        <Søkefelt updateFilteredItems={props.updateFilteredItems} itemsToFilter={props.itemsToFilter} />
                    }
                    {props?.extraTitle && props.extraTitle}
                    {props?.extraButton && props.extraButton}
                </div>
                <div className="wrapper-padding"/>
                {props.children}
                {//props?.header && <Header />
                }
            </IonContent>
        </IonPage>
    );
}


export function Søkefelt(props) {
    const inputRef = useRef(null);
    const [searchString, setSearchString] = useState('');

    //console.log(searchString, props.itemsToFilter, props.searchTerms)
    useEffect(() => {
        if (typeof props?.updateSearchString !== 'function') return
        props.updateSearchString(searchString);
    }, [searchString]);

    useEffect(() => {
        filterBrukere();
    }, [searchString, props.itemsToFilter, props.searchTerms]);

    function sortItems(items, sortBy) {
        if (!Array.isArray(items)) {
            console.error('Expected items to be an array, but got:', items);
            return [];
        }
        // Assuming you have a function to sort items based on props.sortBy
        return items.sort((a, b) => a[sortBy] - b[sortBy]);
    }

    function filterBrukere() {
        const itemsToFilter = Array.isArray(props.itemsToFilter) ? props.itemsToFilter : [];
        const searchTerms = Array.isArray(props.searchTerms) ? props.searchTerms : [];

        let filtered = [];

        if (searchString === '') {
            filtered = sortItems(itemsToFilter, props.sortBy);
        } else {
            const searchStr = typeof searchString === 'string' ? searchString : '';
            filtered = props?.doNotShowScoreUnderZero
                ? searchObjects(itemsToFilter, searchStr, searchTerms).filter((item) => item.score > 0)
                : searchObjects(itemsToFilter, searchStr, searchTerms);
        }

        if (typeof props.updateFilteredItems === 'function') {
            if (searchString === '') filtered = filtered.map((item) => ({ ...item, score: 1 }));
            console.log(filtered);
            filtered.forEach((item) => {
                console.log(item.score);
            })
            props.updateFilteredItems(filtered);
        } else {
            //console.error('Expected props.updateFilteredItems to be a function, but got:', props.updateFilteredItems);
        }
    }

    return (
        <button className="søkefelt" onClick={() => inputRef.current.focus()}>
            <input className="søkefelt-input" onChange={(e) => setSearchString(e.target.value)} ref={inputRef} type="text" placeholder="Søk" />
            <SearchIcon />
        </button>
    );
}