import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import CustomInput from "../../components/miniComponents/customInput";
import { createCompany, createId, getAuthUserId } from "../../firebase";
import { useHistory } from "react-router";
import { ButtonListWrappingButton } from "../modules/modules";
import { Add, AlarmRounded, ApartmentRounded, BorderColorRounded, Check, ContactPageRounded, Edit, Email, OnlinePredictionRounded, Pages, People, PostAddRounded, Report } from "@mui/icons-material";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserContext } from "../../App";
import Canvas from "../../components/canvas";
import { useTranslation } from "react-i18next";
import ButtonOnlyHitOnce from "../../components/miniComponents/buttonOnlyHitOnce";

import { ReactComponent as ProgressClockIcon } from '../../assets/icons/mdi_progress-clock.svg';
import { checkCouponCode } from "../../database/company";
import { useSelector } from "react-redux";

export default function CreateCompanyPage(props) {
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const [company, setCompany] = useState({
        name: "",
        id: createId(),
        email: "",
        leader: "",
        nummer: "",
        //etternavn: "",
        createdAt: new Date(),
        orgNr: "",
        lat: "",
        lng: "",
        address: "",
        postalCode: "",
        city: "",
        createdBy: user.uid || getUserIdBackup(),
        couponCode: "",
        nextPaymentDate: new Date(+ new Date() + 30 * 24 * 60 * 60 * 1000),
        paymentMethod: null,
        invoiceMail: "",
    });

    const [startedSignering, setStartedSignering] = useState(false);
    const [skippedSigning, setSkippedSigning] = useState(false);
    const [checkedForSelgerKode, setCheckedForSelgerKode] = useState(null);
    const [suggestedCompanies, setSuggestedCompanies] = useState(null)

    const getUserIdBackup = async () => {
        return await getAuthUserId();
    }

    const [moduleSettings, setModuleSettings] = useState({
        rapportModul: false,
        timeføringModul: false,
        utstyrModul: false,
        kundeModul: false,
        prosjektModul: false,
        hmsModule: false,
        // in a month
    })

    const history = useHistory();

    useEffect(() => {
        if (!company.orgNr) return;
        if (company.orgNr.length < 9) return;
        getOrgByOrgNr(company.orgNr).then((data) => {
            if (!data) return;
            console.log(data);
            setCompany({
                ...company,
                ...data
            })
        })
    }, [company.orgNr])

    useEffect(() => {
        if (company?.name?.length < 3) return;
        async function getSuggested() {
            const suggested = await searchByOrgName(company.name)
            setSuggestedCompanies(suggested)
        }
        if (company?.orgNrSuggested) return;
        getSuggested()
    }, [company.name])

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js';
        script.addEventListener('load', () => {
            console.log("script loaded")
            window.grecaptcha.enterprise.ready(() => {
                /*window.grecaptcha.enterprise.render('recaptcha-container', {
                    sitekey: '6LfS2rYpAAAAAMK2a23ByZEmyGZxK9_vzvVDSNtl',
                    callback: (token) => {
                        // Handle the token, typically by sending it to your server
                        console.log(token);
                    }
                });*/
            });
        });
        document.body.appendChild(script);
        console.log("script added")

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    function onSign(e) {
        e.preventDefault();
        console.log(window.grecaptcha)
        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute('6LfS2rYpAAAAAMK2a23ByZEmyGZxK9_vzvVDSNtl', { action: 'LOGIN' });
            // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
            // reCAPTCHA Enterprise to the end user's browser.
            // This token must be validated by creating an assessment.
            // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
        });
    }

    return (
        <DefaultWrapper>
            <div className="stretch-width center">
                <div className="content-ny column big-gap" style={{
                    margin: "auto",
                    maxWidth: "1000px"
                }}>
                    <div className="row stretch-width center-column">
                        <h1 className="fit-content">{t("Company", "Firma")}</h1>
                        <div className="division-line orange" />
                    </div>

                    <div className="row small-gap">
                        <CustomInput onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    orgNr: e.target.value,
                                    orgNrSuggested: false
                                })
                            }
                        } inputPattern={[3, 3, 3]} id="OrgNr" className="input" label={t("Organization number", "Org Nr.")} value={company.orgNr}></CustomInput>
                    </div>

                    <div className="row small-gap">
                        <CustomInput label={t("Company Name", "Firmanavn")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    name: e.target.value,
                                    orgNrSuggested: false
                                })
                            }
                        } id="name"
                            className="input"
                            value={company.name}
                            suggestions={suggestedCompanies}
                            onSuggestion={(suggestion) => {
                                console.log(suggestion)
                                setCompany({
                                    ...company,
                                    name: suggestion?.name,
                                    orgNr: suggestion?.organisasjonsnummer,
                                    orgNrSuggested: true,
                                })
                                setSuggestedCompanies(null)
                            }}
                        ></CustomInput>
                        <CustomInput label={t("Address", "Addresse")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    address: e.target.value
                                })
                            }
                        } id="address" className="input" value={company.address}></CustomInput>
                    </div>
                    <div className="row small-gap">
                        <CustomInput label={t("Postal code", "Postnummer")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    postalCode: e.target.value
                                })
                            }
                        } id="zip" className="input" value={company.postalCode}></CustomInput>
                        <CustomInput label={t("City", "By")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    city: e.target.value
                                })
                            }
                        } id="city" className="input" value={company.city}></CustomInput>
                    </div>


                    <div className="row stretch-width center-column">
                        <h1 className="fit-content text-no-wrap">{t("Person / Leader / Administrator", "Person / Leder / Administrator")}</h1>
                        <div className="division-line orange" />
                    </div>
                    <div className="row small-gap">
                        <CustomInput label={t("Name", "Navn")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    leader: e.target.value
                                })
                            }
                        } id="name" className="input" value={company.leader}></CustomInput>
                        {/*<CustomInput label={t("Lastname", "Etternavn")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    etternavn: e.target.value
                                })
                            }
                        } id="lastname" className="input" value={company.etternavn}></CustomInput>
                        */}
                    </div>

                    <div className="row small-gap">
                        <CustomInput label={t("Email", "E-post")} onChange={
                            (e) => {
                                setCompany({
                                    ...company,
                                    email: e.target.value
                                })
                            }
                        } id="email" className="input" value={company.email}></CustomInput>
                        <CustomInput label={t("Phone Number", "Telefon Nummer")} beforeInput={<p>+47</p>}
                            onChange={
                                (e) => {
                                    setCompany({
                                        ...company,
                                        nummer: e.target.value
                                    })
                                }
                            } id="phone" className="input" value={company.nummer} inputPattern={[3, 2, 3]}></CustomInput>
                    </div>


                    <div className="row stretch-width center-column">
                        <h1 className="fit-content">{t("Invoice", "Faktura")}</h1>
                        <div className="division-line orange" />
                    </div>

                    <div className="row stretch-width center">
                        <button disabled className={"big-button column center card" + (
                            company.paymentMethod === "EHF" ? " active" : ""
                        )} onClick={() => {
                            setCompany({
                                ...company,
                                paymentMethod: "EHF"
                            })
                        }}>
                            <OnlinePredictionRounded style={{ fontSize: "56px", color: "var(--contrastColor)", width: "56px", height: "56px" }} />
                            <h3 className="beta">{t("Coming Soon", "Kommer Snart")}</h3>
                            <p>EHF</p>
                        </button>

                        <button className={"big-button column center card" + (
                            company.paymentMethod === "Brev" ? " active" : ""
                        )} disabled onClick={() => {
                            setCompany({
                                ...company,
                                paymentMethod: "Brev"
                            })
                        }}>
                            <PostAddRounded style={{ fontSize: "56px", color: "var(--contrastColor)", width: "56px", height: "56px" }} />
                            <h3 className="beta">{t("Coming Soon", "Kommer Snart")}</h3>
                            <p>{t("Post", "Brev")}</p>
                        </button>

                        <button className={"big-button column center card" + (
                            company.paymentMethod === "Email" ? " active" : ""
                        )} style={{ height: "100%" }} onClick={() => {
                            setCompany({
                                ...company,
                                paymentMethod: "Email"
                            })
                        }}>
                            <Email style={{ fontSize: "56px", color: "var(--contrastColor)", width: "56px", height: "56px" }} />
                            <h3 className="working">{t("Working", "Fungerer")}</h3>
                            <p>{t("Email", "E-post")}</p>
                        </button>
                    </div>

                    {company.paymentMethod === "Email" && <CustomInput label={t("Payment Method Email", "Betalingsmetode E-post")} onChange={
                        (e) => {
                            setCompany({
                                ...company,
                                invoiceMail: e.target.value
                            })
                        }
                    } id="email" className="input" value={company.invoiceMail}></CustomInput>
                    }



                    <h3>{t("Modules included in customer package", "Moduler Inkludert I Kundepakke")}</h3>
                    <div className="flexApart wrap row">
                        <ButtonListWrappingButton title={t("Report Modules", "Rapportmodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, rapportModul: !moduleSettings.rapportModul }) }} active={moduleSettings.rapportModul} icon={ContactPageRounded} />
                        <ButtonListWrappingButton title={t("Time Management Module", "Timeføringsmodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, timeføringModul: !moduleSettings.timeføringModul }) }} active={moduleSettings.timeføringModul} icon={AlarmRounded} />
                        <ButtonListWrappingButton title={t("Equipment Module", "Utstyrsmodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, utstyrModul: !moduleSettings.utstyrModul }) }} active={moduleSettings.utstyrModul} icon={Pages} />
                        <ButtonListWrappingButton title={t("Customer Module", "Kundemodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, kundeModul: !moduleSettings.kundeModul }) }} active={moduleSettings.kundeModul} icon={People} />
                        <ButtonListWrappingButton title={t("Project Module", "Prosjektmodul")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")} onClick={() => { setModuleSettings({ ...moduleSettings, prosjektModul: !moduleSettings.prosjektModul }) }} active={moduleSettings.prosjektModul} icon={ApartmentRounded} />
                        <ButtonListWrappingButton title={t("Health And Safety", "HMS")} description={t("+ 0 kr/ Month", "+ 0 kr/ Måneden ")}
                            onClick={() => { setModuleSettings({ ...moduleSettings, hmsModule: !moduleSettings.hmsModule }) }} active={moduleSettings.hmsModule} icon={Report} />
                    </div>

                    <div className="column">
                        <div className="row center">
                            <CustomInput label={t("Coupon Code", "Kupong Kode")} onChange={(e) => {
                                setCompany({
                                    ...company,
                                    couponCode: e.target.value
                                })
                            }
                            } id="couponCode" className="input" value={company.couponCode}></CustomInput>
                            <button className={"confirm " + checkedForSelgerKode} onClick={async () => {
                                console.log(company.couponCode)
                                const response = await parseInput(company.couponCode);
                                const responseData = response?.data
                                const data = responseData?.data
                                console.log(responseData)
                                console.log(data)
                                if (!responseData) return setCheckedForSelgerKode("recording");
                                if (Object.keys(responseData).length === 0) return setCheckedForSelgerKode("recording");
                                if (responseData?.error) return setCheckedForSelgerKode("recording");

                                setCheckedForSelgerKode("good");
                                setCompany({
                                    ...company,
                                    ...data,
                                    //selgerKode: parsed.data.string
                                })
                            }}><Check style={{ height: "24px", width: "24px" }} /></button>
                        </div>
                        <BrukerVilkår data={company} />
                        <Agreement data={company} />


                        <div className="stretch-width center row">
                            {startedSignering ?
                                <Canvas onExport={(link) => {
                                    setCompany({
                                        ...company,
                                        signering: link
                                    })
                                }} /> : <button className="ny column" onClick={() => {
                                    setStartedSignering(true)
                                    let content = document.querySelector("ion-content");

                                    setTimeout(() => {
                                        // smooth
                                        return content && content.scrollToBottom(400);
                                    }, 100)
                                    //const windowToScroll = document.querySelector(".ion-page");
                                    // wait 100ms for the canvas to be created
                                    /*setTimeout(() => {
                                    windowToScroll.scrollTo({ top: windowToScroll.scrollHeight, behavior: 'smooth' });
                                    } , 1000)*/
                                }}>
                                    <BorderColorRounded style={{
                                        fontSize: "56px",
                                        color: "var(--contrastColor)",
                                        width: "56px",
                                        height: "56px",
                                    }}
                                    />
                                    <p>{t("I understand brukervilkår", "Jeg forstår og aksepterer brukervilkårene, Start Signering")}</p>
                                </button>

                            }
                            {
                                !skippedSigning && !startedSignering && <ButtonOnlyHitOnce className="ny column" onClick={async () => {
                                    setSkippedSigning(true);
                                    setCompany({
                                        ...company,
                                        signering: null,
                                    })
                                }}><ProgressClockIcon style={{
                                    fontSize: "56px",
                                    color: "var(--contrastColor)",
                                    width: "56px",
                                    height: "56px",
                                }} /> <p>{t("Sign Later", "Signer Senere")}</p> </ButtonOnlyHitOnce>
                            }
                        </div>
                    </div>

                    <div className="flexApart row small-gap">
                        <div />
                        {company.signering || skippedSigning &&
                            <ButtonOnlyHitOnce className="ny" onClick={async () => {
                                await createCompany(company, moduleSettings);
                                history.goBack();
                            }}>{t("Create", "Opprett")} <Add /> </ButtonOnlyHitOnce>
                        }
                    </div>
                </div>
            </div>
        </DefaultWrapper >
    );
}


export async function getOrgByOrgNr(nr) {
    //Remove spaces from orgnr
    const orgnr = nr.replace(/\s/g, '');
    if (!orgnr) return;
    if (orgnr.length !== 9) return;

    // Querying the Enhetsregisteret API
    /*const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
    const response = await axios.get(apiUrl);*/
    // Use fetch instead
    const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`;
    const apiUrlRoller = `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}/roller`;


    const data = fetch(apiUrl)
        .then(response => response.json())
        .then(async (data) => {
            // If the orgnr is valid, set the client's name to the name of the company
            // If the orgnr is invalid, set the client's name to an empty string
            // If the orgnr is empty, set the client's name to an empty string

            // If the orgnr is valid, set the client's address to the address of the company
            // If the orgnr is invalid, set the client's address to an empty string
            // If the orgnr is empty, set the client's address to an empty string

            // If the orgnr is valid, set the client's postal code to the postal code of the company
            // If the orgnr is invalid, set the client's postal code to an empty string
            // If the orgnr is empty, set the client's postal code to an empty string
            async function fetchPlaces(query) {
                try {
                    const response = await fetch(`https://app.effektivjobb.no/fetchPlaces?input=${query}`);
                    const data = await response.json();
                    return data.candidates[0];  // get the first result
                } catch (error) {
                    console.error('Error fetching from server:', error);
                    return null;
                }
            }
            if (!data || data.status === 400 || data.status === 403) return;

            async function fetchPlacesAsync() {
                const place = await fetchPlaces(data?.forretningsadresse?.adresse[0]);
                console.log(place);
                return place;
            }

            const addressWithLangAndLat = await fetchPlacesAsync()

            let temp = {
                lat: addressWithLangAndLat?.geometry?.location?.lat,
                lng: addressWithLangAndLat?.geometry?.location?.lng,
                address: data?.forretningsadresse?.adresse[0],
                postalCode: data?.forretningsadresse?.postalCode,
                city: data?.forretningsadresse?.poststed,
                orgNr: data?.organisasjonsnummer,
                name: data?.name
            };
            return temp;
        });
    return data;
}

export async function searchByOrgName(name) {
    const baseUrl = "https://data.brreg.no/enhetsregisteret/api/enheter"; // base URL for the entity search
    const url = `${baseUrl}?navn=${encodeURIComponent(name)}`; // constructing the URL with query parameter for name

    /*const suggestions = await fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // parsing the response to JSON
        })
        .then(data => {
            console.log(data);
            return data._embedded.enheter;
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });*/
    const suggestions = await fetch(url)
    const response = await suggestions.json()
    console.log(response)
    const data = response?._embedded?.enheter
    console.log(data)

    return data;
}






const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backdropFilter: "blur(10px) brightness(0.75)",
    background: "transparent",
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowForwardIosSharpIcon
                sx={{ fontSize: '0.9rem', color: props.expanded ? 'var(--contrastColor)' : '#FFF' }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    position: 'sticky', // Add this line
    top: 0, // Add this line to define the sticky position
    zIndex: 1, // Add this line to ensure it stays on top of other content
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export function BrukerVilkår({ data }) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const percentageToTakeOff = (100 - data?.percentage) / 100;

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ color: "var(--contrastColor)" }}>{t("Prices & subscription", "Priser & Abonnement")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="column">
                        <div className="column small-gap">
                            <h2 className="fit-content orange">{t("Admin User Price", "Admin Bruker Pris")}</h2>
                            <p>
                                {data?.months && data?.percentage ? `${t("For the first", "For de første")} ${data.months} ${t("months", "månedene")}, ${t("you pay", "så betaler du")} ${Math.floor(percentageToTakeOff * 249)}${t("kr/month per admin user", "kr/mnd Per Admin Bruker, så 249kr/mnd Per Admin Bruker etter det.")}` :
                                    `${t("Per Admin User: ", "Per Admin bruker: 249kr/mnd")}`
                                }
                            </p>
                            <h2 className="fit-content orange">{t("User Price", "Bruker Pris")}</h2>
                            <p>
                                {data?.months && data?.percentage ? `${t("For the first", "For de første")} ${data.months} ${t("months", "månedene")}, ${t("you pay", "så betaler du")} ${Math.floor(percentageToTakeOff * 149)}kr/mnd Per Bruker, så 149kr/mnd Per Bruker etter det.` :
                                    t("Per User", `Per Bruker: 149kr/mnd`)
                                }
                            </p>
                            <h2 className="fit-content orange">{t("Modules", "Moduler")}</h2>
                            <p>
                                {t("Modules: Per module is individual prices", "Moduler: Per Modul er det individe priser som blir lagt til i måneden")}.
                            </p>
                        </div>
                        <div className="column small-gap">
                            <h2 className="fit-content orange">{t("Invoicing", "Fakturering")}</h2>
                            {data?.freeMonth ?
                                <div className="column small-gap">
                                    <p>{t("The first month after signing is a free trial period", "Den første pågående måneden fra signering er gratis, og er en prøveperiode for EffektivJobb")}.</p>
                                    <p>{t("After this period is done, payments are made in advance", "Etter denne perioden er ferdig, så betaler du forskudds-basert for neste måneden")}.</p>
                                </div>
                                :
                                <div className="column small-gap">
                                    <p>{t("Payments are made in advance", "Betalinger blir gjort forskuddsvis")}.</p>
                                    <p>{t("The first payment is sent within 10 days of the agreement acceptance", "Første betaling blir sendt innen 10 dager fra avtalestart")}.</p>
                                </div>
                            }
                            <p>{t("If you don't want to continue with Effektivjobb, you have to cancel the subscription before the next month activates", "Hvis du ikke ønsker å fortsette med EffektivJobb, må du si opp abonnementet før den pågående måneden er ferdig.")}</p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={{ color: "var(--contrastColor)" }}>{t("Terms of use", "Brukervilkår")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <iframe src="https://effektivjobb.no/personvern/no-style" style={{
                        width: "100%",
                        height: 1000,
                        border: "none",
                    }}></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ color: "var(--contrastColor)" }}>{t("Rights", "Rettigheter")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Rights data={data} />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


export async function parseInput(inputString) {
    const data = await checkCouponCode(inputString);

    return { ...data };
}


export const Agreement = ({ data }) => {
    const { t } = useTranslation();

    if (data?.freeMonth) {
        <div className="row wrap small-gap">
            <p>{t("By signing you accept", "Ved å signere godtar du")} </p><p><a href="https://effektivjobb.no/personvern">{t("Brukervilkårene", "Brukervilkårene")}</a></p> <p>{t("to", "til")} EffektivJobb,</p>
            <p>{t("and that the 30 day trial starts from signing", "og at den 30 dagers prøveperioden starter fra signeringsdato")}.</p>
        </div>
    }

    return (
        <div className="row wrap small-gap">
            <p>{t("By signing you accept", "Ved å signere godtar du")} </p><p><a href="https://effektivjobb.no/personvern">{t("Brukervilkårene", "Brukervilkårene")}</a></p> <p>{t("to", "til")} EffektivJobb,</p>
        </div>
    )
}


function Rights({ data }) {

    if (data?.freeMonth) {
        return (
            <div className="column">
                <div className="column small-gap">
                    <h3>Firmaet kan kansellere avtalen under prøveperioden uten å bli belastet den kommende måneden.</h3>
                    <h3>En ny avtale kan bli skrivd i ettertid, med forskuddsbetaling.</h3>
                    <h3>Firmaet kan når som helst justere antall brukere og moduler, og kostnadene vil automatisk bli justert i henhold til dette.</h3>
                </div>
                <div className="column small-gap">
                    <h3>EffektivJobb kan kansellere avtalen med rimelig varsel.</h3>
                    <h3>EffektivJobb kan endre priser og vilkår med forhåndsvarsel.</h3>
                    <h3>EffektivJobb kan endre funksjoner og moduler med forhåndsvarsel.</h3>
                    <h3>EffektivJobb kan endre design og layout med rimelig varsel.</h3>
                    <h3>EffektivJobb kan endre funksjonalitet og tilgjengelighet med rimelig varsel.</h3>
                </div>
            </div>
        )
    }

    return (
        <div className="column">
            <div className="column small-gap">
                <h3>Firmaet kan kansellere avtalen med rimelig varsel.</h3>
                <h3>Firmaet kan når som helst justere antall brukere og moduler, og kostnadene vil automatisk bli justert i henhold til dette.</h3>
            </div>
            <div className="column small-gap">
                <h3>EffektivJobb kan kansellere avtalen med rimelig varsel.</h3>
                <h3>EffektivJobb kan endre priser og vilkår med forhåndsvarsel.</h3>
                <h3>EffektivJobb kan endre funksjoner og moduler med forhåndsvarsel.</h3>
                <h3>EffektivJobb kan endre design og layout med rimelig varsel.</h3>
                <h3>EffektivJobb kan endre funksjonalitet og tilgjengelighet med rimelig varsel.</h3>
            </div>
        </div>
    )
}