import { useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import TimeChooser from "../../components/timetracking/timeChooser";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { getProjects } from "../../firebase";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import CustomModal from "../../components/miniComponents/CustomModal";
import ButtonListButton from "../../components/miniComponents/buttonListButton";

function formatDate(date) {
    // Helper function to format a Date object to "YYYY-MM-DDTHH:MM"

    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = date.getFullYear();

    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    return yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes;


}


export default function Vernerunde() {

    const [skjema, setSkjema] = useState({
        sted: {},
        dato: new Date(),
        hendelse: "",
        resultat: "",
        årsak: "",
        tiltak: "",
        ansvarlig: "",
        frist: new Date(),
        oppfølging: "",
        status: "",
        kommentar: "",
        vedlegg: "",
        signatur: null,
    });

    const [prosjekter, setProsjekter] = useState([])
    const [projectModal, setProjectModal] = useState(false);

    useEffect(() => {
        //getProjects
        const onUpdate = (data) => {
            console.log(data);
            setProsjekter(data);
        }
        getProjects(onUpdate);
    }, [])

    return (
        <DefaultWrapper>
            <div className="content-ny column">
                <h1>RUH skjema</h1>
                <div className="column small-gap">
                    <h2>Sted</h2>
                    <div className="button-list">
                        <ButtonListButton onClick={() => setProjectModal(true)} title={skjema.sted.navn} description={skjema.sted.adresse} />
                    </div>
                </div>

                <div className="column small-gap">
                    <h2>Dato</h2>
                    <CustomInput noIcon={true} type="datetime-local" value={formatDate(skjema.dato)} onChange={(e) => setSkjema({ ...skjema, dato: new Date(e.target.value) })} />
                </div>

                <div className="column small-gap">
                    <h2>Beskriv Hendelsen</h2>
                    <CustomTextareaInput value={skjema.hendelse} onChange={(e) => setSkjema({ ...skjema, hendelse: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Hva er resultatet</h2>
                    <CustomTextareaInput value={skjema.resultat} onChange={(e) => setSkjema({ ...skjema, resultat: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Hva er årsaken til hendelsen</h2>
                    <CustomTextareaInput value={skjema.årsak} onChange={(e) => setSkjema({ ...skjema, årsak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Tiltak for å hindre slik uønsket hendelse igjen</h2>
                    <CustomTextareaInput value={skjema.tiltak} onChange={(e) => setSkjema({ ...skjema, tiltak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Send -post til:</h2>
                    <CustomTextareaInput value={skjema.email} onChange={(e) => setSkjema({ ...skjema, email: e.target.value })} />
                </div>

                <CustomModal visible={projectModal} items={prosjekter} onChoise={(e) => {
                    setSkjema({ ...skjema, sted: e });
                    setProjectModal(false);
                }} onClose={() => setProjectModal(false)} />
            </div>
        </DefaultWrapper>
    )

}