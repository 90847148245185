import { IonPage } from "@ionic/react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import React, { useContext } from "react";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import DefaultWrapper from "../../../components/defaultWrapper";
import { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import { generatePDFFromDocument, PDFPreview } from "../../../components/renderPDF";

import { PDFstyles } from "./styles";
import { Column, CommentField, DefaultPageFooter, DefaultPageInfo, Field, FlexApart, LongField, Row } from "./customPDFLibrary";
import { handleUploadPdf } from "../../../firebase";
import { CompanyContext } from "../../../contexts/companyContext";
import { UploadingContext } from "../../../contexts/loadingContext";
import { uploadHMSReport } from "../../../database/hms";
import { blobToBase64 } from "../../../components/exportToPdf";
import { useSelector } from "react-redux";




export default function RUHPDF() {
    const history = useHistory();
    const data = history.location.state;
    const { companyDetails } = useSelector(state => state.company);
    const { startUploadBar, countUp, endUploadBar } = useContext(UploadingContext);

    const navigateToNewPage = (path, state) => {
        history.push(path, state);
    }

    const send = async () => {
        startUploadBar(3, "Genererer PDF");
        const instance = await pdf(<MyDocument data={data} />);

        const pdfBlob = await instance.toBlob();
        countUp("Laster opp PDF");

        const pdfUrl = await handleUploadPdf(pdfBlob, "HMS/RUH", data.id);


        countUp("Sender Emailer");
        const newReport = {
            ...data,
            pdfUrl,
            company: companyDetails
        }

        console.log(newReport);
        await uploadHMSReport(newReport)
        endUploadBar();
        localStorage.removeItem("currentRUH");
        navigateToNewPage("/HMS", { tab: "RUH" });
    }

    return (
        <DefaultWrapper>
           
                <button className="bottom-right-button" onClick={send}>Send</button>
                <PDFPreview document={<MyDocument data={data} />} />
           
        </DefaultWrapper>
    );
}


const MyDocument = ({ data }) => {
    console.log(data);

    return (
        <Document>
            <Page
                //landscape={true}
                size="A4"
                //orientation="landscape"
                style={PDFstyles.page}>

                <DefaultPageInfo id={data.id} />
                <DefaultPageFooter />

                <View style={[PDFstyles.column, PDFstyles.gap]}>
                    <FlexApart direction="row">
                        <Column style={[PDFstyles.centerHeight]}>
                            <Text style={[PDFstyles.text, PDFstyles.textBold, PDFstyles.h1]}>RUH Rapport</Text>
                            <Text style={[PDFstyles.text, PDFstyles.h2]}>Rapport om uønsket hendelse</Text>
                        </Column>
                        {data?.logo &&
                            <Image src={data?.logo} style={PDFstyles.logo} />
                        }
                    </FlexApart>


                    <Field label="Prosjekt" value={(data?.prosjekt?.navn || "") + " " + (data?.prosjekt?.nummer || "")} />

                    <Row gap>
                        <LongField label="Dato og klokkeslett" value={new Date(data?.dato).toLocaleDateString() + " " + new Date(data?.dato).toLocaleTimeString()} />
                        <LongField label="Sted" value={(data?.sted?.addresse || data?.sted?.navn || "") + " " + (data?.sted?.by || "")} />
                    </Row>

                    <Column gap>
                        <CommentField break label="Beskrivelse av hendelsen" value={data?.hendelse} />
                        <CommentField break label="Hva er resultatet" value={data?.resultat} />
                        <CommentField break label="Årsak til hendelsen" value={data?.årsak} />
                        <CommentField break label="Tiltak for å hindre slik uønsket hendelse igjen" value={data?.tiltak} />
                    </Column>
                </View>
            </Page>
        </Document>
    )
};