




// authSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { firebaseLogout } from '../firebase';
import { resetCompany } from './companySlice';

const initialState = {
  user: null,
  companyUser: null,
  isAuthenticated: false,
};

export const logoutUser = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  console.log("Got executed")
  console.log("Logged out in thunk")
  await firebaseLogout();
  dispatch(logout()); // Calls the synchronous logout action
  dispatch(resetCompany())
});

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      console.log("Logged out in slice")
    },

    setCompanyUser: (state, action) => {
      state.companyUser = action.payload;
    },
  },
});

export const { loginSuccess, logout, setCompanyUser } = authSlice.actions;
export default authSlice.reducer;
