// store/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';


const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        //byId: {},
        allIds: [],
    },
    reducers: {
        addSubscription: {
            reducer:
                (state, action) => {
                    const { path } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.allIds.includes(path)) return;
                    state.allIds.push(path);
                },
            prepare: ({ path, subscription }) => {
                return {
                    payload: { path, subscription }
                };
            },
        },
        removeSubscription: (state, action) => {
            //console.log(action.payload);
            const path = action.payload;
            state.allIds = state.allIds.filter(id => id !== path);
        },
    },
});


export const { addSubscription, removeSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
