import CustomModal from "../../miniComponents/CustomModal";
import { useEffect, useState } from "react";
import "../../../css/custom-components/dropdown.css";
import { ArrowDownward } from "@mui/icons-material";


export default function Dropdown({ label, adaptToChoise, icon, title, items=[], onChange, value }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [info, setInfo] = useState({
        title: title || label,
        chosenItemId: null
    });

    useEffect(() => {
        if (info?.chosenItemId) return;
        if (!value) return;
        if (!value?.name) return;
        setInfo(old => { return {chosenItemId: value.id, title: value.name} });
    }, [value]);

    useEffect(() => {
        if (info?.chosenItemId) return;
        if (items.length === 0) return;
        if (value) return setInfo(old => { return { ...old, chosenItemId: value.id } });
        setInfo(old => { return { ...old, chosenItemId: items[0], title: items[0]?.name } });
        onChange(items[0]);
    }, [items]);

    return (
        <div>
            <button type={"button"} className="dropdown-button" onClick={() => {
                console.log(info);
                setModalVisible(true)
            }}>
                <div className="row relative center-column stretch-width">
                    <label className="dropdown-label">{label}</label>
                    <p>{info.title}</p>
                    <ArrowDownward />
                </div>

            </button>
            <CustomModal items={items} visible={modalVisible}
                onChoise={(e) => {
                    if (onChange) onChange(e);
                    setInfo(old => { return { ...old, chosenItemId: e.id, title: e.name } });
                }}
                adaptToChoise={adaptToChoise}
                onClose={() => {
                    setModalVisible(false);
                }}
                title={title}
                label={label}
            />
        </div>
    )
}