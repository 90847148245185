
import React, { useState, useRef, useContext } from 'react';
import { createReport, deleteReport, getClients, getCompanyDetails, getFirmSettings, getNotSentReports, getProjects, getProjectsByCompanyId, getReportById, getReportNumber, getSentReports, getUtstyr, handleUpload, handleUploadFile, setReportFirebase, setReportNumber, updateReviewReport } from '../../firebase';
//import RapportCard from '../components/rapport-card';
//import ClientModal from '../components/aiChooseFirma';
import { useEffect } from 'react';
//import KontaktPersonModal from '../components/kontaktPerson';
//import Borring from '../components/oppgaver/borring';
//import generatePDF from './pdfPreview2';
import { IonAlert, IonButton, IonContent, IonHeader, IonModal, IonPage, IonToolbar, useIonToast } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import SelectionModal from '../../components/selectionModal';
import Borring from '../../components/oppgaver/borring';
import SelectOppgave from '../../components/selectOppgave';
import Saging from '../../components/oppgaver/saging';
import OppgaveAnnet from '../../components/oppgaver/annet';
import { SearchWithPopup } from '../../components/searchWithPopups/searchWithPopup';
import ReportUtstyr from '../../components/oppgaver/ReportUtstyr';
import DefaultWrapper from '../../components/defaultWrapper';
import ButtonListButton from '../../components/miniComponents/buttonListButton';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';


import { useSpring, animated, useTrail } from '@react-spring/web';
import BigButton from '../../components/bigButton';
import { HeaderContext, ReportContextCreation } from '../../App';

import { ReactComponent as UserIcon } from '../../assets/icons/material-symbols_person.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/material-symbols_bar-chart-4-bars-rounded.svg';
import { ReactComponent as OfficeIcon } from '../../assets/icons/vaadin_office.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/mdi_delete.svg';
import DraggableList from '../../components/miniComponents/draggableList';
import { _ } from 'lodash';
import { Capacitor } from '@capacitor/core';
import CustomDropdown from '../../components/miniComponents/customDropdown';
import CustomModal from '../../components/miniComponents/CustomModal';
import { useTranslation } from 'react-i18next';
import CustomTextareaInput from '../../components/miniComponents/customTextareaInput';
import { createDateOutOfFirebaseTimestamp } from '../timeføring/timeføring';

export default function Rapport(props) {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [utstyr, setUtstyr] = useState([]);

    const history = useHistory();
    const params = useParams();
    const [deleteAlert, setDeleteAlert] = useState(false);


    //console.log(history.location.state);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        const update = (querySnapshot) => {
            const clientList = [];
            querySnapshot.forEach((doc) => {
                const clientData = doc.data();
                clientList.push(clientData);
            });
            setClients(clientList);
        };

        const fetchClients = async () => {
            const clients = await getClients(update);
            setClients(clients);
        };
        fetchClients();
    }, []);
    //console.warn(props.route.params.report.report.id);

    //console.log(history.location.state);

    //console.log(history.location.state);
    //const { name, arbeidsplass, by, postnummer } = props?.route?.params?.report?.report?.firma;
    //const id = props.route.params.report.report.id;

    const [modalVisible, setModalVisible] = useState(false);
    const [kontaktPersonModal, setKontaktPersonModal] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const [oppgaveModal, setOppgaveModal] = useState(false);
    const { reportContext, setReportContext } = useContext(ReportContextCreation);
    const [report, setReport] = useState({
        firma: {
            navn: "",
            arbeidsplass: "",
            by: "",
            postnummer: "",
            kontaktpersoner: [],
            kontaktperson: "",
            projekt: {
                navn: "",
                id: "",
            },
            projekter: [],
        },
        id: "",
        status: {
            navn: "Ikke sendt",
            farge: "red",
        },
        oppgaver: [],
        annet: "",
        utstyr: [],
        mal: history.location.state?.oppgaveMal,
        filer: [],
        projekt: {
            navn: "",
            id: "",
        },
    });
    const fileInput = useRef(null);

    const [kontaktpersoner, setKontaktpersoner] = useState([]);

    const [canDrag, setCanDrag] = useState(true);

    const [rapportInstillinger, setRapportInstillinger] = useState({
        statuser: [],
    });

    useEffect(() => {
        let newKontaktPersoner = [];
        if (reportContext?.firma?.kontaktpersoner) {
            newKontaktPersoner = [...reportContext?.firma?.kontaktpersoner];
        }
        if (reportContext?.projekt?.kontaktpersoner) {
            newKontaktPersoner = [...newKontaktPersoner, ...reportContext?.projekt?.kontaktpersoner];
        }

        setKontaktpersoner(newKontaktPersoner);
    }, [reportContext?.firma?.kontaktpersoner, reportContext?.projekt?.kontaktpersoner])

    useEffect(() => {
        async function getStatuses() {
            const instillinger = await getFirmSettings("rapportInstillinger");
            console.log(instillinger);
            setRapportInstillinger(instillinger);
            console.log(reportContext?.status)
            if ((reportContext?.status === null || reportContext?.status === undefined) && instillinger?.defaultStatus) {
                setReportContext((old) => {
                    return { ...old, status: instillinger?.defaultStatus }
                })
            }
        }
        getStatuses();
    }, []);

    // reportContext updates the local report, give it "lastUpdated" and save it to local storage
    useEffect(() => {
        const newReport = { ...reportContext };
        newReport.lastUpdated = new Date();
        //setReport(newReport);

        //get reports from local storage
        let localRapporter = JSON.parse(localStorage.getItem("localRapporter"));

        // add or overwrite report in local storage
        if (!localRapporter) {
            localRapporter = [];
        }
        const index = localRapporter.findIndex((e) => e.id === newReport.id);
        if (index === -1) {
            localRapporter.push(newReport);
        } else {
            localRapporter[index] = newReport;
        }

        // save to local storage
        localStorage.setItem("localRapporter", JSON.stringify(localRapporter));
        //localStorage.setItem("localRapporter", JSON.stringify(newReport));
        //setReportContext(newReport);
    }, [reportContext]);

    useEffect(() => {
        async function checkReportsThenupdate() {
            console.log(reportContext.id);

            console.log(params.id)
            const report = await fetchAndUpdateReport(params.id);
            if (params.id === reportContext.id) return; // If already updated, skip
            if (!report) return;
            if (createDateOutOfFirebaseTimestamp(report.lastUpdated) < createDateOutOfFirebaseTimestamp(reportContext.lastUpdated)) return;
            console.log("Report updated");
            setReportContext(report);
        }

        checkReportsThenupdate();
    }, [reportContext.id]);


    const [projekter, setProjekter] = useState([]);

    console.log(projekter)
    useEffect(() => {
        async function getDetails() {
            const details = await getCompanyDetails()

            setReportContext((old) => {
                return {
                    ...old,
                    sendingFirmDetails: details
                }
            })
        }
        getDetails()
    }, [])

    useEffect(() => {
        const update = (data) => {
            // Filter to only projects that have end-date in the future, or no end-date
            setProjekter(data.filter((e) => {
                if (!e.sluttDato) return true;
                const now = new Date();
                const endDate = new Date(e.sluttDato);
                return endDate > now;
            }));
        };

        getProjects(update);
    }, []);

    const id = reportContext.id;

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value; //assign the value of ref to the argument
        }, [value]); //this code will run when the value of 'value' changes
        return ref.current; //in the end, return the current ref value.
    }

    // Every time Report updates, wait 5 seconds to see if more updates are coming, then save to firebase
    const [lastUpdateTime, setLastUpdateTime] = useState(new Date());

    useEffect(() => {
        console.warn("Report checking for update");

        const timeout = setTimeout(() => {
            console.warn("Report update check");

            if (!reportContext || reportContext.id === "") return;

            // Check if the lastUpdateTime is more than 5 seconds ago
            const now = new Date();
            const timeDiff = now - lastUpdateTime;

            if (timeDiff < 1000) {
                console.warn("Last updated less than 5 seconds ago. Skipping update.");
                return;
            }

            // Update lastUpdateTime
            setLastUpdateTime(now);

            // Now update the reportContext with the new lastUpdated time
            if (!reportContext.id) return
            let tempContext = { ...reportContext, lastUpdated: now };

            // Your other conditions and update logic
            if (reportContext.status === "needToBeAccepted") {
                updateReviewReport(tempContext);
            }
            console.log(tempContext)
            setReportFirebase(tempContext);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [reportContext]); // Depends on reportContext





    //console.log(context);

    useEffect(
        () => {
            async function getReportByIdFromFirebase() {
                console.warn("id: " + id);
                const reportById = await getReportById(id);
                console.warn(reportById);
                setReport(reportById);
            }
            console.warn("id: " + id);
            if (!id) return;
            getReportByIdFromFirebase()
        }, [id]
    );

    const updateOppgaver = (oppgave) => {
        const oldReports = { ...reportContext };
        const updatedOppgaver = oldReports.oppgaver.map((oppgaveToFind) =>
            oppgaveToFind.id === oppgave.id ? oppgave : oppgaveToFind
        );
        const newReports = { ...oldReports, oppgaver: updatedOppgaver };
        console.warn(oppgave);
        console.warn(newReports);
        setReportContext(newReports);
    };


    // get utstyr from firebase
    useEffect(() => {
        const onUpdate = (snapshot) => {
            const data = snapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
            console.log(data)
            setUtstyr(data)
        }
        async function getUtstyrFromFirebase() {
            const data = await getUtstyr(onUpdate)
            setUtstyr(data)
        }
        getUtstyrFromFirebase()
    }, [])


    const [present] = useIonToast();

    const AlertTop = () => {
        present({
            cssClass: 'toast-ccc',
            message: 'Saving..',
            duration: 1500,
            position: "top"
        });
    };


    useEffect(() => {
        if (history?.location?.state?.updateRapport) {
            history.location.state.updateRapport = false;
            //AlertTop();
        }
    }, [])

    console.log(reportContext)
    if (reportContext === undefined || !reportContext) {
        console.log(reportContext)
        return (<div></div>);
    }

    function newOppgave() {
        setReportContext((old) => {
            return {
                ...old, oppgaver: [
                    ...old.oppgaver,
                    {
                        title: `${t("Task", "Oppgave")} ${old.oppgaver.length + 1}`,//oppgave.title,
                        id: randomId(),
                        type: randomId(),
                        icon: "fas fa-file-alt",
                        //TODO: FELLES NEEDS TO CHANGE FOR FELLES ID

                        oppgaveEmner: reportContext.mal.oppgaver.map(opg => {
                            return {
                                ...opg,
                                id: randomId(),
                                type: opg.id,
                                dato: new Date,
                                underAlignments: [
                                    {
                                        id: randomId(),
                                        tasks: [...opg.underAlignments]
                                    }
                                ]
                            }
                        })
                    }
                ]
            }
        });
    }

    console.log(reportContext)

    if (!reportContext.id) return <DefaultWrapper />

    return (
        <DefaultWrapper lagring={true} header={false} onBack={() => setReportFirebase(reportContext)} extraButton={<button id="present-alert" onClick={() => setDeleteAlert(true)}>Slett Rapport <DeleteIcon /></button>}>
            <IonAlert
                isOpen={deleteAlert}
                header={t("Delete report", "Slette Rapport")}
                message={t("Are you sure you want to delete this report", "Er du sikker på at du vil slette denne rapporten? Bare Admins kan hente den tilbake.")}
                trigger="present-alert"
                buttons={[
                    {
                        text: t("Cancel", "Avbryt"),
                        role: 'cancel',
                        handler: () => {
                            console.log('Alert canceled');
                            setDeleteAlert(false);
                        },
                    },
                    {
                        text: t("Ok", "OK"),
                        role: 'confirm',
                        handler: async () => {
                            await deleteReport(reportContext);
                            setDeleteAlert(false);
                            console.log('Alert confirmed');
                            history.goBack();
                        },
                    },
                ]}
                onDidPresent={() => console.log('did present')}
                onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
            ></IonAlert>

            <div className="content-ny column big-gap" style={{
                pointerEvents: canDrag ? 'auto' : 'none',
            }}>
                <div className="client-container column">
                    <div className="flexApart">
                        <h1>{t("Report", "Rapport")}</h1>
                        <div className="row">
                            {rapportInstillinger?.brukStatus ?
                                <CustomDropdown options={rapportInstillinger?.statuser} defaultValue={rapportInstillinger?.defaultStatus}
                                    selected={reportContext?.status} onChange={(e) => setReportContext((old) => {
                                        return { ...old, status: e }
                                    })} label={"Status"}></CustomDropdown> : null
                            }
                            <h1>#{id}</h1>
                        </div>
                    </div>

                    <div className='firma-area row-size-dependant gap small-gap'>


                        <div className='column small-gap'>
                            <h3>{t("Project", "Prosjekt")}</h3>
                            <ButtonListButton icon={ProjectIcon} onClick={() => setProjectModal(true)}>
                                <div className="row flexApart stretch-width">
                                    <p>{reportContext?.projekt?.navn || reportContext?.prosjekt?.navn || t("No Project selected", "Ingen prosjekt valgt")}</p>
                                    <h2>▼</h2>
                                </div>
                            </ButtonListButton>
                        </div>


                        <div className='column small-gap'>
                            <h3>{t("Customer", "Kunde")}</h3>
                            <ButtonListButton className='row center-column selection padding flexApart' icon={OfficeIcon} onClick={() => setModalVisible(true)}>
                                <div className='row flexApart'>
                                    <div className="column noGap">
                                        <p>{reportContext?.firma?.navn || t("No Customer selected", "Ingen kunde valgt")}</p>
                                        <p>{reportContext?.firma?.arbeidsplass}</p>
                                        <p>{reportContext?.firma?.by}</p>
                                    </div>
                                    <h2>▼</h2>
                                </div>
                            </ButtonListButton>
                        </div>


                        <div className='column small-gap'>
                            <h3>{t("Contact person", "Kontaktperson")}</h3>
                            <ButtonListButton icon={UserIcon} onClick={() => setKontaktPersonModal(true)}>
                                <div className="row flexApart">
                                    <p>{reportContext?.kontaktperson?.navn || t("No Contact Person selected", "Ingen kontaktperson valgt")}</p>
                                    <h2>▼</h2>
                                </div>
                            </ButtonListButton>
                        </div>

                    </div>
                </div>

                <div className="oppgaver-container column" style={{ position: "relative" }}>
                    <div className="flexApart row">
                        <h1>{t("Tasks", "Oppgaver")}</h1>
                        <button onClick={() => { newOppgave() /*setOppgaveModal(true);*/ }}>{t("Add task", "Legg til oppgave")}</button>
                    </div>


                    <div onPointerDown={(e) => {
                        // if within the 100px of the right side of the screen, dont drag
                        if (e.clientX > window.innerWidth - 100) {
                            setCanDrag(false)
                        }
                        console.log("mouse down")
                    }} onPointerUp={(e) => {
                        setCanDrag(true)
                        console.log("mouse up")
                    }}
                        onPointerLeave={(e) => {
                            setCanDrag(true)
                            console.log("mouse leave")
                        }}
                        style={{ height: "fit-content" }}>
                        <DraggableList
                            onOrderChange={(newOrder) => {
                                console.log(newOrder);
                                setReportContext((old) => {
                                    return { ...old, oppgaver: newOrder }
                                });
                            }}
                            items={reportContext?.oppgaver}
                            onClickItem={(oppgave) => {
                                navigateToNewPage(`/rapport/${reportContext.id}/oppgave/` + oppgave.id, { oppgave: oppgave, report: reportContext });
                            }}
                        />
                    </div>
                </div>

                <div className="annet-container column">
                    <h1>{t("Equipment", "Utstyr")}</h1>
                    <ReportUtstyr addedUtstyr={reportContext.utstyr} items={utstyr} onChange={
                        (e) => {
                            setReportContext((old) => {
                                return { ...old, utstyr: e }
                            })
                        }
                    }></ReportUtstyr>
                </div>

                <div className="annet-container column">
                    <h1>{t("Miscellaneous", "Annet")}</h1>
                    <div className="annet-area">
                        <CustomTextareaInput
                            value={reportContext.annet}
                            onChange={(e) => setReportContext((old) => {
                                return { ...old, annet: e.target.value }
                            })}
                        />
                    </div>
                </div>

                <div className="annet-container column">
                    <h1>{t("Documents", "Dokumenter")}</h1>
                    <div className="annet-area column padding input-background" style={{ borderRadius: 20 }}>
                        {reportContext?.filer?.map((file) => (
                            <div className="row flexApart">
                                <p>{file.name}</p>
                                <button onClick={() => {
                                    setReportContext((old) => {
                                        return { ...old, filer: old.filer.filter((e) => e.id !== file.id) }
                                    })
                                }
                                }>X</button>
                            </div>
                        ))}
                        <input type="file"
                            multiple={true}
                            ref={fileInput} onChange={(e) => {
                                console.log(fileInput.current.files)
                                Array.from(fileInput.current.files).map((file) => {
                                    //fileInput.current.files.map((file) => {
                                    //const file = fileInput.current.files[0];

                                    async function makeProcessedFiles2() {
                                        const url = await handleUploadFile(file, reportContext.id);
                                        const newFile = { name: file.name, url: url, id: randomId() };
                                        console.log(newFile)
                                        console.log(reportContext)


                                        setReportContext((old) => {
                                            return { ...old, filer: [...old.filer, newFile] }
                                        })
                                    }
                                    makeProcessedFiles2()
                                })
                            }} />

                        <button onClick={() => fileInput.current.click()}>{t("Add File", "Legg til fil")}</button>
                    </div>
                </div>

                <div className="buttons-container row flexApart">
                    <div></div>
                    <button onClick={async () => {
                        await setReportFirebase(reportContext);
                        navigateToNewPage("/reportPreview", { ...reportContext, preview: true });
                    }}>{t("Look Over", "Se over")}</button>
                </div>



                <CustomModal visible={modalVisible} items={clients} onChoise={(e) => setReportContext(
                    (old) => {
                        return { ...old, firma: e }
                    }
                )} onClose={() => setModalVisible(false)} onNew={
                    () => {
                        setModalVisible(false)
                        navigateToNewPage('/manageClient', null)
                    }
                } />



                <CustomModal visible={kontaktPersonModal} items={
                    kontaktpersoner
                } onChoise={(e) => setReportContext(
                    (old) => {
                        return { ...old, kontaktperson: e }
                    }
                )} onClose={() => setKontaktPersonModal(false)} onNew={
                    () => {
                        setKontaktPersonModal(false)
                        navigateToNewPage('/manageClient', reportContext.firma)
                    }} />



                <CustomModal visible={projectModal} items={
                    projekter.filter((e) => e.firmaConnection === reportContext.firma.id).length > 0 ?
                        projekter.filter((e) => e.firmaConnection === reportContext.firma.id)
                        : projekter
                } onChoise={(e) => setReportContext(
                    (old) => {
                        return { ...old, projekt: e }
                    }
                )} onClose={() => setProjectModal(false)} onNew={
                    () => {
                        setProjectModal(false)
                        navigateToNewPage('/prosjekt/edit/', reportContext.firma)
                    }
                } />
            </div >

        </DefaultWrapper >
    );
}

function randomId() {
    return Math.random().toString(36).substr(2, 9);
}






async function fetchAndUpdateReport(reportId) {
    const localReport = getLocalReport(reportId);
    const onlineReport = await getReportById(reportId);

    if (!localReport && !onlineReport) {
        // Handle case where the report does not exist both locally and online
        return;
    }

    if (!localReport) {
        // No local report, use online version
        //saveToLocal(onlineReport);
        return onlineReport;
    }

    if (!onlineReport) {
        // No online report, possibly use local version or handle error
        return localReport;
    }

    const localUpdateTime = new Date(localReport.lastUpdated);
    const onlineUpdateTime = new Date(onlineReport.lastUpdated);

    if (onlineUpdateTime > localUpdateTime) {
        // Online version is more recent
        //saveToLocal(onlineReport);
        return onlineReport;
    } else {
        // Local version is up to date or more recent
        //updateOnlineVersion(reportId, localReport); // Make sure to implement this function
        return localReport;
    }
}

function getLocalReport(reportId) {
    const localReports = JSON.parse(localStorage.getItem("localReports"));
    if (!localReports) return null;
    return localReports.find((e) => e.id === reportId);
}