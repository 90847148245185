import { useState } from "react";
import CustomModal from "../../../components/miniComponents/CustomModal";
import CustomInput from "../../../components/miniComponents/customInput";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { Delete } from "@mui/icons-material";
import FillOnEmptyInput from "../../../components/miniComponents/fillOnEmptyInput";




export default function SJAline({ line, skjema, setSkjema }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="column">
            <div className="row">
                <ButtonListButton title={line?.beskrivelse} description={line?.kontroll ? "Kontroll: " + line?.kontroll : " "} onClick={() => setOpen(true)} />

                <button type="button" style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        setSkjema(old => {
                            const oldLines = [...old?.lines] || [];
                            const newLines = oldLines.filter(l => l.id !== line.id);
                            return { ...old, lines: newLines };
                        })
                    }}><Delete /></button>
            </div>


            <CustomModal title="SJA" open={open} setOpen={setOpen} onClose={
                () => {
                    setOpen(false);
                }
            } noSearch={true}>
                <h2>Beskrivelse av arbeidet</h2>
                <FillOnEmptyInput value={line?.beskrivelse}
                    placeholderValue={"Ny Oppgave"}
                    onChange={(e) =>
                        setSkjema(old => {
                            console.log(old)
                            const oldLines = [...old?.lines] || [];
                            const index = oldLines.findIndex(l => l.id === line.id);
                            oldLines[index] = { ...line, beskrivelse: e.target.value };
                            return { ...old, lines: oldLines };
                        })}></FillOnEmptyInput>

                <h2>Hva kan gå galt?</h2>
                <CustomInput noIcon={true} type="text" value={line?.farer} onChange={(e) =>
                    setSkjema(old => {
                        const oldLines = [...old?.lines] || [];
                        const index = oldLines.findIndex(l => l.id === line.id);
                        oldLines[index] = { ...line, farer: e.target.value };
                        return { ...old, lines: oldLines };
                    })}
                />

                <h2>Hvilke tiltak kan iverksettes?</h2>
                <CustomInput noIcon={true} type="text" value={line?.tiltak} onChange={(e) =>
                    setSkjema(old => {
                        const oldLines = [...old?.lines] || [];
                        const index = oldLines.findIndex(l => l.id === line.id);
                        oldLines[index] = { ...line, tiltak: e.target.value };
                        return { ...old, lines: oldLines };
                    })}
                />

                <h2>Hvor god kontroll har vi på farene</h2>
                <div className="row">
                    <button type="button" className={line?.kontroll === "God" ? "good" : ""}
                        onClick={() =>
                            setSkjema(old => {
                                const oldLines = [...old?.lines] || [];
                                const index = oldLines.findIndex(l => l.id === line.id);
                                oldLines[index] = { ...line, kontroll: "God" };
                                return { ...old, lines: oldLines };
                            })}>God 😃</button>
                    <button type="button" className={line?.kontroll === "Delvis" ? "orange-card" : ""}
                        onClick={() =>
                            setSkjema(old => {
                                const oldLines = [...old?.lines] || [];
                                const index = oldLines.findIndex(l => l.id === line.id);
                                oldLines[index] = { ...line, kontroll: "Delvis" };
                                return { ...old, lines: oldLines };
                            })}>Delvis 😐</button>
                    <button type="button" className={line?.kontroll === "Dårlig" ? "recording" : ""}
                        onClick={() =>
                            setSkjema(old => {
                                const oldLines = [...old?.lines] || [];
                                const index = oldLines.findIndex(l => l.id === line.id);
                                oldLines[index] = { ...line, kontroll: "Dårlig" };
                                return { ...old, lines: oldLines };
                            })}>Dårlig 🙁</button>
                </div>

                <h2>Ansvarlig</h2>
                <div className="row wrap">
                    {
                        skjema?.deltagere.map((person, index) => {
                            return (
                                <button type="button" className={line?.ansvarlige?.includes(person) ? "good" : ""} key={index} onClick={() => {
                                    if (line.ansvarlige.includes(person)) {
                                        setSkjema(old => {
                                            const oldLines = [...old?.lines] || [];
                                            const index = oldLines.findIndex(l => l.id === line.id);
                                            oldLines[index] = { ...line, ansvarlige: line.ansvarlige.filter(a => a !== person) };
                                            return { ...old, lines: oldLines };
                                        }
                                        )
                                    } else {
                                        setSkjema(old => {
                                            const oldLines = [...old?.lines] || [];
                                            const index = oldLines.findIndex(l => l.id === line.id);
                                            oldLines[index] = { ...line, ansvarlige: [...line.ansvarlige, person] };
                                            return { ...old, lines: oldLines };
                                        })
                                    }
                                }}> {person.name}</button>
                            )
                        })
                    }
                </div>

            </CustomModal>
        </div >
    )
}