import { useContext, useEffect } from "react"
import { UserContext } from "../App";
import { CompanyContext } from "./companyContext";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";






export default function NavigationCX (props) {
    const companyId = useSelector(state => state.company.companyId)
    const user = useSelector(state => state.user.user);
    const history = useHistory();

    const navigateToNewPage = (path, information) => {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        if (!user) return navigateToNewPage("/login");
        if (!companyId) return navigateToNewPage("/chooseCompany");
        const badPaths = ["/login", "/company", "glemt-passord"];
        if (companyId && user && badPaths.includes(history.location.pathname)) {
            navigateToNewPage("/hjem");
        }
    }, [JSON.stringify(companyId), JSON.stringify(user), JSON.stringify(history.location.pathname)])

    return (
        <div>
            {props.children}
        </div>
    )
}
