import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";


export default function ButtonOnlyHitOnce(props) {
    const [clicked, setClicked] = useState(false);
    const [disabled, setDisabled] = useState(false); // This is to prevent form non-submits

    useEffect(() => {
        setTimeout(() => {
            setDisabled(old => clicked);
        }, 100);
    }, [clicked])

    return (
        <button 
            disabled={disabled}
            {...props}
            type={props.type || "button"}
            onClick={async () => {
                if (clicked) return;
                console.log("Clicked")
                
                setClicked(true);
                await props?.onClick();
                if (props.type === "submit") {
                    setTimeout(() => {
                        setClicked(false);
                    }, 5000);
                    return;
                }
                console.log("onClick")
                //setClicked(false);
            }}
        >
            {props.children}
            {clicked ? <CircularProgress className="circular-progress-button" size={20} /> : null}
        </button>
    );
}