import React, { useState, useEffect, useRef } from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';

export default function CustomDropdown({ options = [], selected, onChange, noBackground, label, defaultValue, stretch }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const buttonRef = useRef(null);
  console.log(options)
  console.log(selected)
  console.log(defaultValue)

  useEffect(() => {
    // Determine if the current selection or default value is in the options
    let initialValue = options.find(option => option.id === defaultValue) || options.find(option => option.id === defaultValue?.id)
    if (!initialValue && selected) {
      initialValue = options.find(option => option.id === selected.id);
    }

    if (selected) return setSelectedOption(selected);

    setSelectedOption(initialValue || null);
  }, [options, defaultValue, selected]);

  const handleSelectionChange = (e) => {
    const newSelectedId = e.detail.value;
    const newSelection = options.find(option => option.id === newSelectedId);

    // Update only if selection changes
    if (newSelection && newSelection.id !== selectedOption?.id) {
      setSelectedOption(newSelection);
      onChange(newSelection);
    }
  };

  return (
    <div ref={buttonRef} className={'customDropdown row center relative ' + (noBackground ? 'noBackground' : '') + (stretch ? ' stretch-width' : '')} 
         onClick={() => buttonRef.current?.querySelector('ion-select')?.open()}>
      <label className='dropdown-label'>{label}</label>
      <IonSelect
        value={selectedOption?.id} // Use state-managed selected option
        onIonChange={handleSelectionChange}
        interface="action-sheet"
        className="stretch-width"
      >
        {options.map((option, index) => (
          <IonSelectOption key={index} value={option.id}>
            {option.name || option.label || option.navn || option.title || option.value}
          </IonSelectOption>
        ))}
      </IonSelect>
      ▼
    </div>
  );
}
