import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc } from 'firebase/firestore';
import { db, app, getCompany, getCompanyId, companyId } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';








// Create a new company
export async function checkCouponCode(string) {
    const functions = getFunctions(app, 'europe-west2');
    const checkSalesCode = httpsCallable(functions, 'checkCouponCode');
    const data = await checkSalesCode({ couponCode: string });
    if (data?.error) {
        throw new Error(data.error);
    }
    return data;
}

export async function getRoles(params, onUpdate) {
    // Get an array of the roles in the company
    const q = query(collection(db, "Kompanier", companyId(), "roles"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const roles = [];
        querySnapshot.forEach((doc) => {
            roles.push(doc.data());
        });
        onUpdate(roles);
    });

    // return roles
    const documents = await getDocs(q);
    const roles = [];
    documents.forEach((doc) => {
        roles.push(doc.data());
    });
    return {value: roles, subscription: unsubscribe};
}


export async function getThisCompany(onUpdate) {
    const companyDocRef = doc(db, 'Kompanier', companyId());
    const companyDoc = await getDoc(companyDocRef);


    if (onUpdate) {
        const unsubscribe = onSnapshot(companyDocRef, (snapshot) => {
            onUpdate(snapshot.data())
        });
    }

    return companyDoc.data();
}



export async function setCompanyDetails(details) {
    const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'details');
    await setDoc(companyDocRef, details);
}

export async function getCompanyDetails() {
    if (!companyId()) return null;
    const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'details');
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.data();
}


// Settings
export function SaveFirmSettings(settings, saveToString) {
    console.log("SaveFirmSettings")
    console.log(settings)
    setDoc(doc(db, "Kompanier", companyId(), "instillinger", saveToString), settings);
}

export async function getFirmSettings(type, onUpdate) {
    console.log("getFirmSettings")
    if (!type) return null;

    if (!companyId()) return null;


    // Get document
    const docRef = doc(db, "Kompanier", companyId(), "instillinger", type);
    const docSnap = await getDoc(docRef);

    //listen for updates

    if (typeof onUpdate === "function") {
        const unsubscribe = onSnapshot(docRef, (doc) => {
            onUpdate(doc.data());
        });
    }

    return docSnap.data();
}