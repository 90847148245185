

import BackgroundImage from '../../assets/Background.png';

export default function Background(props) {
    return (
        <div className='background-holder'>
            <img src={BackgroundImage} alt="background"></img>
            <div className="background-diffuser"></div>
        </div>
    );
}