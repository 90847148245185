import { IonContent, IonHeader, IonModal, IonPage, IonToolbar, LocationHistory } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import { createId, createProject, getClientById, getDocumentsByNoProjectId, getProjects, getProjectsByCompanyId } from "../../../firebase";
import { useEffect, useState } from "react";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import CustomInput from "../../../components/miniComponents/customInput";

import { ReactComponent as WorkIcon } from '../../../assets/icons/material-symbols_work.svg';
import { SearchAddressesWithPopup } from "../../../components/miniComponents/searchAddresses";
import { Add, AddRounded, PlusOneRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


export default function ProjectList(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [noIdDocuments, setNoIdDocuments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    //console.log(information.projekter)

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    useEffect(() => {
        const onUpdate = (data) => {
            setProjects(data)
        }

        getProjects(onUpdate)
    }, [])

    return (
        <DefaultWrapper søkefelt={true} updateFilteredItems={(filteredBySearchProjects) => {
            const filterAwayProjectsWithEndDatesDone = filteredBySearchProjects.filter((project) => {
                
                if (new Date(project?.sluttDato) < new Date()) {
                    return false
                }
                return true
            })
            setFilteredProjects(filterAwayProjectsWithEndDatesDone)
        }} itemsToFilter={projects} doNotShowScoreUnderZero={true}>
            <div className="content-ny column big-gap">
                <div className='flexApart'>
                    <h1>{t("Projects", "Prosjekter")}</h1>
                </div>

                <button className="btn btn-primary bottom-right-header-button"
                    onClick={() => {
                        console.log(history.location.state)
                        navigateToNewPage('/prosjekt/edit')
                    }}

                >{t("New Project", "Nytt Prosjekt")} <AddRounded /></button>

                <div className='downContainer column big-gap button-list'>
                    {filteredProjects?.map((project) => {
                        const numberText = project?.nummer ? project?.nummer + " - " : "";
                        return <ButtonListButton title={numberText + project.navn} description={project.addresse + (project.postnummer ? ", " + project.postnummer : "")}
                            icon={WorkIcon} key={project.id} className="row" onClick={
                                () => navigateToNewPage(`/prosjekter/${project.id}`, { projekt: project })
                            } />
                    })}
                    {noIdDocuments.length > 0 && <ButtonListButton title='Uten prosjekt' description='Dokumenter uten prosjekt' icon={WorkIcon} key='noId' className="project column" onClick={
                        () => navigateToNewPage('/prosjekter', { projekt: { id: 'noProject' } })
                    } />}
                </div>
            </div>
        </DefaultWrapper>
    );
}