import { useHistory, useLocation } from "react-router";
import TimeCard, { calculateAllTimePassed, SimplifiedTimeCard } from "../../components/timetracking/timeCards";
import { PcSimplifiedTimeCard } from "../../components/timetracking/PcSimplifiedTimeCard";


import CustomInput from "../../components/miniComponents/customInput";
import { useContext, useEffect, useState } from "react";
import { checkForTimeOverlap, createId, getClientById, getClients, getClientsAndProjectsInOne, getCompanyTimeSettings, getProjectsByCompanyId, getRecordedTimeFromDB, getRecordedTimeFromDBOfCurrentDay, getRecordedTimeOfCurrentDay, uploadTimeToDb } from "../../firebase";
import { IonContent, IonModal } from "@ionic/react";
import TimeChooser from "../../components/timetracking/timeChooser";

//import { ReactComponent as SettingsIcon } from '../assets/icons/mdi_settings.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/ph_play-fill.svg';
import CustomDropdown from "../../components/miniComponents/customDropdown";
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { AlertContext, CheckIfOnPC, UserContext } from "../../App";

import { ReactComponent as PlusIcon } from '../../assets/icons/material-symbols_add.svg';


import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { navigate } from "ionicons/icons";
import { DatePicker } from "@mui/x-date-pickers";
import TimePickerCarousel from "../../components/miniComponents/calendarInput";
import { SquareRounded } from "@mui/icons-material";
//const moment = require('moment-timezone');
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Popper } from "@mui/material";
import ButtonOnlyHitOnce from "../../components/miniComponents/buttonOnlyHitOnce";
import useCollectionSubscriptionRS from "../../components/utils/hooks/RSfirebaseCollectionSubscriber";
import { addTime, removeTime, setTimes, updateTime } from "../../stores/timeSlice";
import { useSelector } from "react-redux";
import { getWeekId } from "./utils/utils";
import { StartAndStopTimer } from "../../components/timetracking/StartAndStopTimer";
import { PcStartAndStopTimer } from "../../components/timetracking/pcStartAndStopTimer";



const filterOptions = createFilterOptions({
    ignoreCase: true,
});


const information = {
    id: createId(),
    //startDate: new Date(),
    //endDate: new Date(),
    title: "",
    pause: false,
    tilleg: {
        id: createId(),
        name: "Normal",
        prosent: 0,
    },

}

export default function Timeføring(props) {
    const companyUser = useSelector((state) => state.user.companyUser)
    const user = useSelector((state) => state.user.user)
    const { t } = useTranslation()
    const history = useHistory();
    const [currentlyViewing, setCurrentlyViewing] = useState(new Date())

    const { previousRecordings, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.allTimes.allIds,
        path: `tid/${user?.uid}/timer`,
        setFunction: setTimes,
        updateFunction: updateTime,
        addFunction: addTime,
        removeFunction: removeTime,
        key: "previousRecordings",
        payload: {},
        idOnly: true,
        returnItemsByIdSelector: (state) => state.time.allTimes.byId,
        // Only the once created by the user
        filters: [
            { field: 'endDate', operator: '>', value: moment(currentlyViewing).startOf('day').toDate() },
        ]
    });

    const [currentlyViewingPrevTimeStamps, setCurrentlyViewingPrevTimeStamps] = useState([])

    const [fetchedTimes, setFetchedTimes] = useState(false)

    const [kunderWithProjects, setKunderWithProjects] = useState([])

    useEffect(() => {
        // update the currently viewing times
        const checkAndSetCurrentlyViewing = () => {
            // If it is older than the same day.
            if (moment(currentlyViewing).startOf('day').toDate() < moment().startOf('day').toDate()) {
                setCurrentlyViewing(new Date())
            }
        }


        const interval = setInterval(checkAndSetCurrentlyViewing, 5000)
        return () => clearInterval(interval)
    }, [currentlyViewing])



    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const formattedDate = getDayName(currentlyViewing, t) + " " + currentlyViewing.getDate() + ". " + getMonth(currentlyViewing, t) + " " + currentlyViewing.getFullYear();
    //new Intl.DateTimeFormat('no-NO', options).format(currentlyViewing);
    //console.log(formattedDate); // Output will be in Norwegian format, e.g., "torsdag, 9. november 2023"
    console.log(filterTimesByDates(previousRecordings, moment(currentlyViewing).startOf('day'), moment(currentlyViewing).endOf('day')))
    console.log(previousRecordings)
    return (
        <div className="column content-ny padding-bottom-for-header" style={{ position: "relative" }}>
            <button className="btn btn-primary bottom-right-header-button-with-header"
                onClick={() => {
                    navigateToNewPage("/timeføring/ny")
                }}

            >{t("New Manual Time", "Ny Manuell Tid")}<PlusIcon /></button>

            {CheckIfOnPC() ?
                <PcStartAndStopTimer />
                : <StartAndStopTimer />
            }


            <div className="column small-gap" style={{ paddingTop: "3em" }}>
                <div className="row flexApart wrap center-column">

                    <h1 style={{ paddingLeft: 25 }}>{calculateAllTimePassed(
                        filterTimesByDates(
                            previousRecordings, moment(currentlyViewing).startOf('day'), moment(currentlyViewing).endOf('day')
                        )).toString().replace(".", ",")
                    } t
                    </h1>

                    <h2 className="row">
                        {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}
                    </h2>
                </div>

                <hr />
            </div>

            <LoadingWhileEmpty gottenInformation={fetchedTimes}>
                {previousRecordings.filter(t => {
                    const time = createDateOutOfFirebaseTimestamp(t.startDate)
                    return time >= moment(currentlyViewing).startOf('day') && time <= moment(currentlyViewing).endOf('day')
                }).sort(
                    (a, b) => { return createDateOutOfFirebaseTimestamp(a.endDate) - createDateOutOfFirebaseTimestamp(b.endDate) }
                ).map((time) => {
                    if (time?.endDate) return CheckIfOnPC() ? (
                        <PcSimplifiedTimeCard key={time.id} time={time} onClick={() => {
                            navigateToNewPage("/timeføring/" + time.id, {
                                timeToEdit: {
                                    ...time,
                                    open: true,
                                    startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                    endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                    //tilleg: time.tilleg,
                                }
                            })
                        }} />) : (
                        <SimplifiedTimeCard key={time.id} time={time} onClick={() => {
                            navigateToNewPage("/timeføring/" + time.id, {
                                timeToEdit: {
                                    ...time,
                                    open: true,
                                    startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                    endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                    //tilleg: time.tilleg,
                                }
                            })
                        }} />
                    )

                    /*if (time?.endDate) return (
                        <PcSimplifiedTimeCard key={time.id} time={time} onClick={() => {
                            navigateToNewPage("/timeføring/" + time.id, {
                                timeToEdit: {
                                    ...time,
                                    open: true,
                                    startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                    endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                    //tilleg: time.tilleg,
                                }
                            })
                        }} />
                    )*/
                })}
            </LoadingWhileEmpty>


            {//<TimeCard time={information} />
            }
        </div>
    )
}

export function getDay(t) {
    const date = new Date()
    const day = date.getDay()
    switch (day) {
        case 0:
            return t("Sunday", "Søndag")
        case 1:
            return t("Monday", "Mandag")
        case 2:
            return t("Tuesday", "Tirsdag")
        case 3:
            return t("Wednesday", "Onsdag")
        case 4:
            return t("Thursday", "Torsdag")
        case 5:
            return t("Friday", "Fredag")
        case 6:
            return t("Saturday", "Lørdag")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function getMonth(date, t) {
    //console.log(date)
    // Check if date is a timestamp, if so, convert it to a date
    let dateCopy = new Date(date)


    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }

    if (date === undefined) dateCopy = new Date()
    //console.log(dateCopy, date)

    let month = dateCopy.getMonth()
    if (typeof date === "number") month = date
    //console.log(month)

    switch (month) {
        case 0:
            return t("January", "Januar")
        case 1:
            return t("February", "Februar")
        case 2:
            return t("March", "Mars")
        case 3:
            return t("April", "April")
        case 4:
            return t("May", "Mai")
        case 5:
            return t("June", "Juni")
        case 6:
            return t("July", "Juli")
        case 7:
            return t("August", "August")
        case 8:
            return t("September", "September")
        case 9:
            return t("October", "Oktober")
        case 10:
            return t("November", "November")
        case 11:
            return t("December", "Desember")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function getDayInMonth(date) {
    //console.log(date)
    let dateCopy = createDateOutOfFirebaseTimestamp(date)
    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }
    if (!date) dateCopy = new Date()

    return dateCopy.getDate()
}

export function createDateOutOfFirebaseTimestamp(timestamp) {
    //console.log(timestamp)
    if (!timestamp) return null;
    let date;
    if (timestamp?.seconds !== undefined) {
        date = new Date(timestamp?.seconds *
            1000 + timestamp?.nanoseconds / 1000000);
    } else if (typeof timestamp === "string") {
        date = new Date(timestamp);
    } else {
        date = new Date(timestamp);
    }
    return moment(date).tz('Europe/Oslo').toDate();
}

export function makeDateReadable(date, t) {
    const now = new Date();
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Convert `date` to a Date object if it is a Unix timestamp.
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // If it is under a week old, return day by name.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 7) {
        return getDayName(date, t);
    }

    // If it is within the current month, return day and month.
    if (date >= firstDayOfCurrentMonth) {
        return date.getDate() + ". " + getMonth((date.getMonth() + 1), t);
    }

    // If it is under a year old, return month and day.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 365) {
        return date.getDate() + ". " + getMonth((date.getMonth() + 1), t);
    }

    // If it is over a year old, return year, month, and day.
    return date.getDate() + ". " + getMonth((date.getMonth() + 1), t) + " " + date.getFullYear();
}


export function getDayName(date, t) {
    //console.log(date)
    const day = date.getDay()
    switch (day) {
        case 0:
            return t("Sunday", "Søndag")
        case 1:
            return t("Monday", "Mandag")
        case 2:
            return t("Tuesday", "Tirsdag")
        case 3:
            return t("Wednesday", "Onsdag")
        case 4:
            return t("Thursday", "Torsdag")
        case 5:
            return t("Friday", "Fredag")
        case 6:
            return t("Saturday", "Lørdag")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function createNorwegianDate(date) {
    const month = date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    return date.getDate() + "." + month + "." + date.getFullYear()
}

function isValidDate(dateString) {
    // Check if the format is correct (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-based
    const day = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month < 0 || month > 11) {
        return false;
    }

    // Create a Date object and check if it matches the input
    const date = new Date(year, month, day);
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        return false;
    }

    return true;
}

// Convert to date if valid
export function IsActuallyDate(dateString) {
    if (isValidDate(dateString)) {
        return new Date(dateString);
    } else {
        return null;
    }
}


export const TimeChoiseModal = ({
    open, start, end, tilleg, tillegOptions,
    onDone, onClose,
    title,
    kunde, kunder,
    projekt, projekter, t
}) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [tillegToUpdate, setTillegg] = useState(tilleg)

    //console.log(tillegOptions)
    //console.log(tilleg)
    //console.log(tillegToUpdate)


    useEffect(() => {
        setStartDate(start)
        setEndDate(end)

    }, [start, end])

    //console.log(startDate, endDate)
    //console.log(start, end)

    return (
        <IonModal isOpen={open} cssClass='my-custom-class' onDidDismiss={onClose}>
            <IonContent>
                <div className="content-ny">
                    <div className="row flexApart">
                        <button className="btn btn-primary" onClick={() => {
                            onClose()
                        }}>{t("Back", "Tilbake")}</button>

                        <button className="btn btn-primary" onClick={() => {
                            onDone(startDate, endDate, tillegToUpdate)
                        }}>{t("Done", "Ferdig")}</button>
                    </div>
                    {tillegOptions &&
                        <div className="column flexApart">
                            <h2>{t("Additions", "Tillegg")}</h2>

                            <div className="typeAvTillegg">
                                <CustomDropdown options={tillegOptions} selected={tillegToUpdate} onChange={(e) => setTillegg(e)} />
                            </div>
                        </div>
                    }
                    <h2>{t("From", "Fra")}</h2>
                    <TimeChooser date={startDate} onChange={(e) => setStartDate(e)} />
                    <h2>{t("To", "Til")}</h2>
                    <TimeChooser date={endDate} onChange={(e) => setEndDate(e)} />

                </div>
            </IonContent>
        </IonModal>
    )
}



export const customSxStyles = {
    // Input text color
    '& .MuiAutocomplete-inputRoot': {
        color: "white",
    },
    // Focused input border color
    '& .MuiAutocomplete-inputFocused': {
        borderColor: "var(--contrastColor)",
    },
    // Normal input border color
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
    },
    // Hover input border color
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "var(--contrastColor)",
    },
    // Label color
    '& .MuiInputLabel-root': {
        color: "white",
    },
    // Focused label color
    '& .Mui-focused .MuiInputLabel-root': {
        color: 'var(--contrastColor) !important',
    },
    // Shrunk label color
    '& .MuiInputLabel-shrink': {
        color: 'var(--contrastColor) !important',
    },
    // Autocomplete paper styles
    '& .MuiAutocomplete-paper': {
        borderTop: '2px solid var(--contrastColor)',
        borderBottom: '2px solid var(--contrastColor)',
        backgroundColor: "red",
    },
    // No options background color
    "& .MuiAutocomplete-noOptions": {
        backgroundColor: "red",
    },

    "& .MuiAutocomplete-listbox": {
        backgroundColor: "var(--input-background)",
    },
};

export const CustomPaper = styled('div')({
    backgroundColor: "var(--input-background)",
    borderRadius: 5,
});

export function filterTimesByDates(times, startDate, endDate) {
    return times.filter(time => {
        const timeDate = createDateOutOfFirebaseTimestamp(time.startDate)
        return timeDate >= startDate && timeDate <= endDate
    })
}