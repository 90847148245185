import { useContext, useEffect, useState } from "react";
import DefaultWrapper from "../../components/defaultWrapper";
import { SearchAddressesWithPopup } from "../../components/miniComponents/searchAddresses";
import TimeChooser from "../../components/timetracking/timeChooser";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { createId, getProjects } from "../../firebase";
import CustomDropdown from "../../components/miniComponents/customDropdown";
import CustomModal from "../../components/miniComponents/CustomModal";
import ButtonListButton from "../../components/miniComponents/buttonListButton";
import HMSArea, { HMSProject } from "./utils/area";
import { useHistory, useParams } from "react-router";
import AvvikOptionsModalButton from "../../components/modals/buttons/avvikOptionsButton";
import { CompanyContext } from "../../contexts/companyContext";
import UserOrAnonymous from "./utils/userOrAnonymous";
import { useSingleFetchEditable } from "../../components/utils/hooks/fetchSingleEditable";
import { deleteHMSReport, getHMSReport, setHMSReport } from "../../database/hms";
import { useSingleFetchEditableRS } from "../../components/utils/hooks/RSFetchSingleEditable";
import { addHMSReport, removeHMSReport, setHMSByType, updateHMSReport } from "../../stores/hmsSlice";
import { useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import DeleteButton from "../../components/miniComponents/deleteButton";

function formatDate(dateToConvert) {
    // Helper function to format a Date object to "YYYY-MM-DDTHH:MM"
    console.log(dateToConvert);
    const date = new Date(dateToConvert);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = date.getFullYear();

    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    return yyyy + '-' + mm + '-' + dd + 'T' + hours + ':' + minutes;


}



export default function Avvik() {
    const params = useParams();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const { companyDetails } = useSelector((state) => state.company);
    const {
        document,
        isLoading,
        setDocument,
        updateData,
        isChanged,
        save
    } = useSingleFetchEditableRS({
        selector: (state) => state.HMS.avvik.reports,
        path: 'HMS/avvik/reports',
        saveFunction: setHMSReport,
        params: {
            id: params?.id,
            type: "avvik",
        },
        //setFunction: setHMSByType,
        updateFunction: updateHMSReport,
        addFunction: addHMSReport,
        removeFunction: removeHMSReport,
        autoUpdate: true,

        key: "document",
        payload: { type: "avvik", id: params.id },
        id: params?.id,
        filters: [
            { field: "originalCreator.uid", operator: "==", value: user.user.uid },
            { field: "status", operator: "!=", value: "sent" },
        ]
    })

    useEffect(() => {
        if (!document) return
        if (!document.companyDetails?.logo) return
        setDocument({ ...document, logo: companyDetails?.logo, });
    }, [companyDetails?.logo, document]);

    useEffect(() => {
        if (!user) return;
        if (!document) return;
        if (document?.originalCreator) return;
        setDocument({ ...document, originalCreator: user.user });
    }, [user, document]);

    const navigateToNewPage = (path, state) => {
        history.push(path, state);
    }

    const onSubmit = () => {
        const nyttSkjema = { ...document, signatur: "signert" }
        setDocument(nyttSkjema);
        navigateToNewPage("/hms/avvik/pdf", nyttSkjema);
    }


    if (!document) return null;
    return (
        <DefaultWrapper extraButton={
            <button onClick={async () => {
                deleteHMSReport({ id: document.id, type: "avvik" });
                navigateToNewPage("/HMS", { tab: "Avvik" });
            }}>Slett Avvik <Delete /></button>
        } lagring={true} onBack={save} >

            <form className="content-ny column" onSubmit={onSubmit}>
                <HMSArea skjema={document} setSkjema={setDocument} />
                <HMSProject skjema={document} setSkjema={setDocument} />
                <UserOrAnonymous value={document.ansvarlig} onChange={(e) => setDocument({ ...document, ansvarlig: e })} />

                <div className="column small-gap">
                    <h2>Dato</h2>
                    <CustomInput noIcon={true} type="datetime-local" value={document.dato} onChange={(e) => setDocument({ ...document, dato: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Når ble avviket oppdaget</h2>
                    <CustomInput noIcon={true} type="datetime-local" value={document.oppdaget} onChange={(e) => setDocument({ ...document, oppdaget: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Beskrivelse av avvik</h2>
                    <CustomTextareaInput value={document.description} onChange={(e) => setDocument(old => { return { ...document, description: e.target.value } })} />
                </div>

                <div className="column small-gap">
                    <h2>Begrunnelser for avvik</h2>
                    <AvvikOptionsModalButton label="Begrunnelser" onChange={(e) => setDocument(old => { return { ...document, begrunnelser: e ? e : [] } })} values={document.begrunnelser} />
                </div>

                <div className="column small-gap">
                    <h2>Sansynelig årsak til avvik</h2>
                    <CustomTextareaInput value={document.årsak} onChange={(e) => setDocument({ ...document, årsak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Forebyggende tiltak</h2>
                    <CustomTextareaInput value={document.tiltak} onChange={(e) => setDocument({ ...document, tiltak: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <h2>Ønskelig frist for å lukke avviket</h2>
                    <CustomInput noIcon={true} type="datetime-local" value={document.frist === "" ? document.frist : document.frist} onChange={(e) => setDocument({ ...document, frist: e.target.value })} />
                </div>

                <div className="column small-gap">
                    <div className="row flexApart">
                        <h2>Send e-post til:</h2>
                        <button type={"button"} onClick={() => {
                            setDocument({ ...document, emails: [...document?.emails, { email: "", id: createId() }] });
                        }}>Legg til Email</button>
                    </div>
                    {document.emails.map((e, i) => {
                        return (
                            <div key={e.id} className="row center">
                                <CustomInput required  type="email" value={e.email} onChange={(e) => {
                                    let temp = [...document.emails];
                                    temp[i] = { ...temp[i], email: e.target.value };
                                    setDocument({ ...document, emails: temp });
                                }} />
                                <DeleteButton type={"button"} onClick={() => {
                                    let temp = [...document.emails];
                                    temp.splice(i, 1);
                                    setDocument({ ...document, emails: temp });
                                }}>Slett</DeleteButton>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="row flexApart stretch-width">
                    <div />
                    <button type="submit" >Se Over</button>
                </div>
            </form>
        </DefaultWrapper >
    )

}